import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { post } from './rest'; // Assuming post function is defined in rest for creating a new quiz
import { toast } from 'react-toastify';
import QuillEditor from './QuillEditor';

const CreateQuizForm = ({
  questions,
  onQuizCreated,
  onCancel,
  selectedOptionCourses,
  selectedOptionModule,
  selectedOptionUnit,
  selectedOptionLesson,
  assessmentData,
  selectedQuizId // New prop to receive selected quiz ID
}) => {
  const [quizData, setQuizData] = useState({
    title: "",
    description: "",
    averagePassPercentage: "",
    difficultyLevel: "",
    timer: "", // Added timer field
    selectedAssessment: selectedQuizId // Initialize selectedAssessment with selectedQuizId
  });
  const authorid = sessionStorage.getItem("authorid");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const organizationId = sessionStorage.getItem("organizationid");
    const roleId = sessionStorage.getItem("roleid");

    try {
      const quizResponse = await post("assessmentquiz", {
        ...quizData,
        organization: organizationId,
        course: selectedOptionCourses,
        coursemodule: selectedOptionModule,
        coursechapter: selectedOptionUnit,
        lms: selectedOptionLesson,
        createdBy: sessionStorage.getItem("authorid"), 
      });

      if (quizResponse.statusCode === 201) {
        const quizId = quizResponse.response.assessmentQuiz.id;

        for (let question of questions) {
          const updatedQuestion = {
            ...question,
            course: selectedOptionCourses,
            coursemodule: selectedOptionModule,
            coursechapter: selectedOptionUnit,
            assessmentQuiz: quizId,
            organization: organizationId,
            role: roleId,
            assessmentCluster: question.assessmentCluster._id,
            courseCluster: question.courseCluster ? question.courseCluster._id : null,
            lms: selectedOptionLesson,
            createdBy:authorid,
            type:"normal"
          };

          delete updatedQuestion._id;
          delete updatedQuestion.createdAt;
          delete updatedQuestion.updatedAt;
          debugger;
          const responseData = await post(`assessment`, updatedQuestion);
          console.log(responseData);
          if (responseData.statusCode === 201) {
            toast.success("Quiz created with selected Questions successfully!");
          }
        }

        // Reset form data after successful creation
        setQuizData({
          title: "",
          description: "",
          averagePassPercentage: "",
          difficultyLevel: "",
          timer: "", // Reset timer field
          selectedAssessment: quizId // Update selectedAssessment with newly created quiz ID
        });

        onQuizCreated(quizId); // Notify parent component with the created quiz ID
      } else {
        toast.error("Failed to create quiz");
      }
    } catch (error) {
      console.error("Error creating quiz:", error);
      toast.error("Failed to create quiz");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formQuizTitle" className="form-group">
        <Form.Label>Quiz Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter quiz title"
          value={quizData.title}
          onChange={(e) => setQuizData({ ...quizData, title: e.target.value })}
          required
        />
      </Form.Group>
      <Form.Group controlId="formQuizDescription" className="form-group">
        <Form.Label>Quiz Description</Form.Label>
        <QuillEditor
          value={quizData.description}
          onChange={(content) => setQuizData({ ...quizData, description: content })}
          required
        />
      </Form.Group>
      <Row>
        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group controlId="formAveragePassPercentage" className="form-group">
        <Form.Label>Average Pass Percentage</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter average pass percentage"
          value={quizData.averagePassPercentage}
          onChange={(e) => setQuizData({ ...quizData, averagePassPercentage: e.target.value })}
          required
        />
      </Form.Group>
        </Col>
        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group controlId="formDifficultyLevel" className="form-group">
        <Form.Label>Difficulty Level</Form.Label>
        <Form.Control
          as="select"
          value={quizData.difficultyLevel}
          onChange={(e) => setQuizData({ ...quizData, difficultyLevel: e.target.value })}
          required
        >
          <option value="">Select Difficulty Level</option>
          {[...Array(11).keys()].map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
        </Col>
        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
        <Form.Group controlId="formTimer" className="form-group">
        <Form.Label>Timer (in minutes)</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter quiz timer"
          value={quizData.timer}
          onChange={(e) => setQuizData({ ...quizData, timer: e.target.value })}
          required
        />
      </Form.Group>
        </Col>
      </Row>
      
      
      

      <Button variant="primary" type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">
        Create Quiz
      </Button>
    </Form>
  );
};

export default CreateQuizForm;
