import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

function Footer() {
    return ( 
        <div className="p-4 mt-5">
        <p className="m-0">© Copyrights GreenBug 2024. All rights reserved. Designed by Codingster</p>
        </div>
     );
}

export default Footer;