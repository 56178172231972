import React, { useState, useEffect } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function Chapters() {
    const [chapters, setChapters] = useState([]);
    const [filteredChapters, setFilteredChapters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [chaptersPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
  
    useEffect(() => {
  
      fetchChapters();
    }, []);
  
    const fetchChapters = async () => {
      try {
        get('coursechapter')
          .then(response => {
            debugger;
            var role = sessionStorage.getItem("roleslug");
          var organizationid = sessionStorage.getItem("organizationid");
          var chapterdata ='';
          if (role === 'superadmin') {
            chapterdata = response.response.filter(chapter => role !== "employee" && role !== "organizationadmin" && !chapter.organization );
          } else if (role === 'organizationadmin') {
            chapterdata = response.response.filter(chapter => role !== 'superadmin' && chapter.organization === organizationid);
          } else {
            chapterdata = response.response.filter(chapter => role !== 'superadmin' && chapter.organization === organizationid);
          }
            setChapters(chapterdata);
            setFilteredChapters(chapterdata);
          })
          .catch(error => console.error('Error fetching data:', error));          
    
      } catch (error) {
        console.error('Error fetching Units:', error);
      }
    };
  
    const handleChange = (e) => {
      setSearchQuery(e.target.value);
      filterChapters(e.target.value);
    };
  
    const filterChapters = (query) => {
      const filteredChaps = chapters.filter(Chap =>
        Chap.title.toLowerCase().includes(query.toLowerCase()) ||
        Chap.courseModule.title.toLowerCase().includes(query.toLowerCase()) 
      );
      setFilteredChapters(filteredChaps);
      setCurrentPage(1);
    };
  
    const indexOfLastChap = currentPage * chaptersPerPage;
    const indexOfFirstChap = indexOfLastChap - chaptersPerPage;
    const currentChaps = filteredChapters.slice(indexOfFirstChap, indexOfLastChap);
    async function handleDelete(Id) {
      try {
        await del(`coursechapter/${Id}`);
        // Refresh Chapters after deletion
        fetchChapters();
      } catch (error) {
        console.error('Error deleting Units:', error);
      }
    }
    const getPageNumbers = () => {
      const totalPages = Math.ceil(filteredChapters.length / chaptersPerPage);
      const pageNumbers = [];
      const maxVisiblePages = 5;
  
      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
  
        if (startPage > 1) pageNumbers.push(1, '...');
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        if (endPage < totalPages) pageNumbers.push('...', totalPages);
      }
      return pageNumbers;
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleNext = () => {
      if (currentPage < Math.ceil(filteredChapters.length / chaptersPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };
  return (
    <DashboardContent pageTitle="Units">
  <div>
        {/* <Row className='text-right my-5'>
          <Col md={{span:3, offset:9}}>
          <Button type="button" onClick={()=>window.location='/createIndustry'} className="fw-btn-fill btn-gradient-yellow">Create Category</Button>
          </Col>
        </Row> */}

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" ">
          <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} onChange={handleChange} placeholder="Search ..." className="form-control"  />
          </Col>
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Unit</th>
                <th>Module</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedChapters">

              {/* Render Chapters */}
              {currentChaps.map((Chap, index) => (
                <tr key={index}>
                   <td>{Chap.title}</td>
                   <td>{Chap.courseModule && Chap.courseModule.title}</td>
                  <td><i onClick={()=>window.location='/editunit?id='+ Chap.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(Chap.id)} className="fa-regular fa-trash-can"></i></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredChapters.length / chaptersPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredChapters.length / chaptersPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default Chapters;
