import React, { useState, useEffect } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { del, get } from './rest';

function Subscriptions() {
    const [subscriptions, setSubscriptions] = useState([]);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [subscriptionsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
  
    useEffect(() => {
  
      fetchSubscriptions();
    }, []);
  
    const fetchSubscriptions = async () => {
      try {
        get('subscription')
        .then(response => {
          debugger;
          setSubscriptions(response.response);
          setFilteredSubscriptions(response.response);
        })
        .catch(error => console.error('Error fetching data:', error));          
  
      } catch (error) {
        console.error('Error fetching Subscriptions:', error);
      }
    };
  
    const handleChange = (e) => {
      setSearchQuery(e.target.value);
      filterSubscriptions(e.target.value);
    };
  
    const filterSubscriptions = (query) => {
      const filteredsubtypes = subscriptions.filter(subtype =>
        subtype.subscriptiontype.toLowerCase().includes(query.toLowerCase()) ||
        subtype.price.toLowerCase().includes(query.toLowerCase()) 
      );
      setFilteredSubscriptions(filteredsubtypes);
      setCurrentPage(1);
    };
  
    const indexOfLastsubtype = currentPage * subscriptionsPerPage;
    const indexOfFirstsubtype = indexOfLastsubtype - subscriptionsPerPage;
    const currentsubtypes = filteredSubscriptions.slice(indexOfFirstsubtype, indexOfLastsubtype);
    async function handleDelete(Id) {
      try {
        await del(`subscription/${Id}`);
        // Refresh industries after deletion
        fetchSubscriptions();
      } catch (error) {
        console.error('Error deleting industries:', error);
      }
    }

  return (
    <DashboardContent pageTitle="Subscription Types">
  <div>
        <Row className='text-right my-5'>
          <Col md={{span:4, offset:8}}>
          <Button type="button" onClick={()=>window.location='/createsubscriptiontype'} className="fw-btn-fill btn-gradient-yellow">Create Subscription Type</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" ">
          <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Subscription Type</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedSubscriptions">
           
              {/* Render Subscriptions */}
              {currentsubtypes.map((subtype, index) => (
                <tr key={index}>
                   <td>{subtype.subscriptiontype}</td>
                   <td>{subtype.price}</td>
                  <td><i onClick={()=>window.location='/editsubscriptiontype?id='+ subtype.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(subtype.id)} className="fa-regular fa-trash-can"></i></td>
              
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <span>Previous</span>
          <ul className="pagination">
            {Array(Math.ceil(filteredSubscriptions.length / subscriptionsPerPage)).fill().map((_, i) => (
              <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                <button onClick={() => setCurrentPage(i + 1)} className="page-link">{i + 1}</button>
              </li>
            ))}
          </ul>
          <span>Next</span>

        </nav>
      </div>
    <ToastContainer />
    </DashboardContent>
  );
}

export default Subscriptions;
