import { Col, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { get } from "./rest";

function Profile() {
    const [admin, setAdmin] = useState([]);

    const [id, setId] = useState(null);
  const location = useLocation();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get("id");
        setId(id);
        if(id){
          fetchAdmin(id);
        } else {
          const authorid = sessionStorage.getItem("authorid");
          fetchAdmin(authorid);
        }
        
      }, [location.search]);
      const fetchAdmin = async (id) => {
        try {
          get("users/" + id)
            .then((response) => {
              debugger;
              setAdmin(response.response);
            })
            .catch((error) => console.error("Error fetching data:", error));
        } catch (error) {
          console.error("Error fetching Admins:", error);
        }
      };
    return ( 
        <DashboardContent pageTitle="Profile">
            <Row>
                <Col md={6}>
                <div className="card">
                <div className="card-body">
                    <Row>
                        <Col md={4}><p>UserName:</p></Col>
                        <Col md={8}><p>{admin.username}</p></Col>
                    </Row>
                    <Row>
                        <Col md={4}><p>Name:</p></Col>
                        <Col md={8}><p>{admin.firstname} {admin.lastname}</p></Col>
                    </Row>
                    <Row>
                        <Col md={4}><p>Email:</p></Col>
                        <Col md={8}><p>{admin.email}</p></Col>
                    </Row>
                    <Row>
                        <Col md={4}><p>Mobile:</p></Col>
                        <Col md={8}><p>{admin.phoneNumber}</p></Col>
                    </Row>
                    <Row>
                        <Col md={4}><p>City:</p></Col>
                        <Col md={8}><p>{admin.city}</p></Col>
                    </Row>
                    <Row>
                        <Col md={4}><p>State:</p></Col>
                        <Col md={8}><p>{admin.state}</p></Col>
                    </Row>
                </div>
            </div>
                </Col>
            </Row>
        </DashboardContent>
     );
}

export default Profile;