import { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { del, get } from "./rest";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";

function ViewDocuments() {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
        fetchUsers();
      }, []);
      var role = sessionStorage.getItem("roleslug");
      var authorid = sessionStorage.getItem("authorid");

    const fetchUsers = async () => {
        try {
          get('users')
            .then(response => {
              debugger;
              var organizationid = sessionStorage.getItem("organizationid");
              var orgAdmins ='';
              if (role === 'superadmin') {
                orgAdmins = response.response.filter(admin => admin.role.slug !== "employee" && admin.role.slug !== "superadmin" &&
                    Array.isArray(admin.docURL) && 
                admin.docURL.length > 0 );
              } else if (role === 'organizationadmin') {
                debugger;
                orgAdmins = response.response.filter(admin => admin.role.slug !== 'superadmin' && admin.organization && admin.organization.id === organizationid && 
                Array.isArray(admin.docURL) && 
                admin.docURL.length > 0 );
              } else {
                orgAdmins = response.response.filter(admin => admin.role.slug !== 'superadmin' && admin.organization && admin.organization.id === organizationid && admin.id === authorid && 
                Array.isArray(admin.docURL) && 
                admin.docURL.length > 0 );
              }
    
              setUsers(orgAdmins);
              setFilteredUsers(orgAdmins);
            })
            .catch(error => console.error('Error fetching data:', error));          
      
        } catch (error) {
          console.error('Error fetching Users:', error);
        }
      };
      const filterUsers = (query) => {
        const filteredOrgAdmins = users.filter(OrgAdmin => {
          const docMatch = OrgAdmin.docURL ? OrgAdmin.docURL.some(slot => 
            slot.name.toLowerCase() === query ||
            slot.coursename.toLowerCase() === query
          ) : true;
        return docMatch;
        });
      
        setFilteredUsers(filteredOrgAdmins);
        setCurrentPage(1);
      }       
      
      const indexOfLastOrgAdmin = currentPage * usersPerPage;
      const indexOfFirstOrgAdmin = indexOfLastOrgAdmin - usersPerPage;
      const currentOrgAdmins = filteredUsers.slice(indexOfFirstOrgAdmin, indexOfLastOrgAdmin);
    //   async function handleDelete(Id) {
    //     try {
    //       await del(`users/${Id}`);
    //       // Refresh industries after deletion
    //       fetchUsers();
    //     } catch (error) {
    //       console.error('Error deleting industries:', error);
    //     }
    //   }
    
      const getPageNumbers = () => {
        const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
        const pageNumbers = [];
        const maxVisiblePages = 5;
    
        if (totalPages <= maxVisiblePages) {
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
        } else {
          const startPage = Math.max(1, currentPage - 2);
          const endPage = Math.min(totalPages, currentPage + 2);
    
          if (startPage > 1) pageNumbers.push(1, '...');
          for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
          }
          if (endPage < totalPages) pageNumbers.push('...', totalPages);
        }
        return pageNumbers;
      };
    
      const handlePrevious = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handleNext = () => {
        if (currentPage < Math.ceil(filteredUsers.length / usersPerPage)) {
          setCurrentPage(currentPage + 1);
        }
      };
      const handleChange = (e) => {
        setSearchQuery(e.target.value);
        filterUsers(e.target.value);
      };

      return (
        <DashboardContent pageTitle="List of Documents">
          <div style={{ overflowX: 'hidden' }}>
            <Row className='text-right my-5 d-flex align-items-center'>          
              <Col md={4} className="form-group">
                <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
              </Col>
              
            </Row>
            {/* </Form> */}
            {currentOrgAdmins.map((OrgAdmin, orgIndex) => (
                OrgAdmin?.docURL && OrgAdmin.docURL.length > 0 ? (
                    OrgAdmin.docURL.map((doc, docIndex) => (
                    <Col key={`${orgIndex}-${docIndex}`} md={8} className="mb-3">
                        <Card>
                            <Card.Body>
                                <Card.Title>{doc.name} </Card.Title>
                                <p>From - {doc.coursename} </p>
                                {OrgAdmin.id !== authorid ? <><p>To - {OrgAdmin.username} ({OrgAdmin.email})</p></> :<></>}
                                <Button onClick={() => window.location =  doc.url} className="mr-2 secondary-btn">
                                View
                                </Button>
                     
                            </Card.Body>
                            </Card>
                        </Col>
                        ))
                    ) : null
                    ))}

         
            {/* Pagination */}
            <nav className='d-flex justify-content-end my-5 align-items-center'>
            <Button
                className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <ul className="pagination">
                {getPageNumbers().map((pageNumber, index) => (
                  <li
                    key={index}
                    className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
                  >
                    {pageNumber === '...' ? (
                      <span className="page-link">...</span>
                    ) : (
                      <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                    )}
                  </li>
                ))}
              </ul>
              <Button
                className={`btn plain-btn ${currentPage === Math.ceil(filteredUsers.length / usersPerPage) ? 'disabled' : ''}`}
                onClick={handleNext}
                disabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}
              >
                Next
              </Button>
            </nav>
          </div>
        </DashboardContent>
     );
}

export default ViewDocuments;