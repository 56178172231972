import React, { useState } from 'react';

const CourseForm = ({ onSubmit }) => {
  const [courseData, setCourseData] = useState({
    days: 1,
    courseName: '',
    courseId: '',
    price: '',
    slots: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleAddSlot = () => {
    // Create a new time slot object with default values
    const newSlot = {
      startTime: '09:00', // Default start time
      endTime: '10:00',   // Default end time
    };
    // Add the new slot to the slots array in the course data
    setCourseData({ ...courseData, slots: [...courseData.slots, newSlot] });
  };

  const handleSlotChange = (index, e) => {
    const { name, value } = e.target;
    // Update the slot at the specified index with the new value
    const updatedSlots = [...courseData.slots];
    updatedSlots[index] = { ...updatedSlots[index], [name]: value };
    setCourseData({ ...courseData, slots: updatedSlots });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(courseData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Number of Days:
        <input type="number" name="days" value={courseData.days} onChange={handleChange} />
      </label>
      <label>
        Course Name:
        <input type="text" name="courseName" value={courseData.courseName} onChange={handleChange} />
      </label>
      <label>
        Course ID:
        <input type="text" name="courseId" value={courseData.courseId} onChange={handleChange} />
      </label>
      <label>
        Price:
        <input type="text" name="price" value={courseData.price} onChange={handleChange} />
      </label>
      <h3>Time Slots:</h3>
      {courseData.slots.map((slot, index) => (
        <div key={index}>
          <label>
            Start Time:
            <input type="time" name="startTime" value={slot.startTime} onChange={(e) => handleSlotChange(index, e)} />
          </label>
          <label>
            End Time:
            <input type="time" name="endTime" value={slot.endTime} onChange={(e) => handleSlotChange(index, e)} />
          </label>
        </div>
      ))}
      <button type="button" onClick={handleAddSlot}>Add Time Slot</button>
      <button type="submit">Submit</button>
    </form>
  );
};

export default CourseForm;
