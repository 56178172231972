import DashboardContent from './DashboardContent';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { post, get } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import React, { useState, useEffect } from "react";
import Select from "react-select";

function RandomQuiz(){
    const [formData, setFormData] = useState({});
    const [existingAssessments, setExistingAssessments] = useState([]); 
    const [selectedAssessment, setSelectedAssessment] = useState(null); // State for selected unit
    const authorid = sessionStorage.getItem("authorid");

    useEffect(() => {
        fetchExistingAssessments();
    }, []);

    const fetchExistingAssessments = async () => {
        try {
            const organizationid = sessionStorage.getItem("organizationid");
            const response = await get(`assessmentquiz`);
            const assessments = response.response
                .filter((assessment) => assessment.organization === organizationid && assessment.type !== 'random')
                .map((assessment) => ({
                    value: assessment.id,
                    label: assessment.title
                }));
            
            setExistingAssessments(assessments);
        } catch (error) {
            console.error('Error fetching existing assessments:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAssessmentChange = (selectedAssessment, event) => {
        if (event) {
            event.stopPropagation(); // Stop event propagation
        }
        setSelectedAssessment(selectedAssessment.value);
        setFormData((prevState) => ({
            ...prevState,
            assessmentQuiz: selectedAssessment.value,
        }));
    };

    const handleDifficultyLevelChange = (selectedOption) => {
        setFormData((prevState) => ({
            ...prevState,
            difficultyLevel: selectedOption.value.toString(),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var organizationid = sessionStorage.getItem("organizationid");
            const quizResponse = await post("assessmentquiz", {
                ...formData,
                organization: organizationid,
                type: "random",
                createdBy: authorid
            });
            if(quizResponse.statusCode === 201){
                toast.success("Created Successfully", {
                    onClose: () => {
                        window.location.href = '/assessments';
                    },
                });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const options = [...Array(11).keys()].map((level) => ({
        value: level,
        label: level.toString(),
    }));

    return(
        <DashboardContent pageTitle="Create Random Quiz">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="student-info">
                                <Form onSubmit={handleSubmit} id="createRole" className="">
                                    <Row>
                                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                            <Form.Label>Create a Quiz Name</Form.Label>                                    
                                            <Form.Control type="text" id="title" name="title" className="form-control" onChange={handleChange} required />
                                        </Col>
                                        
                                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                            <Form.Label>No. of Questions</Form.Label>                                    
                                            <Form.Control type="text" id="questionscount" name="questionscount" className="form-control" onChange={handleChange} required />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                            <Form.Label>Select Quiz</Form.Label>
                                            <Select
                                                options={existingAssessments}
                                                placeholder="Select a Quiz"
                                                required
                                                isSearchable
                                                onChange={(selectedOption) => setFormData({ ...formData, assessmentQuiz: selectedOption.value })}
                                            />
                                        </Col>
                                        
                                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                            <Form.Label>Difficulty Level</Form.Label>
                                            <Select
                                                value={options.find(option => option.value === formData.difficultyLevel)}
                                                onChange={handleDifficultyLevelChange}
                                                options={options}
                                                required
                                            />
                                        </Col>
                                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                            <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Create Random Quiz</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </DashboardContent>
    );
}
export default RandomQuiz;
