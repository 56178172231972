import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function YearPicker({ selected, onChange }) {
  const [year, setYear] = useState(selected || new Date().getFullYear());

  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value);
    setYear(selectedYear);
    onChange(selectedYear);
  };

  // Generate year options from 1900 to 2099
  const years = [];
  for (let year = 1900; year <= 2099; year++) {
    years.push(year);
  }

  return (
    <Form.Control as="select" value={selected || ''} onChange={handleYearChange}>
            <option value="" disabled hidden>Select Year</option>
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </Form.Control>
  );
}

export default YearPicker;
