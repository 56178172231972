import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectField from "./SelectField";
import { ToastContainer, toast } from "react-toastify";
import { get, put } from "./rest";
import { useLocation } from "react-router-dom";

function EditOrganizationAdmin() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    city: "",
    state: "",
    role: "",
    organization:""
  });

  const [id, setId] = useState(null);
  const location = useLocation();
  const [selectedOptionRoles, setSelectedOptionRoles] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [optionsRoles, setOptionsRoles] = useState([]);
  const [organizationAdmins, setOrganizationAdmins] = useState([]);
  const [optionsOrganization, setOptionsOrganization] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [showOrganizationField, setShowOrganizationField] = useState(true); // State to control organization field visibility

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchOrganizationAdmins(id);
  }, [location.search]);
  useEffect(() => {
    fetchRolesOptions(); 
    var role = sessionStorage.getItem("roleslug");
    if( role === 'superadmin'){
      fetchOrganizationOptions();
    } else{
      setShowOrganizationField(false);
    }
  }, []);

  const fetchOrganizationOptions = async () => {
    try {
        const response = await get('organization');
        debugger;
        const options = response.response.map(org => ({
            value: org._id,
            label: org.adminOrganization,
            isSelected: org._id === selectedOrg
        }));
        debugger;
        setOptionsOrganization(options);
    } catch (error) {
        console.error('Error fetching organization options:', error);
    }
  };
  const fetchRolesOptions = async () => {
    try {
      const response = await get("roles");
      debugger;
      var roleslug = sessionStorage.getItem("roleslug");
      var organizationid = sessionStorage.getItem("organizationid");

      // var filteredOptions ='';
      // if (roleslug === 'superadmin') {
      //   filteredOptions = response.response.filter(rol => rol.slug !== 'superadmin');
      // } else if (roleslug === 'organizationadmin') {
      //   filteredOptions = response.response.filter(rol => rol.slug !== 'superadmin' && rol.slug !== 'organizationadmin' && rol.organization.id === organizationid && rol.slug !== 'employee');
      // } else {
      //   filteredOptions = response.response.filter(rol => rol.slug !== 'superadmin' && rol.slug !== 'organizationadmin' && rol.organization.id === organizationid && rol.slug !== 'employee');
      // }
      const filteredOptions = response.response.filter(rol => rol.organization && rol.organization.id === organizationid);


      const options = filteredOptions.map((role) => ({
        value: role.id,
        label: role.role,
        isSelected: role.id === selectedOptionRoles
      }));
      debugger;
      setOptionsRoles(options); // Set optionsRoles directly from the response
    } catch (error) {
      console.error("Error fetching roles options:", error);
    }
  };
  const fetchOrganizationAdmins = async (id) => {
    try {
      get("users/" + id)
        .then((response) => {
          debugger;
          setOrganizationAdmins(response.response);
          setFormData(response.response);
          setSelectedOptionRoles(response.response.role);
          setSelectedOrg(response.response.organization);
        })
        .catch((error) => console.error("Error fetching data:", error));
    } catch (error) {
      console.error("Error fetching OrganizationAdmins:", error);
    }
  };
  const handleChangeOrganization = (selectedOrganization) => {
    debugger;
    setSelectedOrganization(selectedOrganization);
    setFormData((prevState) => ({
      ...prevState,
      organization: selectedOrganization,
    }));
  };
  //   useEffect(() => {
  //     console.log("useEffect triggered");
  //     if (formData.role) {
  //         const defaultOption = optionsRoles.find(option => option.value === formData.role);
  //         console.log("Default Option:", defaultOption);
  //         setSelectedOptionRoles(defaultOption);
  //     }
  // }, [formData.role, optionsRoles]);
  const [phoneError, setPhoneError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

 if (name === 'phoneNumber') {
  // Remove any non-numeric characters
  newValue = value.replace(/\D/g, '');

  // Check if the original value contains any non-numeric characters
  if (value !== newValue) {
    setPhoneError('Phone number should contain only numbers.');
  } else if (newValue.length !== 10) {
    // Validate if the phone number has exactly 10 digits
    setPhoneError('Phone number must be 10 digits long.');
  } else {
    setPhoneError('');
  }

  // Restrict to 10 digits (this ensures newValue is always 10 digits)
  newValue = newValue.slice(0, 10);
}


    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeRoles = (selectedOptionRoles) => {
    debugger;
    setSelectedOptionRoles(selectedOptionRoles);
    setFormData((prevState) => ({
      ...prevState,
      role: selectedOptionRoles,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = {
        ...formData,
        updatedBy : sessionStorage.getItem("authorid")
      };
      const responseData = await put("users/" + id, updatedFormData);
      debugger;
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/users";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <DashboardContent pageTitle="Edit user">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Edit user </h3>
                </div>
              </div>
              <div className="student-info">
                <Form onSubmit={handleSubmit} id="editemp" className="mt-5">
                  <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>
                        Username:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="username"
                        name="username"
                        className="form-control"
                        value={formData.username}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Password:</Form.Label>
                      <Form.Control
                        type="password"
                        id="password"
                        name="password"
                        className="form-control"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                    </Col> */}
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>First name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="firstname"
                        name="firstname"
                        className="form-control"
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    </Row>
                <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Last name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="lastname"
                        name="lastname"
                        className="form-control"
                        value={formData.lastname}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Email:</Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Col> */}
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Phone:</Form.Label>
                      <Form.Control
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        className={`form-control ${phoneError ? 'is-invalid' : ''}`}
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                      />
                      {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                    </Col>
                    </Row>
                <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>City:</Form.Label>
                      <Form.Control
                        type="text"
                        id="city"
                        name="city"
                        className="form-control"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>State:</Form.Label>
                      <Form.Control
                        type="text"
                        id="state"
                        name="state"
                        className="form-control"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    </Row>
                <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Role:</Form.Label>
                      <SelectField
                      placeholder="Select Role"
                        options={optionsRoles}
                        onChange={handleChangeRoles}
                        selected={formData.role.id}
                      />
                    </Col>
                    {showOrganizationField && (
                      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                        <Form.Label>Organization:</Form.Label>
                        <SelectField options={optionsOrganization} placeholder="Select Organization" onChange={handleChangeOrganization} selected={formData.organization} />
                      </Col>
                    )}
                    
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    </Col>                    
                    
                  </Row>
                  <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        Update User
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditOrganizationAdmin;
