import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DashboardContent from './DashboardContent';

function CreateQuestion() {
  const [selectedType, setSelectedType] = useState('');
  const options = [
    { value: 'fillInTheBlanks', label: 'Fill in the Blanks' },
    { value: 'multipleChoice', label: 'Multiple Choice Questions' },
    { value: 'chooseTheCorrectAnswer', label: 'Choose the Correct Answer' },
    // { value: 'trueFalse', label: 'True or False' },
    // { value: 'essay', label: 'Essay' },
    // { value: 'shortAnswer', label: 'Short Answer' }
  ];

  const handleTypeChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedType(selectedValue);
    handleRedirect(selectedValue);
  };
  const handleRedirect = (selectedValue) => {
    switch (selectedValue) {
      case 'fillInTheBlanks':
        window.location.href = '/fill-in-the-blanks';
        break;
      case 'multipleChoice':
        window.location.href = '/create-assessment';
        break;
        case 'chooseTheCorrectAnswer':
        window.location.href = '/createchooseTheCorrectAnswer';
        break;
      // case 'trueFalse':
      //   window.location.href = '/create-assessment';
      //   break;
      // case 'essay':
      //   window.location.href = '/essay-answer';
      //   break;
      // case 'shortAnswer':
      //   window.location.href = '/short-answer';
      //   break;
      default:
        break;
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Handle form submission
  // };

  // const renderQuestionComponent = () => {
  //   switch (selectedType) {
  //       case 'fillInTheBlanks': return <FillInTheBlanks />;
  //       case 'multipleChoice': return <CreateAssessment />;
  //       case 'trueFalse': return <CreateAssessment />;
  //       case 'essay': return <EssayAnswer />;
  //       case 'shortAnswer': return <ShortAnswer />;
  //       default: return null;
  //   }
  // };

  return (
    <DashboardContent pageTitle="Question">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Create Question/Quiz</h3>
                </div>
              </div>
              <div className="student-info hidecheckox">
                <Row>
                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                <Form id="SelectQuestion" className="mt-5">
                  <Form.Group controlId="questionType" className="form-group">
                    <Form.Label>Select Question Type:</Form.Label>
                    <Form.Control as="select" value={selectedType} onChange={handleTypeChange}>
                      <option value="">Select...</option>
                      {options.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  {/* Render the appropriate component based on selected type */}
                  {/* {renderQuestionComponent()} */}
                </Form>
                </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </DashboardContent>
  );
}

export default CreateQuestion;
