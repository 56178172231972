import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { get, post } from "./rest";
import { ToastContainer, toast } from "react-toastify";
import SelectField from "./SelectField";
import DashboardContent from "./DashboardContent";
import CustomSelectField from "./CustomSelectField";

function ShortAnswer() {
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
    course: ""
  });
  const [optionsCourses, setOptionsCourse] = useState([]);
  const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsModuleCourse, setOptionsModuleCourse] = useState([]); // State for module options
  const [selectedOptionModule, setSelectedOptionModule] = useState(null); // State for selected module
  const [optionsUnitModule, setOptionsUnitModule] = useState([]); 
  const [selectedOptionUnit, setSelectedOptionUnit] = useState(null); // State for selected unit

  useEffect(() => {
    fetchCourseOptions();
  }, []);
  const fetchCourseOptions = async () => {
    try {
      const response = await get('courses');
      const options = response.response.map(course => ({
        value: course.id,
        label: course.course_title
      }));
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  const handleChangeCourses = (selectedOptionCourses) => {
    setSelectedOptionCourses(selectedOptionCourses);
    setFormData(prevState => ({
      ...prevState,
      course: selectedOptionCourses
    }));
    fetchCourseModuleOption(selectedOptionCourses); 
    debugger;
  };
  const fetchCourseModuleOption = async (selectedOptionCourses) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursemodule?course=${selectedOptionCourses}`);
      const options = response.response
        .map((module) => {
          if (module.organization === organizationid) {
            return {
              value: module.id,
              label: module.title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
    } catch (error) {
      console.error("Error fetching Module options:", error);
    }
  };

  // Handle module change
  const handleChangeModule = (selectedOptionModule) => {
    setSelectedOptionModule(selectedOptionModule);
    setFormData((prevState) => ({
      ...prevState,
      coursemodule: selectedOptionModule,
    }));
    fetchCourseUnitOption(selectedOptionModule)
  };
  const fetchCourseUnitOption = async (selectedOptionModule) => {
    
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursechapter?courseModule=${selectedOptionModule}`);
      const options = response.response
        .map((unit) => {
          if (unit.organization === organizationid) {
            // if (unit.organization === organizationid) {
            return {
              value: unit.id,
              label: unit.title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsUnitModule(options);
    } catch (error) {
      console.error("Error fetching Unit options:", error);
    }
  };
  // Handle unit change
  const handleChangeUnit = (selectedOptionUnit) => {
    setSelectedOptionUnit(selectedOptionUnit);
    setFormData((prevState) => ({
      ...prevState,
      coursechapter: selectedOptionUnit,
    }));
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleModuleSubmit = async (e, filter) => {
    e.preventDefault();
    debugger;
    try {
      debugger;
      const response = await post('/coursemodule', { title: filter });
      if (response.statusCode === 201) {
        toast.success('New module added successfully');
        
        // setSelectedOptionModule({
        //   value: filter,
        //   label: filter,
        // });
        // debugger;
        // fetchCourseModuleOptions().then(() => {
        //   // Set the selected module to the newly added module
        //   setSelectedOptionModule({
        //     value: response.response.id,
        //     label: filter,
        //   });
        // });

        fetchCourseModuleOption(filter);
      } else {
        toast.error('Failed to add new module');
      }
    } catch (error) {
      console.error('Error adding new module:', error);
      toast.error('Failed to add new module');
    }
  };

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    try {
      const roleid = sessionStorage.getItem("roleid");
      const roleslug = sessionStorage.getItem("roleslug");
      const organizationid = sessionStorage.getItem("organizationid");
      formData.type = 'shortAnswer';

      var CourseCluster = '';
      var assessmentCluster='';
      if (roleslug === 'superadmin'){
        CourseCluster = '65eef8496cbe0baacc3aab6f';
        assessmentCluster ='6607a835f89bfe1c7d5f0807';
      } else {
        CourseCluster = '65eef8586cbe0baacc3aae21';
        assessmentCluster ='6607a83df89bfe1c7d5f0809';
        formData.organization = organizationid;
      }
      const formDataWithAnswer = {
        ...formData,
        assessmentCluster: assessmentCluster,
        courseCluster: CourseCluster,
        role: roleid
      };
      debugger;
      const responseData = await post("assessment", formDataWithAnswer);
      debugger;
      if (responseData.statusCode === 201) {
        toast.success("Created Successfully", {
          onClose: () => {
            window.location.href = "/questionbank";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <DashboardContent pageTitle="Create Question - Short Answer">
      <Row>
        <Col md={12}>
          <div className="student-info hidecheckox">
            <Form onSubmit={handleSubmit} id="createQuestion" className="mt-5">
              <Row>
                <Col md={12} className="form-group">
                  <Form.Group controlId="question">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="question"
                      value={formData.question}
                      placeholder="Enter the short answer question"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={6} className="form-group">
                  <Form.Label>Answer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the correct answer"
                    name="answer"
                    value={formData.answer}
                    onChange={handleChange}
                  />
                </Col> */}
                   <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>Course:</Form.Label>
                      <SelectField options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses} />
                    </Col>
                    {selectedOptionCourses && ( // Render module select box only if course is selected
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        {/* <Form.Label>Module:</Form.Label> */}
                        <CustomSelectField options={optionsModuleCourse} onChange={handleChangeModule} isSearchable handleModuleSubmit={handleModuleSubmit}/>

                    </Col>
                     )}  
                     {selectedOptionModule && ( // Render unit select box only if module is selected */} */}
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>Unit:</Form.Label>
                    <SelectField options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} />
                  </Col>
                 )} 
                </Row>
                <Row>
                <Col md={6} className="form-group">
                  <Button
                    type="submit"
                    className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                  >
                    Create Question
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default ShortAnswer;
