import React, { useState, useEffect } from 'react';



const Calendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    
    fetch('coursecalendar')
      .then(response => response.json())
      .then(data => {        
        const formattedEvents = data.response.map(event => ({
          title: event.course.course_title,
          start: event.start_date,
          end: event.end_date
        }));
        setEvents(formattedEvents);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      {/* <FullCalendar
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
        events={events}
      /> */}
    </div>
  );
};

export default Calendar;
