import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { get } from './rest';

const OrgHeader = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [webData, setWebData] = useState();

    
  useEffect(() => {
    const authorid = sessionStorage.getItem('authorid');
    setLoggedIn(authorid !== null);
    async function fetchData() {
      try {
        let organizationId = sessionStorage.getItem("organizationid");
        if(!organizationId){
          const queryParams = new URLSearchParams(window.location.search);
          const organizationName = queryParams.get('org');

          if (organizationName) {
              const response = await get('OrganizationWebsite');
              const data = response.response;
              const organizationData = data.find(org => org.organization.adminOrganization.toLowerCase() === organizationName.toLowerCase());

              if (organizationData) {
                  setWebData(organizationData);
                  sessionStorage.setItem("organizationwebsiteid", organizationData.organization.id); // Store organizationId in sessionStorage
              } else {
                  throw new Error('Organization data not found');
              }
          }
        }
        const response = await get('OrganizationWebsite');
        const data = response.response;
        const organizationData = data.find(org => org.organization && org.organization.id === organizationId);
        if (organizationData) {
          setWebData(organizationData);
        } else {
          throw new Error('Organization data not found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  const handleLoginLogout = () => {
    if (loggedIn) {
      // sessionStorage.removeItem('authorid');
      // setLoggedIn(false);
      window.location.href = "/dashboard";
    } else {
        window.location.href = "/login";
    //   sessionStorage.setItem('authorid', 'some-author-id'); // Replace 'some-author-id' with actual authorId
      setLoggedIn(true);
    }
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        {webData && <Navbar.Brand href="/home"><img src={webData.organization.logo} alt={webData.organization.adminOrganization} width={100}/></Navbar.Brand>}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#home" className='me-4'>Home</Nav.Link>
            <Nav.Link href="#about" className='me-4'>About</Nav.Link>
            <Nav.Link href="#topics" className='me-4'>Topics</Nav.Link>
            <Nav.Link href="#contact" className='me-4'>Contact</Nav.Link>
          </Nav>
          <Button className='btn-fill-lg btn-gradient-yellow btn-hover-bluedark' onClick={handleLoginLogout}>
            {loggedIn ? 'Dashboard' : 'Login'}
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default OrgHeader;
