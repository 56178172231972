import { Button, Col, Form, Row, Table } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import SelectField from "./SelectField";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { get, post, put } from "./rest";
import { useLocation } from "react-router-dom";

function EditCourseCalendar() {
  const [formData, setFormData] = useState({
    start_date: null,
    end_date: null,
    course: null,
    price: "",
    slots: [],
  });
  const [id, setId] = useState(null);
  const location = useLocation();
  const [optionsCourses, setOptionsCourses] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchCourseCalendar(id);
    fetchCourseOptions();
  }, [location.search]);

  const [events, setEvents] = useState([]);

  const fetchCourseOptions = async () => {
    try {
      const response = await get("courses");
      const options = response.response.map((course) => ({
        value: course.id,
        label: course.course_title,
      }));
      setOptionsCourses(options);
    } catch (error) {
      console.error("Error fetching Courses options:", error);
    }
  };

  const fetchCourseCalendar = async (id) => {
    try {
      const response = await get("coursecalendar/" + id);
      const eventData = response.response;
      debugger;
      setFormData({
        start_date: eventData.start_date,
        end_date: eventData.end_date,
        course: eventData.course.id,
        price: eventData.price,
        slots: eventData.slots,
      });
    } catch (error) {
      console.error("Error fetching Course Calendar:", error);
    }
  };

  const [newSlots, setNewSlots] = useState([]);

  const handleAddSlot = () => {
    const newSlot = {};
    setNewSlots([...newSlots, newSlot]);
  };

  const handleSlotChange = (index, key, value) => {
    const updatedSlots = [...newSlots];
    updatedSlots[index] = {
      ...updatedSlots[index],
      [key]: value,
    };
    setNewSlots(updatedSlots);
  };

  const handleDeleteSlot = (index) => {
    const updatedSlots = [...formData.slots];
    updatedSlots.splice(index, 1);
    setFormData({ ...formData, slots: updatedSlots });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = {
        ...formData,
        slots: [...formData.slots, ...newSlots],
        updatedBy : sessionStorage.getItem("authorid")
      };
      const calendarResponse = await put(
        "coursecalendar/" + id,
        updatedFormData
      );
      if (calendarResponse.statusCode === 200) {
        toast.success("Form Updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            window.location.href = "/coursecalendar";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <DashboardContent pageTitle="Edit Course Calendar">
      <div className="card dashboard-card-ten">
        <div className="card-body">
          <Form onSubmit={handleSubmit} className="my-5">
            <Row>
              <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="start_date"
                  className="form-control"
                  value={formData.start_date}
                  onChange={(e) =>
                    setFormData({ ...formData, start_date: e.target.value })
                  }
                />
              </Col>
              <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="end_date"
                  className="form-control"
                  value={formData.end_date}
                  onChange={(e) =>
                    setFormData({ ...formData, end_date: e.target.value })
                  }
                />
              </Col>
              <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                <Form.Label>Course:</Form.Label>
                <SelectField
                  options={optionsCourses}
                  placeholder="Select Course"
                  value={formData.course}
                  onChange={(selectedOptionCourses) =>
                    setFormData({ ...formData, course: selectedOptionCourses })
                  }
                  selected={formData.course}
                />
              </Col>
              <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                <Form.Label>Price:</Form.Label>
                <Form.Control
                  type="text"
                  name="price"
                  className="form-control"
                  value={formData.price}
                  onChange={(e) =>
                    setFormData({ ...formData, price: e.target.value })
                  }
                />
              </Col>
              <Col>
                <Button
                  onClick={handleAddSlot}
                  className="my-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                >
                  Add Slot
                </Button>
              </Col>

              {newSlots.map((slot, index) => (
                <div key={index}>
                  <Row>
                    <Col className="form-group">
                      <Form.Control
                        type="time"
                        name={`slots[${index}].startTime`}
                        value={slot.startTime}
                        onChange={(e) =>
                          handleSlotChange(index, "startTime", e.target.value)
                        }
                      />
                    </Col>
                    <Col className="form-group">
                      <Form.Control
                        type="time"
                        name={`slots[${index}].endTime`}
                        value={slot.endTime}
                        onChange={(e) =>
                          handleSlotChange(index, "endTime", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </Row>
            <Row>
              <Col md={12}>
                <Table bordered striped responsive>
                  <thead>
                    <tr>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.slots.map((slot, index) => (
                      <tr key={index}>
                        <td>{slot.startTime}</td>
                        <td>{slot.endTime}</td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteSlot(index)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  type="submit"
                  className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditCourseCalendar;
