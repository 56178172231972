import React, { useEffect, useState } from 'react';
import DashboardContent from './DashboardContent';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import YearPicker from './YearPicker'; // Import the YearPicker component
import { get, post } from './rest';
import SelectField from './SelectField';

function CreateDefaultCourse() {
  const [formData, setFormData] = useState({
  });
  const [selectedYear, setSelectedYear] = useState(null);
  const [optionsIndustries, setOptionsIndustries] = useState([]);
  const [selectedOptionIndustries, setSelectedOptionIndustries] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState('');
  const [uploading, setUploading] = useState(false);
  const [creating, setCreating] = useState(false);
  const handleYearChange = (year) => {
    debugger;
    setSelectedYear(year);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);
    const roleid = sessionStorage.getItem("roleid");
    const roleslug = sessionStorage.getItem("roleslug");
    const organizationid = sessionStorage.getItem("organizationid");
    const authorid = sessionStorage.getItem("authorid");
    var CourseCluster = '';
    if (roleslug === 'superadmin'){
      CourseCluster = '65eef8496cbe0baacc3aab6f';
    } else {
      CourseCluster = '65eef8586cbe0baacc3aae21';
      formData.organization = organizationid;
    }
    try {
      const form = e.target;
      const formData = new FormData(form);
  
      // Convert FormData to JSON object
      const formDataJSON = {};
      formData.forEach((value, key) => {
        formDataJSON[key] = value;
      });
      if (roleslug !== 'superadmin'){      
        formDataJSON.organization = organizationid;
      }
      // Add additional data to formDataJSON if needed
      formDataJSON.category = selectedOptionIndustries;
      formDataJSON.year = selectedYear;
      formDataJSON.thumbnail = thumbnailURL;
      formDataJSON.CourseCluster = CourseCluster;
      formDataJSON.role = roleid;
      formDataJSON.createdBy = authorid;
  
      // Post formDataJSON to the backend
      const responseData = await post('courses', formDataJSON);
  
      if (responseData.statusCode === 201) {
        toast.success("Created Successfully", {
          onClose: () => {
            window.location.href = '/course';
          },
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setCreating(false);
    }
  };
  
  const handleThumbnailChange = async (event) => {
    setUploading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('files', file);
      formData.append('path', 'prod/default/courses/thumbnail'); 
    
      try {
          debugger;
          const response = await fetch('http://api.campusplus360.in/upload', {
              method: 'POST',
              body: formData,
          }); 
          if (response.status === 201) { 
              const data = await response.json(); 
              debugger;
              if (data.response.length > 0) {
                const firstObject = data.response[0]; 
                const url = firstObject.url;
                setThumbnailURL(url); 
                toast.success('Image Uploaded successfully');
              } 
          } else {
              toast.error('Failed to upload image');
          }
      } catch (error) {
          console.error('Error uploading image:', error);
          toast.error('Failed to upload image');
      } finally {
        setUploading(false); 
      }
  };
  useEffect(() => {
    fetchIndustriesOptions();  
}, []);
  const fetchIndustriesOptions = async () => {
    try {
        const response = await get('categories');
        debugger;
        const options = response.response.map(category => ({
            value: category.id, // Assuming _id is unique and can be used as the value
            label: category.industry // Using the role property as the label
        }));
        setOptionsIndustries(options); // Set optionsIndustries directly from the response
    } catch (error) {
        console.error('Error fetching Industries options:', error);
    }
};
const handleChangeIndustries = (selectedOptionIndustries) => {
  setSelectedOptionIndustries(selectedOptionIndustries);
  setFormData(prevState => ({
      ...prevState,
      category: selectedOptionIndustries.value 
  }));
};
  return (
    <DashboardContent pageTitle="Default Course">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Create Default Course</h3>
                </div>
              </div>
              <div className="student-info">
                <Form onSubmit={handleSubmit} id="createDefaultCourse" className="mt-5">
                  <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Course Title:</Form.Label>
                      <Form.Control type="text" id="course_title" name="course_title" className="form-control" required />
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Categories:</Form.Label>
                      <SelectField options={optionsIndustries} placeholder="Select Category" onChange={handleChangeIndustries} />
                    </Col>
                    </Row>
                    <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Image:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                              <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" onChange={handleThumbnailChange} required />
                          </div>
                          <small>Note: Please upload the thumbnail in <span style={{color:'red'}}>360x160</span> pixels only.</small>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Price:</Form.Label>
                      <Form.Control type="number" id="price" name="price" className="form-control" required />
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label >Course Summary :</Form.Label>
                      <Form.Control 
                          as="textarea" 
                          id="course_description" 
                          name="course_description" 
                          className="form-control" 
                          required 
                        />       
                    </Col>  
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Year:</Form.Label><br/>
                      <YearPicker selected={selectedYear} onChange={handleYearChange} />
                    </Col>
                    {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Upload Video:</Form.Label>
                    <Form.Control 
                      type="file" 
                      accept="video/*" 
                      onChange={handleFileChange} 
                    />
                    </Col> */}
                    </Row>
                    <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        {uploading ? 'Uploading...' : 'Create Default Course'}
                        
                      </Button>
                      <p>{creating ? 'Creating' : ''}</p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default CreateDefaultCourse;
