import React from 'react';
import './BarChart.css';

const BarChart = ({ data }) => {
  debugger;
  const maxValue = Math.max(...data.map(entry => entry.value));

  return (
    <div className="bar-chart-container">
      <div className="bar-chart">
        {data.map((entry, index) => (
          <div
            key={index}
            className="bar"
            style={{ height: maxValue === 0 ? '0%' : `${(entry.value / maxValue) * 100}%` }} // Normalize height and handle maxValue being 0
          >
            <p className="bar-value">{entry.value > 0 ? entry.value : ''}</p>
            <span className="bar-label">{entry.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
