import DashboardContent from './DashboardContent';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { put, get, post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import React, { useState, useEffect } from "react";
import SelectField from "./SelectField";
import Select from "react-select";

function UploadDocument(){
    const [formData, setFormData] = useState({});
    const [docData, setDocData] = useState({});
    const [docURL, setDocURL] = useState('');

    const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);
    const [selectedCourseName, setSelectedCourseName] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserName, setSelectedUserName] = useState(null);
    const [students, setStudents] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [optionsCourses, setOptionsCourse] = useState([]);
    const fetchCourseOptions = async () => {
      const organizationid = sessionStorage.getItem("organizationid");
  
      try {
        const response = await get('courses');
        const options = response.response.filter(cours=> cours.organization && cours.organization === organizationid ).map(course => ({
          value: course.id,
          label: course.course_title
        }));
        setOptionsCourse(options);
      } catch (error) {
        console.error('Error fetching Courses options:', error);
      }
    };
    const fetchStudents = async (selectedOptionCourses) => {
      const organizationid = sessionStorage.getItem("organizationid");
      try {
        const response = await get('usercourseenrollment');
        const studentsData = response.response.filter(user => user.organization && user.organization.id === organizationid && user.course.id === selectedOptionCourses).map(user => ({
          value: user.user.id,
          label: user.user.username
        }));
        setStudents(studentsData);
      } catch (error) {
        console.error('Error fetching Students:', error);
      }
    };
    const handleChangeCourses = (selectedOptionCourses) => {
      debugger;
      setSelectedOptionCourses(selectedOptionCourses.value);
      setSelectedCourseName(selectedOptionCourses.label);
      // setFormData(prevState => ({
      //   ...prevState,
      //   course: selectedOptionCourses.value
      // }));
      fetchStudents(selectedOptionCourses.value);
    };
    const handleChangeUser = (selectedOptionUsers) => {
      debugger;
      setSelectedUserId(selectedOptionUsers.value);
      setSelectedUserName(selectedOptionUsers.label);
      fetchUsers(selectedOptionUsers.value);
      // setFormData(prevState => ({
      //   ...prevState,
      //   user: selectedOptionUsers
      // }));
    };
    useEffect(() => {
      fetchCourseOptions();
      }, []);
      const handleDocumentChange = async (event) => {
        setUploading(true);
          const file = event.target.files[0];
          const formData = new FormData();
          formData.append('files', file);
          formData.append('path', 'prod/default/users/documents/'+ selectedUserId); 
        
          try {
              debugger;
              const response = await fetch('http://api.campusplus360.in/upload', {
                  method: 'POST',
                  body: formData,
              }); 
              if (response.status === 201) { 
                  const data = await response.json(); 
                  debugger;
                  if (data.response.length > 0) {
                    const firstObject = data.response[0]; 
                    const url = firstObject.url;
                    setDocURL(url); 
                    toast.success('Uploaded successfully');
                  } 
              } else {
                  toast.error('Failed to upload');
              }
          } catch (error) {
              console.error('Error uploading:', error);
              toast.error('Failed to upload');
          } finally {
            setUploading(false); 
          }
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        setDocData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
      const fetchUsers = async (id) => {
        try {
          get('users/' + id)
            .then(response => {
              debugger;
              var orgAdmins = response.response;       
    
              setFormData(orgAdmins);
            })
            .catch(error => console.error('Error fetching data:', error));          
      
        } catch (error) {
          console.error('Error fetching Users:', error);
        }
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {

          // Create a new document object
    const newDocument = {
      course: selectedOptionCourses,
      coursename: selectedCourseName,
      name: docData.title, // Ensure docData.title is set correctly
      url: docURL
    };

    // Update formData by appending the new document object to the existing docURL array
    const updatedFormData = {
      ...formData,
      docURL: [
        ...(formData.docURL || []), // Spread existing docURL array
        newDocument // Add the new document object
      ],
      updatedBy : sessionStorage.getItem("authorid")
    };
          debugger;
          const responseData = await put('users/' + selectedUserId, updatedFormData);
          // toast.success(responseData.response);
          if(responseData.statusCode === 200){
            toast.success("Created Successfully", {
              onClose: () => {
                window.location.href='/documents';
              },
            });
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };
    return(
        <DashboardContent pageTitle="Upload Document">
 <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            {/* <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Create Role</h3>
                                </div>
                            </div> */}
                            <div className="student-info">
                            <Form onSubmit={handleSubmit} id="createRole" className="">
                            <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                <Form.Label>Course:</Form.Label>
                                <Select options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses} isSearchable required/>
                                    {/* <Form.Control type="text" id="role" name="role" className="form-control" onChange={handleChange} required /> */}
                                </Col>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                <Form.Label>Users:</Form.Label>
                                <Select options={students} placeholder="Select User" onChange={handleChangeUser} isSearchable required/>
                                    {/* <Form.Control type="text" id="role" name="role" className="form-control" onChange={handleChange} required /> */}
                                </Col>
                                </Row>
                                <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                <Form.Label>Document Name:</Form.Label>
                                    <Form.Control type="text" id="title" name="title" className="form-control" onChange={handleChange} required />
                                </Col>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                <Form.Label>Upload Document:</Form.Label>
                                <Form.Control type="file" id="imgfile" className="image-upload" onChange={handleDocumentChange} required />
                                </Col>
                                </Row>
                <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                                  {uploading ? 'Uploading...' : 'Upload Document'}
                                </Button>
                                </Col>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        <ToastContainer />
        </DashboardContent>
    );
}
export default UploadDocument;