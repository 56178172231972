import { useEffect, useState, useRef } from "react";
import OrgHeader from "./OrgHeader";
import { get } from "./rest";
import BannerSlider from "./BannerSlider";
import DOMPurify from "dompurify";
import { Button, Card, CardBody, CardHeader, Col, Container, Nav, Row, Carousel, Modal } from "react-bootstrap";
import RazorpayButton from "./RazorpayButton";
import "./Home.css";
import "../App.css";

function Home() {
    const [webData, setWebData] = useState();
    const [courses, setCourses] = useState([]);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [userDetails, setUserDetails] = useState(null);

    const formRef = useRef(null);

    const fetchDefaultCourse = async () => {
        try {
            const response = await get('courses');
            const role = sessionStorage.getItem("roleslug");
            const organizationid = sessionStorage.getItem("organizationwebsiteid") || sessionStorage.getItem("organizationid");
            let coursedata = '';

            if (role === 'superadmin') {
                coursedata = response.response.filter(admin => admin.role.slug !== "employee" && admin.role.slug !== "organizationadmin");
            } else if (role === 'organizationadmin') {
                coursedata = response.response.filter(admin => admin.role.slug !== 'superadmin' && admin.organization && admin.organization === organizationid);
            } else {
                coursedata = response.response.filter(admin => admin.organization && admin.organization === organizationid);
            }
            setCourses(coursedata);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const fetchUserDetails = async (authorId) => {
        try {
            const response = await get(`users/${authorId}`);
            setUserDetails(response.response);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        async function fetchData() {
            sessionStorage.removeItem("organizationwebsiteid");

            try {
                let organizationId = sessionStorage.getItem("organizationid");
                if (!organizationId) {
                    const queryParams = new URLSearchParams(window.location.search);
                    const organizationName = queryParams.get('org');

                    if (organizationName) {
                        const response = await get('OrganizationWebsite');
                        const data = response.response;
                        const organizationData = data.find(org => org.organization && org.organization.adminOrganization.toLowerCase() === organizationName.toLowerCase());

                        if (organizationData) {
                            setWebData(organizationData);
                            sessionStorage.setItem("organizationwebsiteid", organizationData.organization.id);
                        } else {
                            throw new Error('Organization data not found');
                        }
                    } else {
                        throw new Error('Organization name not provided in URL');
                    }
                } else {
                    const response = await get('OrganizationWebsite');
                    const data = response.response;
                    const organizationData = data.find(org => org.organization && org.organization.id === organizationId);
                    
                    if (organizationData) {
                        setWebData(organizationData);
                    } else {
                        throw new Error('Organization data not found');
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
        fetchDefaultCourse();
    }, []);

    const chunkArray = (array, chunkSize) => {
        const results = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }
        return results;
    };

    const handleClosePaymentModal = () => {
        setShowPaymentModal(false);
    };

    const checkCourseAccess = async (course, courseType) => {
        const authorId = sessionStorage.getItem("authorid");
        const response = await get(`/usercourseenrollment`);
        const usercourse = response.response.filter(user => user.user.id === authorId);
        const enrolledCourse = usercourse.find(c => c.course.id === course.id && c.user.id === authorId);
        debugger;
        if (enrolledCourse || course.price === '0') {
            debugger;
            const redirectUrl = courseType === 'lms' ? `/lms?id=${course.id}` : `/quiz?id=${course.id}`;
            window.location.href = redirectUrl;
        } else {
            // User needs to pay for the course
            try {
                await fetchUserDetails(authorId);
                setSelectedCourse(course);
                setShowPaymentModal(true);
            } catch (error) {
                console.error('Error checking course access:', error);
            }
        }
    };
    const courseChunks = chunkArray(courses, 3);

    return (
        <>
            <OrgHeader />
            {webData && (
                <>
                    <BannerSlider banners={webData.banners} id="home" />
                    <Container id="about">
                        <Row>
                            <Col className="pt-5">
                                <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(webData.title) }} className="text-center"></h2>
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(webData.paragraph1) }} className="mb-5"></div>
                                <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(webData.title2) }} className="text-center"></h2>
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(webData.paragraph2) }} className="mb-5"></div>
                            </Col>
                        </Row>
                        <Row className="courseslist mb-5" id="topics">
                            {/* <h2>Courses</h2> */}
                            {/* <Carousel> */}
                                {courseChunks.map((courseChunk, index) => (
                                    // <Carousel.Item key={index}>
                                        <Row className="courseslist">
                                            {courseChunk.map((course, courseIndex) => (
                                                <Col md={4} className="my-5" key={courseIndex}>
                                                    {/* <Card className="flex-fill">
                                                        <CardHeader className="p-0">
                                                            {course.price > 0 && (
                                                                <i className="fa-brands fa-square-web-awesome-stroke premium-icon"></i>
                                                            )}
                                                            <img src={course.thumbnail} alt={course.course_title} className="img-fluid" height="150px" />
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h3>{course.course_title}</h3>
                                                            <p>Category: {course.category.industry}</p>
                                                            <Button className="me-2" onClick={() => checkCourseAccess(course, 'lms')}>LMS</Button>
                                                            <Button onClick={() => checkCourseAccess(course, 'quiz')}>Quiz</Button>
                                                        </CardBody>
                                                    </Card> */}
                                                    <Card className="flex-fill">
                                                        <CardHeader className="p-0">
                                                            {course.price > 0 && (
                                                                <i className="fa-brands fa-square-web-awesome-stroke premimum-icon"></i>
                                                            )}
                                                            <img src={course.thumbnail} alt={course.course_title} className="img-fluid" height="150px" />
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h3>{course.course_title}</h3>
                                                            <Button className="me-2 secondary-btn" onClick={() => checkCourseAccess(course, 'lms')}>LMS</Button>
                                                            <Button className="secondary-btn" onClick={() => checkCourseAccess(course, 'quiz')}>Quiz</Button>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    // </Carousel.Item>
                                ))}
                            {/* </Carousel> */}
                        </Row>
                    </Container>
                    <div id="contact">
                        {/* <EnquiryForm className="my-5" /> */}
                    </div>
                    <div className="footer">
                        <Container className="py-5">
                            <Row className="align-items-center">
                                <Col>
                                    <img src={webData.organization.logo} alt={webData.organization.adminOrganization} width={100} />
                                    <p className="mt-4">
                                        <b>Address: </b>
                                        {webData.organization.adminaddress}
                                        <br />
                                        <b>Area: </b>{webData.organization.adminarea}
                                    </p>
                                </Col>
                                <Col>
                                    {/* <Nav className="mx-auto">
                                        <Nav.Link href="#home">Home</Nav.Link>
                                        <Nav.Link href="#about">About</Nav.Link>
                                        <Nav.Link href="#courses">Courses</Nav.Link>
                                        <Nav.Link href="#contact">Contact</Nav.Link>
                                    </Nav> */}
                                </Col>
                                <Col>
                                    {webData.organization.adminemail && <p><b>Email:</b> {webData.organization.adminemail}</p>}
                                    {webData.organization.adminphone && <p><b>Phone:</b> {webData.organization.adminphone}</p>}
                                    {webData.facebook && <a href={webData.facebook} className="me-4"><i className="fa-brands fa-facebook fa-2x"></i></a>}
                                    {webData.youtube && <a href={webData.youtube} className="me-4"><i className="fa-brands fa-youtube fa-2x"></i></a>}
                                    {webData.twitter && <a href={webData.twitter} className="me-4"><i className="fa-brands fa-twitter fa-2x"></i></a>}
                                    {webData.instagram && <a href={webData.instagram} className="me-4"><i className="fa-brands fa-instagram fa-2x"></i></a>}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            )}

            <Modal show={showPaymentModal} onHide={handleClosePaymentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RazorpayButton course={selectedCourse} userDetails={userDetails} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Home;
