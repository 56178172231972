import { useEffect, useState } from "react";
import { get } from "./rest";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "react-bootstrap";
import OrgHeader from "./OrgHeader";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import slugify from "slugify";


function Courses() {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        fetchDefaultCourse();
      }, []);
    
      const fetchDefaultCourse = async () => {
        try {
          get('courses')
              .then(response => {
                debugger;
                var role = sessionStorage.getItem("roleslug");
              var organizationid = sessionStorage.getItem("organizationid");
              var coursedata ='';
              if (role === 'superadmin') {
                coursedata = response.response.filter(admin => admin.role.slug !== "employee" && admin.role.slug !== "organizationadmin" );
              } else if (role === 'organizationadmin') {
                coursedata = response.response.filter(admin => admin.role.slug !== 'superadmin' && admin.organization === organizationid);
              } else {
                // coursedata = response.response;
                coursedata = response.response.filter(admin => admin.organization === organizationid);
              }
                setCourses(coursedata);
                // setFilteredDefaultCourse(coursedata);
              })
              .catch(error => console.error('Error fetching data:', error)); 
         
        } catch (error) {
          console.error('Error fetching DefaultCourse:', error);
        }
      };
    
    return ( 
        
        <>
            <OrgHeader />
            <Container>
            <Row className="courseslist">
                {courses.map((course, index) => (
                    <Col md={4} className="my-5">
                        <Link to={`/coursedetail?id=${course.id}`} >
                            <Card key={index} className="flex-fill">
                                <CardHeader className="p-0">
                                {course.price > 0 && (
                                    <i class="fa-brands fa-square-web-awesome-stroke premimum-icon"></i>
                                )}
                                    <img src={course.thumbnail} alt={course.course_title} className="img-fluid" height="150px" />
                                </CardHeader>
                                <CardBody>                              
                                    <h3>{course.course_title}</h3>
                                    <p>Category: {course.category.industry}</p>
                                    
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
            </Container>
            
        </>
     );
}

export default Courses;