import { Col, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import PaymentGraph from "./PaymentGraph";
import { useEffect, useState } from "react";
import { get } from "./rest";

function DashboardSuperAdmin() {
  const [course, setCoursesCount] = useState();
  const [enquiries, setEnquiriesCount] = useState();
  const [org, setOrgCount] = useState();

  useEffect(() => {
    fetchCourseCount();
    fetchEnquiriesCount();
    fetchOrgCount();
  }, []);

  const fetchCourseCount = async () => {
    try{
      get('/courses/count')
      .then(response => {
        setCoursesCount(response.response);
      })
    } catch {
    }
  }
  const fetchEnquiriesCount = async () => {
    try{
      get('enquiries/count')
      .then(response => {
        setEnquiriesCount(response.response);
      })
    } catch {
    }
  }
  const fetchOrgCount = async () => {
    try{
      get('/organization/count')
      .then(response => {
        setOrgCount(response.response);
      })
    } catch {
    }
  }
  return (
    <DashboardContent pageTitle="Dashboard">
      {/* <PaymentGraph /> */}
      <Row>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-green ">
                  <i className="fa fa-landmark text-green"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">No. of Active Organizations</div>
                  <div className="item-number" id="ClassCount">
                    {org}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-blue ">
                  <i className="fa-solid fa-chart-line text-blue"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">No. of Leads Received</div>
                  <div className="item-number" id="ClassCount">
                    {enquiries}
                    2
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-yellow ">
                  <i className="fa-brands fa-google text-yellow"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Google Analytics User Hits Count</div>
                  <div className="item-number" id="ClassCount">
                    0
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-magenta ">
                <i className="fa-solid fa-chalkboard-user text-mauvelous"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Course Count</div>
                  <div className="item-number" id="ClassCount">
                    {course}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </DashboardContent>
  );
}

export default DashboardSuperAdmin;
