import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from "react";


function CreateIndustry() {
    const [formData, setFormData] = useState({
    });
    const [response, setResponse] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [thumbnailURL, setThumbnailURL] = useState('');

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
    const handleThumbnailChange = async (event) => {
      setUploading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        formData.append('path', 'prod/default/courses/thumbnail'); 
      
        try {
            debugger;
            const response = await fetch('http://api.campusplus360.in/upload', {
                method: 'POST',
                body: formData,
            }); 
            if (response.status === 201) { 
                const data = await response.json(); 
                debugger;
                if (data.response.length > 0) {
                  const firstObject = data.response[0]; 
                  const url = firstObject.url;
                  setThumbnailURL(url); 
                  toast.success('Thumbnail Uploaded successfully');
                } 
            } else {
                toast.error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Failed to upload image');
        } finally {
          setUploading(false); 
        }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();

      var role = sessionStorage.getItem("roleslug");
      var organizationid = sessionStorage.getItem("organizationid");
      var organization ='';
      if (role !== 'superadmin') {
        organization = organizationid;
      }

      const formDataToSend = {
        ...formData,
        thumbnail: thumbnailURL,
        createdBy: sessionStorage.getItem("authorid"),           
      };
      
      if (role !== 'superadmin') {
        formDataToSend.organization = organization;
      }
      try {
        const responseData = await post('categories', formDataToSend);
        setResponse(responseData);
        // toast.success(responseData.response);
        debugger;
        if(responseData.statusCode === 201){
          toast.success("Created Successfully", {
            onClose: () => {
              window.location.href='/categories';
            },
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };
  

    return ( 
        <DashboardContent pageTitle="Create Category">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Create Category</h3>
                                </div>
                            </div>
                            <div className="student-info">
                            <Form onSubmit={handleSubmit} id="createIndustry" className="mt-5">
                            <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    <Form.Label>Category Title:</Form.Label>
                                    <Form.Control type="text" id="industry" name="industry" className="form-control" onChange={handleChange} required />
                                </Col>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Thumbnail:</Form.Label>
                      {/* <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options"> */}
                              <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" onChange={handleThumbnailChange} required />
                          {/* </div>
                        </div>
                      </div> */}
                    </Col>
                    </Row>
                <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        {uploading ? 'Uploading...' : 'Create Category'}
                        
                      </Button>
                      <p>{creating ? 'Creating' : ''}</p>
                                </Col>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        <ToastContainer />
    </DashboardContent>
    );
}

export default CreateIndustry;