import React, { useState, useEffect } from 'react';
import DashboardContent from "./DashboardContent";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { get, del } from './rest';

function DefaultCourse() {
  const [DefaultCourse, setDefaultCourse] = useState([]);
  const [filteredDefaultCourse, setFilteredDefaultCourse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [DefaultCoursePerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {

    fetchDefaultCourse();
  }, []);

  const fetchDefaultCourse = async () => {
    try {
      get('courses')
          .then(response => {
            debugger;
            var role = sessionStorage.getItem("roleslug");
          var organizationid = sessionStorage.getItem("organizationid");
          var coursedata ='';
          if (role === 'superadmin') {
            coursedata = response.response.filter(admin => admin.role.slug !== "employee" && admin.role.slug !== "organizationadmin" );
          } else if (role === 'organizationadmin') {
            coursedata = response.response.filter(admin => admin.role.slug !== 'superadmin' && admin.organization === organizationid);
          } else {
            coursedata = response.response.filter(admin => admin.organization === organizationid);
          }
            setDefaultCourse(coursedata);
            setFilteredDefaultCourse(coursedata);
          })
          .catch(error => console.error('Error fetching data:', error)); 
     
    } catch (error) {
      console.error('Error fetching DefaultCourse:', error);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterDefaultCourse(e.target.value);
  };
  async function handleDelete(orgId) {
    try {
      await del(`courses/${orgId}`);
      // Refresh organizations after deletion
      fetchDefaultCourse();
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  }

  const filterDefaultCourse = (query) => {
    const filteredCourses = DefaultCourse.filter(course =>
      (course.course_title && course.course_title.toLowerCase().includes(query.toLowerCase())) ||
      (course.category && course.category.industry && course.category.industry.toLowerCase().includes(query.toLowerCase())) ||
      (course.year && course.year.toString().toLowerCase().includes(query.toLowerCase())) ||
      (course.price && course.price.toString().toLowerCase().includes(query.toLowerCase()))
    );
    setFilteredDefaultCourse(filteredCourses);
    setCurrentPage(1);
  };
  

  const indexOfLastcourse = currentPage * DefaultCoursePerPage;
  const indexOfFirstcourse = indexOfLastcourse - DefaultCoursePerPage;
  const currentcourses = filteredDefaultCourse.slice(indexOfFirstcourse, indexOfLastcourse);

  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredDefaultCourse.length / DefaultCoursePerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredDefaultCourse.length / DefaultCoursePerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <DashboardContent pageTitle="Default Courses">
      <div>
        <Row className='text-right my-5 d-flex align-items-center'>
        <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>
          <Col md={{span:3, offset:5}}>
          <Button type="button" onClick={()=>window.location='/createcourse'} className="fw-btn-fill btn-gradient-yellow">Create Default Course</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" ">
         
          {/* <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="area" value={searchQuery} onChange={handleChange} placeholder="SearchArea ..." className="form-control" onChange={handleChange} />
          </div>
          <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="username" value={searchQuery} onChange={handleChange} placeholder="SearchUsername ..." className="form-control" onChange={handleChange} />
          </div> */}
          {/* <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
            <button type="submit" className="fw-btn-fill btn-gradient-yellow">SEARCH</button>
          </div> */}
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Image</th>
                <th>Title</th>
                <th>Category</th>
                <th>Price</th>
                <th>Year</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedDefaultCourse">
          
              {/* Render Default Course */}
              {currentcourses.map((course, index) => (
                <tr key={index}>
                  <td><img src={course.thumbnail} alt='courseimage' width={100}></img></td>
                  <td>{course.course_title}</td>
                  <td>{course.category.industry}</td>
                  <td>{course.price}</td>
                  <td>{course.year}</td>
                  <td><i onClick={()=>window.location='/editcourse?id='+ course.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(course.id)} className="fa-regular fa-trash-can"></i></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredDefaultCourse.length / DefaultCoursePerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredDefaultCourse.length / DefaultCoursePerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default DefaultCourse;
