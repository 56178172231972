import { useEffect, useState } from "react";
import { get, post } from "./rest";
import { useLocation } from "react-router-dom";
import { Col, Container, Row, Nav, Tab, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './CourseDetail.css';
import VerticalAccordionView from "./VerticalAccordionView";
import DOMPurify from 'dompurify';
import VideoPlayer from "./VideoPlayer";

function CourseDetailedView() {
    const [id, setId] = useState(null);
    const location = useLocation();
    const [courseDetails, setCourseDetails] = useState([]);
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [tabDataIndex, setTabDataIndex] = useState(0);
    const [tabItemIndex, setTabItemIndex] = useState(0);
    const [endReached, setEndReached] = useState(false);
    const [globalEvents, setGlobalEvents] = useState([]);
    const [goToNextUnit, setGoToNextUnit] = useState(false);
    const [isCourseCompleted, setIsCourseCompleted] = useState(false); // New state for course completion
    const [currentEvent, setCurrentEvent] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get("id");
        setId(id);
        fetchModules(id);
        fetchLMS(id);
        // fetchGlobalEvents();
    }, [location.search]);

    useEffect(() => {
        if (id) {
            if (selectedModule && selectedChapter) {
                fetchLMS(id, selectedModule, selectedChapter);
            } else if (selectedModule) {
                fetchLMS(id, selectedModule);
            } else {
                fetchLMS(id);
            }
        }
    }, [id, selectedModule, selectedChapter]);
    useEffect(() => {
        const fetchAndUpdateEvents = async () => {
            try {
                const response = await get('globalevents', {
                    params: {
                        organization: sessionStorage.getItem("organizationid"),
                        user: sessionStorage.getItem("authorid"),
                        type: "lessonevent"
                    }
                });
                
                const userFilteredEvents = response.response
                    .filter(event => event.user?._id === sessionStorage.getItem("authorid") 
                                    && (event.type === "lessonevent" || event.type === "coursecompleted") 
                                    && event.course === id);
                
                setGlobalEvents(userFilteredEvents);
                debugger
                setCurrentEvent(getLastEvent(userFilteredEvents));
            } catch (error) {
                console.error('Error fetching global events:', error);
            }
        };
    
        if (id) {
            fetchAndUpdateEvents();
        }
    }, [id]);
    const fetchModules = async (id) => {
        try {
            const response = await get('coursemodule?course=' + id);
            const options = response.response.map((mod) => ({
                title: mod.id,
                content: mod.title,
            }));
            setModules(options);
        } catch (error) {
            console.error("Error fetching Modules:", error);
        }
    };

    const fetchLMS = async (id, moduleId = null, chapterId = null) => {
        try {
            const response = await get('lms');
            const organizationid = sessionStorage.getItem("organizationid");
            const matchingCourses = response.response.filter(
                item => item.course?.id === id &&
                    item.organization === organizationid &&
                    (!moduleId || item.courseModule.id === moduleId) &&
                    (!chapterId || item.courseChapter.id === chapterId)
            );

            setCourseDetails(matchingCourses);
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    };

    // const fetchGlobalEvents = async () => {
    //     try {
    //         const userId = sessionStorage.getItem("authorid");
    //         const response = await get('globalevents', {
    //             params: {
    //                 organization: sessionStorage.getItem("organizationid"),
    //                 user: userId,
    //                 type: "lessonevent"
    //             }
    //         });

    //         const userFilteredEvents = response.response
    //         .filter(event => 
    //             event.user && event.user._id === sessionStorage.getItem("authorid") 
    //             && (event.type === "lessonevent" || event.type === "coursecompleted") 
    //             && event.course && event.course === id
    //         );
    //             console.log("all events", response.response);    
    //         setGlobalEvents(userFilteredEvents);
    //         console.log("global events", globalEvents);
    //         debugger
    //         setCurrentEvent(getLastEvent(globalEvents));

    //         debugger
    //         processGlobalEvents(userFilteredEvents);
    //     } catch (error) {
    //         console.error('Error fetching global events:', error);
    //     }
    // };

    const processGlobalEvents = (events) => {
        if (events.length > 0) {
            const lastEvent = events[events.length - 1];
            const lastIndex = lastEvent.index || 0;
            const maxItemsPerTab = 4;

            const lastTabDataIndex = Math.floor(lastIndex / maxItemsPerTab);
            const lastTabItemIndex = lastIndex % maxItemsPerTab;

            setTabDataIndex(lastTabDataIndex);
            setTabItemIndex(lastTabItemIndex);
        }
    };

    const getPrevLesson = () => {
        if (!selectedLesson || !courseDetails.length) return null;

        const currentLessonIndex = courseDetails.findIndex(lesson => lesson.id === selectedLesson.id);
        if (currentLessonIndex > 0) {
            return courseDetails[currentLessonIndex - 1];
        }
        return null;
    };

    const handleNext = async () => {
        if (selectedLesson && selectedLesson.tabData) {
            let nextTabItemIndex = tabItemIndex + 1;
            let nextTabDataIndex = tabDataIndex;
            const maxItemsPerTab = 4;

            while (nextTabDataIndex < selectedLesson.tabData.length && !isValidTab(selectedLesson.tabData[nextTabDataIndex], nextTabItemIndex)) {
                if (nextTabItemIndex < maxItemsPerTab - 1) {
                    nextTabItemIndex++;
                } else {
                    nextTabItemIndex = 0;
                    nextTabDataIndex++;
                }
            }

            if (nextTabDataIndex < selectedLesson.tabData.length) {
                setTabItemIndex(nextTabItemIndex);
                setTabDataIndex(nextTabDataIndex);
                setEndReached(false);
            } else {
                const nextLesson = getNextLesson();
                if (nextLesson) {
                    setSelectedLesson(nextLesson);
                    setTabItemIndex(0);
                    setTabDataIndex(0);
                    fetchLMS(nextLesson.course.id, nextLesson.coursemodule, nextLesson.coursechapter);
                } else {
                    setEndReached(true);
                    setGoToNextUnit(true);
                }
            }

            const index = nextTabDataIndex * maxItemsPerTab + nextTabItemIndex;
            try {
                await post('globalevents', {
                    name: `${selectedLesson.lesson_title} - ${index}`,
                    type: "lessonevent",
                    createdAt: new Date(),
                    organization: sessionStorage.getItem("organizationid"),
                    user: sessionStorage.getItem("authorid"),
                    index: index,
                    course: id
                });
                // fetchGlobalEvents();
                debugger
            } catch (error) {
                console.error('Error saving event:', error);
            }
        }
    };

    const getNextLesson = () => {
        if (!selectedLesson || !courseDetails.length) return null;

        const currentLessonIndex = courseDetails.findIndex(lesson => lesson.id === selectedLesson.id);
        if (currentLessonIndex >= 0 && currentLessonIndex < courseDetails.length - 1) {
            return courseDetails[currentLessonIndex + 1];
        }
        return null;
    };

    const handlePrevious = () => {
        if (selectedLesson && selectedLesson.tabData) {
            let prevTabItemIndex = tabItemIndex - 1;
            let prevTabDataIndex = tabDataIndex;
            const maxItemsPerTab = 4;

            if (prevTabItemIndex < 0) {
                prevTabDataIndex--;
                if (prevTabDataIndex >= 0) {
                    prevTabItemIndex = maxItemsPerTab - 1;
                }
            }

            if (prevTabDataIndex >= 0) {
                setTabItemIndex(prevTabItemIndex);
                setTabDataIndex(prevTabDataIndex);
                setEndReached(false);
            } else {
                const prevLesson = getPrevLesson();
                if (prevLesson) {
                    setSelectedLesson(prevLesson);
                    setTabItemIndex(prevLesson.tabData.length > 0 ? maxItemsPerTab - 1 : 0);
                    setTabDataIndex(prevLesson.tabData.length - 1);
                    fetchLMS(prevLesson.course.id, prevLesson.coursemodule, prevLesson.coursechapter);
                }
            }
        }
    };

    const isValidTab = (tabData, index) => {
        switch (index) {
            case 0:
                return !!tabData?.description;
            case 1:
                return !!tabData?.video_URL;
            case 2:
                return !!tabData?.liveClassLink;
            case 3:
                return !!tabData?.pdf_URL;
            default:
                return false;
        }
    };

    const handleOuterTabSelect = (key) => {
        const index = parseInt(key.replace('outerTab', ''), 10);
        setTabDataIndex(index);
        setTabItemIndex(0);
        setEndReached(index === selectedLesson.tabData.length - 1 && !isValidTab(selectedLesson.tabData[index], 0));
    };

    const handleInnerTabSelect = (key) => {
        const index = parseInt(key.replace('innerTab', ''), 10);
        setTabItemIndex(index);
        setEndReached(tabDataIndex === selectedLesson.tabData.length - 1 && index === 3);
    };

    const isLastLesson = () => {
        if (!selectedLesson || !courseDetails.length) return false;
        const currentIndex = courseDetails.findIndex(lesson => lesson.id === selectedLesson.id);
        return currentIndex === courseDetails.length - 1;
    };
    const handleCompletionStatus = async () => {
        if (isLastLesson() && endReached) {
            try {
                await post('globalevents', {
                    name: `Course ${id} Completed`,
                    type: "coursecompleted",
                    createdAt: new Date(),
                    organization: sessionStorage.getItem("organizationid"),
                    user: sessionStorage.getItem("authorid"),
                    course: id 
                });
                setIsCourseCompleted(true);
            } catch (error) {
                console.error('Error posting course completion event:', error);
            }
        }
    };

    useEffect(() => {
        handleCompletionStatus();
    }, [endReached]);

    // const fetchEvents = async (studentId) => {
    //     const organizationid = sessionStorage.getItem("organizationid");
    
    //     try {
    //       // Fetch lesson events
    //       const lessonResponse = await get("globalevents", {
    //         params: {
    //           type: "lessonevent",
    //           organization: organizationid,
    //           user: studentId,
    //         },
    //       });
    //       setLessonEvents(
    //         lessonResponse.response.filter(
    //           (event) => event.type === 'lessonevent'
    //         )
    //       );
    
    //       // Fetch course events
    //       const courseResponse = await get("globalevents", {
    //         params: {
    //           type: "courseevent",
    //           organization: organizationid,
    //           user: studentId,
    //         },
    //       });
    //       setCourseEvents(
    //         courseResponse.response.filter(
    //           (event) => event.type === 'courseevent'
    //         )
    //       );
    //     } catch (error) {
    //       console.error("Error fetching events:", error);
    //     }
    //   };
    const getLastEvent = (events) => {
        if (events.length === 0) return "No progress yet.";
        const lastEvent = events.reduce((latest, event) => {
            return new Date(event.createdAt) > new Date(latest.createdAt) ? event : latest;
        }, events[0]);
        if(lastEvent.type === "coursecompleted"){
            debugger
            return "Course Completed"
        } else {
            
            return lastEvent.name; 
        }
        
    };
    return (
        <>
            <Container fluid className="py-5" style={{ backgroundColor: "#fff" }}>
                <Row>
                    <Col md={3} className="px-0">
                        <VerticalAccordionView
                            className="p-0"
                            items={modules}
                            onModuleSelect={setSelectedModule}
                            onChapterSelect={setSelectedChapter}
                            onLessonSelect={setSelectedLesson}
                            onCompletionStatus={handleCompletionStatus}
                        />
                    </Col>
                    <Col md={9} className="px-5">
                        <p>Last Seen: {currentEvent}</p>                       
                        {selectedLesson ? (
                            <div className="my-5">
                                <Row>
                                    <Col>
                                        <h3>Course: {selectedLesson.course.course_title}</h3>
                                        <p>Lesson: {selectedLesson.lesson_title}</p>
                                    </Col>
                                </Row>
                                <Tab.Container activeKey={`outerTab${tabDataIndex}`} onSelect={handleOuterTabSelect}>
                                    <Nav variant="pills" className="flex-row mb-3">
                                        {selectedLesson.tabData.map((tab, index) => (
                                            <Nav.Item key={index}>
                                                <Nav.Link eventKey={`outerTab${index}`}>{tab.title}</Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                    <Tab.Content>
                                        {selectedLesson.tabData.map((tab, outerIndex) => (
                                            <Tab.Pane eventKey={`outerTab${outerIndex}`} key={outerIndex}>
                                                <Tab.Container activeKey={`innerTab${tabItemIndex}`} onSelect={handleInnerTabSelect}>
                                                    <Nav variant="pills" className="flex-row">
                                                        {tab.description && (
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="innerTab0">Description</Nav.Link>
                                                            </Nav.Item>
                                                        )}
                                                        {tab.video_URL && (
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="innerTab1">Video</Nav.Link>
                                                            </Nav.Item>
                                                        )}
                                                        {tab.liveClassLink && (
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="innerTab2">Join Live Class</Nav.Link>
                                                            </Nav.Item>
                                                        )}
                                                        {tab.pdf_URL && (
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="innerTab3">PDF</Nav.Link>
                                                            </Nav.Item>
                                                        )}
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="innerTab0" className="lessonplandescription">
                                                            {tab.description && (
                                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tab.description) }} />
                                                            )}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="innerTab1">
                                                            {tab.video_URL && (
                                                                <VideoPlayer videoUrl={tab.video_URL} />
                                                            )}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="innerTab2">
                                                            {tab.liveClassLink && (
                                                                <a href={tab.liveClassLink} target="_blank" rel="noopener noreferrer">
                                                                    Join Live Class
                                                                </a>
                                                            )}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="innerTab3">
                                                            {tab.pdf_URL && (
                                                                <a href={tab.pdf_URL} target="_blank" rel="noopener noreferrer">
                                                                    Download PDF
                                                                </a>
                                                            )}
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Tab.Container>
                                <Row>
                                    <Col className="d-flex justify-content-between mt-4">
                                        <Button onClick={handlePrevious} disabled={tabDataIndex === 0 && tabItemIndex === 0} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">
                                            <i className="fa fa-arrow-left"></i>
                                        </Button>
                                        {endReached ? (
                                            goToNextUnit ? (
                                                // <Button className="secondary-btn mt-5" onClick={() => { window.location.href = '/quiz?id=' + id }}>Take Quiz</Button>
                                                <Button className="plain-btn mt-5 disabled">Go to Next Unit</Button>
                                            ) : (
                                                <>
                                                <Button className="secondary-btn mt-5" disabled>
                                                    All Lessons Completed
                                                </Button>
                                                
                                                </>
                                            )
                                        ) : (
                                            <Button onClick={handleNext} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">
                                                <i className="fa fa-arrow-right"></i>
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                {isCourseCompleted && (
                                                    <div className="d-flex justify-content-center">
                                                    <Button className="secondary-btn mt-5 me-5" onClick={() => { window.location.href = '/quiz?id=' + id }}>Take Quiz</Button>
                                                    <Button className="course-completed-button mt-5 plain-btn" onClick={() => { window.location.href = '/mycourses' }}>Course Completed</Button>
                                                    </div>
                                                )}
                                {/* {isLastLesson() && (
                                    <Button className="course-completed-button mt-5">Course Completed</Button>
                                )} */}
                                 
                            </div>
                        ) : (
                            <p>Please select a lesson to see the details.</p>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CourseDetailedView;
