import React, { useState, useEffect } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { del, get } from './rest';
import SelectField from './SelectField';
import { useCallback } from 'react';

function Questions() {
    const [assessments, setAssessments] = useState([]);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [assessmentsPerPage] = useState(6);
    const [searchQuery, setSearchQuery] = useState('');

    const [optionsCourses, setOptionsCourse] = useState([]);
    const [optionsModuleCourse, setOptionsModuleCourse] = useState([]); // State for module options
    const [optionsUnitModule, setOptionsUnitModule] = useState([]); 
    const [optionsAssessment, setOptionsAssessment] = useState([]);

  
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedModule, setSelectedModule] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const [selectedAssessment, setSelectedAssessment] = useState('');
    var organizationid = sessionStorage.getItem("organizationid");

   // Fetch course options
      const fetchCourseOptions = async () => {
        try {
          const response = await get('courses');
          // Assuming response is an array of course objects with 'id' and 'course_title' properties
          const options = response.response
          .filter(course => course.organization === organizationid || !course.organization)
          .map(course => ({
            value: course.id,
            label: course.course_title
          }));
          // Set course options
          setOptionsCourse(options);
        } catch (error) {
          console.error('Error fetching Courses options:', error);
        }
      };
  
      // Handle change for Courses dropdown
      const handleChangeCourses = (selectedOptionCourses) => {
        setSelectedCourse(selectedOptionCourses);
        fetchAssessments();
        fetchCourseModuleOption(selectedOptionCourses); 
      };
  
      // Fetch module options based on selected course
      const fetchCourseModuleOption = async (selectedOptionCourses) => {
        try {
          const response = await get(`coursemodule?course=${selectedOptionCourses}`);
          // Assuming response is an array of module objects with 'id' and 'title' properties
          const options = response.response.map(module => ({
            value: module.id,
            label: module.title
          }));
          // Set module options
          setOptionsModuleCourse(options);
        } catch (error) {
          console.error("Error fetching Module options:", error);
        }
      };
  
      // Handle change for Modules dropdown
      const handleChangeModule = (selectedOptionModule) => {
        setSelectedModule(selectedOptionModule);
        fetchAssessments();
        fetchCourseUnitOption(selectedOptionModule);
      };
  
      // Fetch unit options based on selected module
      const fetchCourseUnitOption = async (selectedOptionModule) => {
        try {
          const response = await get(`coursechapter?courseModule=${selectedOptionModule}`);
          // Assuming response is an array of unit objects with 'id' and 'title' properties
          const options = response.response.map(unit => ({
            value: unit.id,
            label: unit.title
          }));
          // Set unit options
          setOptionsUnitModule(options);
        } catch (error) {
          console.error("Error fetching Unit options:", error);
        }
      };
  
      // Handle change for Units dropdown
      const handleChangeUnit = (selectedOptionUnit) => {
        setSelectedUnit(selectedOptionUnit);
        fetchAssessments();
      };
  
      // Handle change for Assessments dropdown
      const handleChangeAssessment = (selectedOptionAssessment) => {
        setSelectedAssessment(selectedOptionAssessment);
        fetchAssessments();
      };
      const fetchAssessmentQuiz = async () => {
        try {
          get('assessmentquiz')
            .then(response => {
              var role = sessionStorage.getItem("roleslug");
              var organizationid = sessionStorage.getItem("organizationid");
              var qundata ='';
              // if (role === 'superadmin') {
              //   qundata = response.response;
              // } else if (role === 'organizationadmin') {
              //   qundata = response.response.filter(admin => admin.organization.id === organizationid);
              // }
              if(role === 'superadmin'){
                qundata = response.response.filter(qun => qun.role.slug === 'superadmin'); 
              } else if (role === 'organizationadmin'){
                qundata = response.response.filter(qun => qun.organization && qun.organization === organizationid);
                // qundata = response.response;
              } else {
                qundata = response.response;
              }
              debugger;
              setAssessments(qundata);
              const options = qundata.map(ass => ({
                  value: ass.id,
                  label: ass.title
                }));
                // Set course options
                setOptionsAssessment(options);
              
            })
            .catch(error => console.error('Error fetching data:', error));            
        } catch (error) {
          console.error('Error fetching Assessments:', error);
        }
      };

      const fetchAssessments = useCallback(async () => {
        try {
            const organizationid = sessionStorage.getItem("organizationid");
            const role = sessionStorage.getItem("roleslug");
            const queryParams = [];

            if (selectedCourse) {
                queryParams.push(`course=${selectedCourse}`);
            }
            if (selectedModule) {
                queryParams.push(`coursemodule=${selectedModule}`);
            }
            if (selectedUnit) {
                queryParams.push(`coursechapter=${selectedUnit}`);
            }
            if (selectedAssessment) {
                queryParams.push(`assessmentQuiz=${selectedAssessment}`);
            }

            // Construct the query string
            const queryString = queryParams.join('&');
            
            // Fetch assessments with applied filters
            const response = await get(`assessment?${queryString}`);
            
            // Filter the assessments based on role and organization
            let filteredAssessments = response.response;
            if (role === 'superadmin') {
                filteredAssessments = filteredAssessments.filter(qun => qun.role.slug === 'superadmin');
            } else if (role === 'organizationadmin') {
                filteredAssessments = filteredAssessments.filter(qun => qun.role.slug !== 'superadmin' && qun.organization && qun.organization.id === organizationid);
            } else {
              filteredAssessments = filteredAssessments.filter(qun => qun.role.slug !== 'superadmin' && qun.organization && qun.organization.id === organizationid);
            }
            
            // Update state with filtered assessments
            setAssessments(filteredAssessments);
            setFilteredAssessments(filteredAssessments);
        } catch (error) {
            console.error('Error fetching Assessments:', error);
        }
    }, [selectedCourse, selectedModule, selectedUnit, selectedAssessment]);
    

  // useEffect hook for initial fetch
  useEffect(() => {
    const fetchData = async () => {
        try {
            await fetchAssessmentQuiz();
            await fetchCourseOptions();
            await fetchAssessments();
        } catch (error) {
            console.error('Error fetching initial data:', error);
        }
    };
    fetchData();
}, [fetchAssessments]);

// useEffect hook for fetching assessments whenever filters change
useEffect(() => {
    fetchAssessments();
}, [selectedCourse, selectedModule, selectedUnit, selectedAssessment, fetchAssessments]);

    // Filter assessments based on search query
    const handleSearch = () => {
        filterAssessments(searchQuery);
      };
    const handleChange = (e) => {
      setSearchQuery(e.target.value);
      filterAssessments(e.target.value);
    };
  
    const filterAssessments = (query) => {
        const filteredQuizs = assessments.filter(quiz => 
            (quiz.question && quiz.question.toLowerCase().includes(query.toLowerCase())) ||
            (quiz.course && quiz.course.course_title && quiz.course.course_title.toLowerCase().includes(query.toLowerCase()))
        );
        setFilteredAssessments(filteredQuizs);
        setCurrentPage(1);
    };
    
    const indexOfLastquiz = currentPage * assessmentsPerPage;
    const indexOfFirstquiz = indexOfLastquiz - assessmentsPerPage;
    const currentquizs = filteredAssessments.slice(indexOfFirstquiz, indexOfLastquiz);
    async function handleDelete(Id) {
      try {
        await del(`assessment/${Id}`);
        fetchAssessments();
      } catch (error) {
        console.error('Error deleting Assessments:', error);
      }
    }
    const getPageNumbers = () => {
      const totalPages = Math.ceil(filteredAssessments.length / assessmentsPerPage);
      const pageNumbers = [];
      const maxVisiblePages = 5;
  
      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
  
        if (startPage > 1) pageNumbers.push(1, '...');
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        if (endPage < totalPages) pageNumbers.push('...', totalPages);
      }
      return pageNumbers;
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleNext = () => {
      if (currentPage < Math.ceil(filteredAssessments.length / assessmentsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };
  return (
    <DashboardContent pageTitle="Question Bank">
  <div>
        <Row className='text-right my-5'>
        <Col md={4} className="form-group">
        <Form.Control 
            type="text" 
            name="name" 
            value={searchQuery} 
            onChange={handleChange} 
            placeholder="Search ..." 
            className="form-control" 
        />
    </Col>
          <Col md={{span:3, offset:5}}>
          <Button type="button" onClick={()=>window.location='/createquestion'} className="fw-btn-fill btn-gradient-yellow">Create Question/Quiz</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
      <Row className="mb-3">
   
</Row>

        <Row className='mb-3 form-group'>
          <span>Sort By:</span>
          <Col md={3}>
            <SelectField options={optionsCourses} value={selectedCourse} placeholder="Select Course" onChange={(selectedCourse) => handleChangeCourses(selectedCourse)}>
              {/* Course options */}
            </SelectField>
          </Col>
          <Col md={3}>
            <SelectField options={optionsModuleCourse} value={selectedModule} placeholder="Select Module" onChange={(selectedModule) => handleChangeModule(selectedModule)}>
              {/* Module options */}
            </SelectField>
          </Col>
          <Col md={3}>
            <SelectField options={optionsUnitModule} value={selectedUnit} placeholder="Select Unit" onChange={(selectedUnit) => handleChangeUnit(selectedUnit)}>
              {/* Unit options */}
            </SelectField>
          </Col>
          <Col md={3}>
            <SelectField options={optionsAssessment} value={selectedAssessment} placeholder="Select Assessment" onChange={(selectedAssessment) => handleChangeAssessment(selectedAssessment)}>
              {/* Assessment options */}
            </SelectField>
          </Col>
        </Row>
        <Row>
        {currentquizs.map((quiz, index) => (
          
            <Col key={index} md={6} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>{quiz.assessmentQuiz && quiz.assessmentQuiz.title} - {quiz.type}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Course - {quiz.course && quiz.course.course_title}</Card.Subtitle>
                  <Card.Text dangerouslySetInnerHTML={{ __html: quiz.question }}></Card.Text>

                  {quiz.type === 'multipleChoice' ? (
                        <Button onClick={() => window.location = '/editquestion?id=' + quiz.id} className="mr-2 secondary-btn">Edit</Button>
                    )
                     : quiz.type === 'chooseTheCorrectAnswer' ? (
                      <Button onClick={() => window.location = '/editchooseTheCorrectAnswer?id=' + quiz.id} className="mr-2 secondary-btn">Edit</Button>

                    )
                     : quiz.type === 'fillInTheBlanks' ? (
                        <Button onClick={() => window.location = '/editfillintheblank?id=' + quiz.id} className="mr-2 secondary-btn">Edit</Button>
                    )
                    //  : quiz.type === 'essay' ? (
                    //   <i onClick={() => window.location = '/editessay?id=' + quiz.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i>
                    // ) 
                    // : quiz.type === 'shortAnswer' ? (
                    //   <i onClick={() => window.location = '/editshortanswer?id=' + quiz.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i>
                    // )
                     : (
                        <Button onClick={() => window.location = '/editquestion?id=' + quiz.id} className="mr-2 secndary-btn">Edit</Button>
                    )}
                  
                  <Button onClick={() => handleDelete(quiz.id)} className='ms-4 py-3' variant="danger">Delete</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
          </Row>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredAssessments.length / assessmentsPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredAssessments.length / assessmentsPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default Questions;
