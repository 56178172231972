import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from 'react-bootstrap';
import SelectField from "./SelectField";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { get, post } from "./rest";
// import AffordabilityWidget from "./AffordabilityWidget";

function SubscriptionPayment() {
    const [formData, setFormData] = useState({

    });
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [optionsOrganization, setOptionsOrganization] = useState([]);
    const [optionsSubscriptions, setOptionsSubscriptions] = useState([]);

    useEffect(() => {
        fetchOrganizationOptions();
        fetchSubscriptionOptions();
    }, []);

    const fetchOrganizationOptions = async () => {
        try {
            const response = await get('organization');
            const options = response.response.map(org => ({
                value: org.id,
                label: org.adminOrganization
            }));
            setOptionsOrganization(options);
        } catch (error) {
            console.error('Error fetching organization options:', error);
        }
    };

    const fetchSubscriptionOptions = async () => {
        try {
            const response = await get('subscription');
            const options = response.response.map(sub => ({
                value: sub.id,
                label: sub.subscriptiontype + '('+sub.price+')'
            }));
            setOptionsSubscriptions(options);
        } catch (error) {
            console.error('Error fetching subscription options:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const roleid = sessionStorage.getItem("roleid");
            const roleslug = sessionStorage.getItem("roleslug");
            let SubscriptionCluster = '';
            if (roleslug === 'superadmin') {
                SubscriptionCluster = '65f1a32e6cbe0baacc4a0c5e';
            } else {
                SubscriptionCluster = '65f1a3266cbe0baacc4a0c26';
            }
            debugger;
            const responseData = await post('/transactions', {
                ...formData,
                role: roleid,
                subscriptionCluster: SubscriptionCluster
            });
            debugger;
            if(responseData.statusCode === 201){
                toast.success("Subscription payment sent successful", {
                  onClose: () => {
                    window.location.reload();
                  },
                });
              }
            // Handle response data as needed
            console.log('Response Data:', responseData);
        } catch (error) {
            console.error('Error submitting subscription payment:', error);
            toast.error('Error submitting subscription payment');
        }
    };
    

    const handleChangeOrganization = (selectedOrganization) => {
        setSelectedOrganization(selectedOrganization);
        setFormData(prevState => ({
            ...prevState,
            organization:selectedOrganization
        }));
    };

    const handleChangeSubscription = (selectedSubscription) => {
        setSelectedSubscription(selectedSubscription);
        setFormData(prevState => ({
            ...prevState,
            subscriptionType:selectedSubscription
        }));
    };

    return (
        <DashboardContent pageTitle="Subscription Payment ">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Subscription Payment</h3>
                                </div>
                            </div>
                            <div className="student-info">
                                <Form onSubmit={handleSubmit} id="subscriptionPayment" className="mt-5">
                                    <Row>
                                        <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                            <Form.Label>Organization:</Form.Label>
                                            <SelectField options={optionsOrganization} placeholder="Select Organization" onChange={handleChangeOrganization} />
                                        </Col>
                                        <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                            <Form.Label>Subscriptions:</Form.Label>
                                            <SelectField options={optionsSubscriptions} placeholder="Select Subscription" onChange={handleChangeSubscription} />
                                        </Col>
                                        <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                            {/* <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Subscription Payment</Button> */}
                                            {/* <AffordabilityWidget key='OADt8R' amount='6000'/> */}

                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </DashboardContent>
    );
}

export default SubscriptionPayment;
