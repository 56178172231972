import { useEffect, useState } from "react";
import "./Admin.css";
import { Form, Button, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { get, post } from "./rest";

function SignUp() {
    const [formData, setFormData] = useState({
    });
    // const [optionsRoles, setOptionsRoles] = useState([]);
    const [selectedOptionRoles, setSelectedOptionRoles] = useState(null);
    const [phoneError, setPhoneError] = useState('');
    const [emailExists, setEmailExists] = useState(false);
    var role = sessionStorage.getItem("roleslug");
    // var organizationid = sessionStorage.getItem("organizationid");
    var organizationid = "65eed4d76cbe0baacc362ce0";
    useEffect(() => {
      fetchRolesOptions();
      
        setFormData(prevState => ({
          ...prevState,
          organization: organizationid 
        }));     
      
  }, [organizationid, role]);
  const fetchRolesOptions = async () => {
    try {
        const response = await get('roles');
        const roleStudent = response.response.find(role => role.slug === 'student');
        if (roleStudent) {
            setSelectedOptionRoles(roleStudent.id);
            setFormData(prevState => ({
                      ...prevState,
                      role: roleStudent.id 
                  }));
        } else {
            console.error('Role with slug "Student" not found.');
        }
        // const options = response.response.map(role => ({
        //     value: role.id,
        //     label: role.role
        // }));
        // setOptionsRoles(options);
    } catch (error) {
        console.error('Error fetching roles options:', error);
    }
  };
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
  
    if (name === 'phoneNumber') {
      // Remove any non-numeric characters
      newValue = value.replace(/\D/g, '');
    
      // Check if the original value contains any non-numeric characters
      if (value !== newValue) {
        setPhoneError('Phone number should contain only numbers.');
      } else if (newValue.length !== 10) {
        // Validate if the phone number has exactly 10 digits
        setPhoneError('Phone number must be 10 digits long.');
      } else {
        setPhoneError('');
      }
    
      // Restrict to 10 digits (this ensures newValue is always 10 digits)
      newValue = newValue.slice(0, 10);
    }
  
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };
  const handleEmailChange = async (e) => {
    const { name, value } = e.target;
    try {
      // Make an API call to check if the email already exists
      const checkEmailResponse = await post(`/auth/check-email-exists`, { email: value });
      debugger;
      setEmailExists(checkEmailResponse.response);
      // If the email already exists in the database
      if (checkEmailResponse.response === true) {
        toast.error('Email already exists!');
      } else {
        // If the email is unique, update the form data
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      }
    } catch (error) {
      console.error('Error checking email:', error);
    }
  };
  //   const handleChangeRoles = (selectedOptionRoles) => {
  //     setSelectedOptionRoles(selectedOptionRoles);
  //     setFormData(prevState => ({
  //         ...prevState,
  //         role: selectedOptionRoles 
  //     }));
  // };
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const responseData = await post('users', formData);
  
        debugger;
        if(responseData.statusCode === 201){
          toast.success("Created Successfully", {
            onClose: () => {
              window.location.href='/Students';
            },
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };
  return (
    <>
      <div className="login-page-wrap">
        <div className="login-page-content py-5" style={{height:"100%"}}>
          <div className="login-box">
            <div className="item-logo mb-0">
              <p className="menulogoonlog">
                Register
              </p>
            </div>
            <Form onSubmit={handleSubmit} id="createemp">
              <Row>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>Student Username:</Form.Label>
                    <Form.Control type="text" id="username" name="username" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control type="password" id="password" name="password" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>First name:</Form.Label>
                    <Form.Control type="text" id="firstname" name="firstname" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>Last name:</Form.Label>
                    <Form.Control type="text" id="lastname" name="lastname" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="email" id="email" name="email" className="form-control" onChange={handleEmailChange} required />
                  </Col>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Form.Label>Phone:</Form.Label>
                        <Form.Control type="tel" id="phoneNumber" name="phoneNumber" className={`form-control ${phoneError ? 'is-invalid' : ''}`} onChange={handleChange} required />
                        {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                    </Col>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>City:</Form.Label>
                    <Form.Control type="text" id="city" name="city" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>State:</Form.Label>
                    <Form.Control type="text" id="state" name="state" className="form-control" onChange={handleChange} required />
                  </Col>
                  {/* <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                  </Col> */}
                  {/* {showOrganizationField && (
                      <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Form.Label>Organization:</Form.Label>
                        <SelectField options={optionsOrganization} placeholder="Select Organization"  onChange={handleChangeOrganization} />
                      </Col>
                    )}   */}
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    
                  </Col>
                  <Button type="submit" className="mt-5 login-btn" disabled={emailExists}>Create Student</Button>
              <Form.Group className="form-group text-center mt-4">
                <a href="/login" className="forgot-btn ">
                  Already have an account? Login
                </a>                
              </Form.Group>
                </Row>
              </Form>
          
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default SignUp;
