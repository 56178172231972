import { Button, Card, Col, Form, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import SelectField from "./SelectField";
import { useEffect, useState } from "react";
import { get, post, put } from "./rest";
import { toast, ToastContainer } from "react-toastify";
import SubscriptionSelectField from "./SubscriptionSelectField";
import moment from "moment";

function UpgradeSubscription() {
    const [formData, setFormData] = useState({
        subscription: null,
    });
    const [organizationPlan, setOrganizationPlan] = useState([]);
    const [startDate, setStartDate] = useState([]);
    const [endDate, setEndDate] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [optionsSubscriptions, setOptionsSubscriptions] = useState([]);
    const [selectedOptionTypes, setSelectedOptionTypes] = useState(null);
    useEffect(() => {
        fetchSubscriptionOptions();
        fetchOrganization();
        fetchOrganizationOptions();
    }, []);
    const fetchOrganization = async () => {
        try {
            const id = sessionStorage.getItem("organizationid");
          const response = await get("organization/" + id );
          
          debugger;
          setFormData(response.response); 
          console.log(formData);
        } catch (error) {
          console.error("Error fetching roles options:", error);
        }
      };
      const fetchSubscriptionOptions = async () => {
        try {
          const response = await get("subscriptiontype");
          const options = response.response.map((sub) => ({
            value: sub.id,
            label: sub.subscriptiontype + "(" + sub.price + ")",
          }));
          setOptionsSubscriptions(options);
        } catch (error) {
          console.error("Error fetching subscription options:", error);
        }
      };
    const handleChangeSubscription = (selectedSubscription) => {
        const { value, label } = selectedSubscription;
        const subscriptionTypeName = label.replace(/\([^)]+\)/, '').trim();
        const subscriptionType = value;
        const price = label.match(/\(([^)]+)\)/)[1];
    
        setSelectedSubscription(selectedSubscription);
        setFormData((prevState) => ({
          ...prevState,
          subscriptionTypeName: subscriptionTypeName,
          subscriptionType: subscriptionType,
          price: price,
          isActive: true
        }));
      };
      const fetchOrganizationOptions = async () => {
        try {
          debugger;
            const response = await get('organization');
            debugger;
            const id = sessionStorage.getItem("organizationid");
            const data = response.response.filter(org => org.id === id);
            const plan = data.map(plan => plan.subscriptionType ? plan.subscriptionType.subscriptiontype :'');
            const startDate = data.map(plan => plan.subscription ? plan.subscription.createdAt :null);
            const validStartDates = startDate.filter(date => date !== null);
            const endDate = validStartDates.map(date => {
                if (date) {
                    const calculatedEndDate = new Date(date);
                    calculatedEndDate.setFullYear(calculatedEndDate.getFullYear() + 1); // Add 1 year (365 days)
                    return calculatedEndDate;
                }
                return null;
            });
            setStartDate(startDate);
            debugger
            setEndDate(endDate);
            setOrganizationPlan(plan[0]);
        } catch (error) {
            console.error('Error fetching organization options:', error);
        }
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const id = sessionStorage.getItem("organizationid");
        try {
            const updatedFormData = {
                ...formData,
                updatedBy : sessionStorage.getItem("authorid")
              };
          const responseData = await put("organization/" + id, updatedFormData);
          debugger;
          if (responseData.statusCode === 200) {
            toast.success("Updated Successfully", {
              onClose: () => {
                window.location.href = "/dashboard";
              },
            });
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      };
      const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
      const handleRazorpayPayment = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    
            if (!res) {
                alert('Razorpay SDK failed to load. Are you online?');
                return;
            }
    
        const options = {
          key: "rzp_test_VLnzWA84IIIUs4",
          amount: formData.price * 100, // Razorpay expects amount in smallest currency unit (paise for INR)
          currency: "INR",
          name: formData.adminOrganization,
          description: "Subscription Payment",
          handler: async function (response) {
            toast.success(`Payment successful! Razorpay Payment ID: ${response.razorpay_payment_id}`);
    
            const transactionData = {
              transactionId: response.razorpay_payment_id,
              amount: formData.price,
              user: formData.adminOrganization,
              createdBy: sessionStorage.getItem("authorid")
            };
    
            try {
              const transactionRes = await post("/subscription", transactionData);
              console.log(transactionRes);
              if (transactionRes.statusCode === 201) {
                // toast.success("Transaction recorded successfully.");
                // setFormData(prevState => ({
                //     ...prevState,
                //     subscription: transactionRes.response.newSubscription.id 
                // }));
                const updatedData = {
                    ...formData,
                    subscription: transactionRes.response.newSubscription.id,
                    updatedBy : sessionStorage.getItem("authorid")
                  };
                try {
                    const id = sessionStorage.getItem("organizationid");
                    debugger
                  const enquiryRes = await put("/organization/" + id, updatedData);
                  if (enquiryRes.statusCode === 200) {                    
                    toast.success("Thank you for Upgrading",{
                      onClose: () => {
                        window.location.href = '/dashboard';
                      }
                    });
                  }
                } catch (error) {
                  console.error("Error submitting enquiry data:", error);
                  toast.error("Error submitting enquiry data.");
                }
              }
            } catch (error) {
              console.error("Error recording transaction:", error);
              toast.error("There was an error processing your request. Please contact support.");
            }
          },
          prefill: {
            name: formData.firstname,
            email: formData.email,
            contact: formData.phoneNumber,
          },
          notes: {
            address: formData.adminaddress,
          },
          theme: {
            color: "#3399cc",
          },
        };
    
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      };
    return ( 
        <DashboardContent pageTitle="Upgrade/Renewal">
           <Card className="p-5">
                  <p className="m-0 me-5"> Plan: {organizationPlan}</p>
                  <p className="m-0 me-5 mb-4">Validity: {startDate.map(date => moment(date).format('DD/MM/YYYY')).join(', ')} - {endDate.map(date => moment(date).format('DD/MM/YYYY')).join(', ')}</p>
                  
                
                        <Form onSubmit={handleSubmit} id="createSubscriptionType" className="mt-5">
                            <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                <SubscriptionSelectField
                                    options={optionsSubscriptions}
                                    onChange={handleChangeSubscription}
                                    placeholder="Select Subscription Type"
                                    selected={selectedSubscription}
                                />
                                </Col>
                                </Row>
                                <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    {/* <Button type="submit" className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Upgrade/Renewal</Button> */}
                                    <Button
                    type="button"
                    onClick={handleRazorpayPayment}
                    className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                  >
                    Upgrade/Renewal
                  </Button>
                                </Col>

                                </Row>
                            </Form>
                            </Card>
                            <ToastContainer />
        </DashboardContent>
     );
}

export default UpgradeSubscription;