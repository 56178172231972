import React, { useEffect } from 'react';
import Select from 'react-select';

function MultiSelectField({ options, onChange }) {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.Select !== 'undefined') {
      window.Select.createPortal = () => {}; // Prevents console warning about createPortal
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.Select !== 'undefined') {
      window.Select.createPortal = () => {}; // Prevents console warning about createPortal
    }
  }, []);

  return (
    // this component for select box field in react 
    // need to install - npm install react-select
    <Select
      options={options}
      onChange={onChange}
      isMulti={true} // Enable multi-select
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} // Fix z-index issue
    />
  );
}

export default MultiSelectField;
