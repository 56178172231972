import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { get, post } from "./rest";
import DashboardContent from "./DashboardContent";
import { useLocation } from "react-router-dom";

function Test() {
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [id, setId] = useState(null);
  const location = useLocation();
//   const [selectedOptionRoles, setSelectedOptionRoles] = useState(null);
//   const [assessments, setAssessments] = useState([]);
//   const [optionsCourses, setOptionsCourse] = useState([]);
//   const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchQuestions(id);
  }, [location.search]);


  const fetchQuestions = async (id) => {
    try {
        const response = await get("assessment/" + id); // Define response only once
        const correctAnswer = response.response.correctanswer;
        const sanitizedResponse = removeHtmlTagsFromResponse(response.response);

        // setSelectedOption(correctAnswer); // Set the correct answer from API to selectedOption
        setFormData(sanitizedResponse);
      } catch (error) {
        console.error("Error fetching Questions:", error);
      }
      await post('globalevents', {
        name: `Assessment Started`,
        type: "assessmentevent",
        createdAt: new Date(),
        organization: sessionStorage.getItem("organizationid"),
        user: sessionStorage.getItem("authorid"),
        index: id
    });
  };



  const handleAnswerChange = (questionId, content) => {
    setFormData(prevState => ({
      ...prevState,
      [questionId]: content,
    }));
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Assuming your API endpoint for submitting answers is "submit-answers"
//       const responseData = await post("submit-answers", formData);
//       if (responseData.statusCode === 200) {
//         toast.success("Answers submitted successfully");
//       }
//     } catch (error) {
//       console.error("Error submitting answers:", error);
//     }
//   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAnswer) {
      toast.error("Please select an answer");
      return;
    }
    try {
      const formData = { selectedAnswer };
      // Assuming your API endpoint for submitting assessment reports is "/assessmentreports"
      const responseData = await post("/assessmentreports", formData);
      if (responseData.statusCode === 200) {
        toast.success("Answer submitted successfully");
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
      toast.error("Failed to submit answer");
    }
  };

//   if (!formData) {
//     return <div>Loading...</div>;
//   }

  const { question, answer1, answer2, answer3, answer4 } = formData;

  return (
    <DashboardContent pageTitle="Quiz">

      <h2>Quiz</h2>
      <form onSubmit={handleSubmit}>
        <h4 dangerouslySetInnerHTML={{ __html: formData.question }}></h4>
      <Form.Group>
          {/* Render the answers dynamically */}
          {["answer1", "answer2", "answer3", "answer4"].map((answer, index) => (
            <Form.Check
              key={index}
              type="radio"
              label={formData[answer]}
              name="answer"
              value={formData[answer]}
              checked={selectedAnswer === formData[answer]}
              onChange={() => setSelectedAnswer(formData[answer])}
            />
          ))}
        </Form.Group>
        <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit Answers</Button>
      </form>
      <ToastContainer />
    </DashboardContent>
  );

}
export default Test;


  // Function to remove HTML tags from all fields in the response
  const removeHtmlTagsFromResponse = (response) => {
    const sanitizedResponse = {};
    for (const key in response) {
      if (response.hasOwnProperty(key)) {
        if (typeof response[key] === "string") {
          sanitizedResponse[key] = stripHtmlTags(response[key]);
        } else {
          sanitizedResponse[key] = response[key];
        }
      }
    }
    return sanitizedResponse;
  };


// Function to strip HTML tags from a string
const stripHtmlTags = (htmlString) => {
  return htmlString.replace(/(<([^>]+)>)/gi, "");
};