import React, { useState } from 'react';
import DashboardContent from './DashboardContent';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { post } from './rest';
import { ToastContainer, toast } from 'react-toastify';

function CreateSubscriptionType() {
    const [formData, setFormData] = useState({

    });
    const [response, setResponse] = useState(null);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const responseData = await post('subscription', formData);
        setResponse(responseData);
        // toast.success(responseData.response);
        debugger;
        if(responseData.statusCode === 201){
          toast.success("Created Successfully", {
            onClose: () => {
              window.location.href='/subscriptions';
            },
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };
  

    return ( 
        <DashboardContent pageTitle="Create Subscription Type">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Create Subscription Type</h3>
                                </div>
                            </div>
                            <div className="student-info">
                            <Form onSubmit={handleSubmit} id="createSubscriptionType" className="mt-5">
                            <Row>
                                <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                    <Form.Label htmlFor="subscriptiontype">Subscription Type:</Form.Label>
                                    <Form.Control type="text" id="subscriptiontype" name="subscriptiontype" className="form-control" onChange={handleChange} required />
                                </Col>
                                <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                    <Form.Label htmlFor="price">Price:</Form.Label>
                                    <Form.Control type="text" id="price" name="price" className="form-control" onChange={handleChange} required />
                                </Col>
                                {/* <Col md={6} xl={6} lg={6} xs={12} className="form-group"> */}
                                    {/* <Form.Label >Image:</Form.Label>
                                    <div className="wrapper">
                                    <div className="box">
                                        <div className="js--image-preview"></div>
                                        <div className="upload-options">
                                        <Form.Label>
                                            <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" required />
                                        </Form.Label>
                                        </div>
                                    </div>
                                    </div> */}
                                {/* </Col> */}
                                <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                    <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Create Subscription Type</Button>
                                </Col>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        <ToastContainer />
    </DashboardContent>
    );
}

export default CreateSubscriptionType;