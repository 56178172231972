import { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { get } from "./rest";
import { Button, Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";

function MyCourses() {
    const [courses, setCourses] = useState([]);
    const [freeCourses, setFreeCourses] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);
    const [remainingCourses, setRemainingCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchGlobalEvents();
        fetchFreeCourses();
    }, []);

    const fetchGlobalEvents = async () => {
        try {
            const response = await get('globalevents');
            const completedCourseIds = response.response
                .filter(event => event.type === 'coursecompleted')
                .map(event => event.course);

            fetchCourses(completedCourseIds);
        } catch (error) {
            console.error('Error fetching global events:', error);
            setIsLoading(false);
        }
    };

    const fetchCourses = async (completedCourseIds) => {
        try {
            const response = await get('usercourseenrollment');
            const role = sessionStorage.getItem("roleslug");
            const organizationid = sessionStorage.getItem("organizationid");
            const authorid = sessionStorage.getItem("authorid");

            const allCourses = response.response.filter(admin =>
                admin.organization.id === organizationid && admin.user.id === authorid
            );

            const completed = allCourses.filter(course => completedCourseIds.includes(course.course.id));
            const remaining = allCourses.filter(course => !completedCourseIds.includes(course.course.id));

            setCompletedCourses(completed);
            setRemainingCourses(remaining);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setIsLoading(false);
        }
    };

    const fetchFreeCourses = async () => {
        try {
            const response = await get('courses');
            const organizationid = sessionStorage.getItem("organizationid");

            const coursedata = response.response.filter(course =>
                course.organization && course.organization === organizationid && course.price === '0'
            );

            setFreeCourses(coursedata);
        } catch (error) {
            console.error('Error fetching free Topics:', error);
        }
    };

    return (
        <DashboardContent pageTitle="My Topics">
            {isLoading ? (
                <p>Loading Topics...</p>
            ) : (
                <>
                    <h3>Completed Topics</h3>
                    {completedCourses.length > 0 ? (
                        <Row className="courseslist">
                            {completedCourses.map((course, index) => (
                                <Col md={4} className="my-5" key={index}>
                                    <Card className="flex-fill">
                                        <CardHeader className="p-0">
                                            {course.course.price > 0 && (
                                                <i className="fa-brands fa-square-web-awesome-stroke premimum-icon"></i>
                                            )}
                                            <img src={course.course.thumbnail} alt={course.course.course_title} className="img-fluid" height="150px" />
                                        </CardHeader>
                                        <CardBody>
                                            <h3>{course.course.course_title}</h3>
                                            <Button className="me-2 secondary-btn" onClick={() => { window.location.href = "/lms?id=" + course.course.id }}>LMS</Button>
                                            <Button className="secondary-btn" onClick={() => { window.location.href = "/quiz?id=" + course.course.id }}>Quiz</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p className="text-center">No completed Topics.</p>
                    )}

                    <h3 className="mt-5">Remaining Topics</h3>
                    {remainingCourses.length > 0 ? (
                        <Row className="courseslist">
                            {remainingCourses.map((course, index) => (
                                <Col md={4} className="my-5" key={index}>
                                    <Card className="flex-fill">
                                        <CardHeader className="p-0">
                                            {course.course.price > 0 && (
                                                <i className="fa-brands fa-square-web-awesome-stroke premimum-icon"></i>
                                            )}
                                            <img src={course.course.thumbnail} alt={course.course.course_title} className="img-fluid" height="150px" />
                                        </CardHeader>
                                        <CardBody>
                                            <h3>{course.course.course_title}</h3>
                                            <Button className="me-2 secondary-btn" onClick={() => { window.location.href = "/lms?id=" + course.course.id }}>LMS</Button>
                                            <Button className="secondary-btn" onClick={() => { window.location.href = "/quiz?id=" + course.course.id }}>Quiz</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p className="text-center">No remaining courses.</p>
                    )}

                    <h3 className="mt-5">Free Topics</h3>
                    {freeCourses.length > 0 ? (
                        <Row className="courseslist">
                            {freeCourses.map((course, index) => (
                                <Col md={4} className="my-5" key={index}>
                                    <Card className="flex-fill">
                                        <CardHeader className="p-0">
                                            {course.price > 0 && (
                                                <i className="fa-brands fa-square-web-awesome-stroke premimum-icon"></i>
                                            )}
                                            <img src={course.thumbnail} alt={course.course_title} className="img-fluid" height="150px" />
                                        </CardHeader>
                                        <CardBody>
                                            <h3>{course.course_title}</h3>
                                            <Button className="me-2 secondary-btn" onClick={() => { window.location.href = "/lms?id=" + course.id }}>LMS</Button>
                                            <Button className="secondary-btn" onClick={() => { window.location.href = "/quiz?id=" + course.id }}>Quiz</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p className="text-center">No free Topics.</p>
                    )}
                </>
            )}
        </DashboardContent>
    );
}

export default MyCourses;
