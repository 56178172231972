import CourseDetail from "./CourseDetail";
import CourseDetailedView from "./CourseDetailedView";
import DashboardContent from "./DashboardContent";

function LMS() {
    return ( 
        <DashboardContent pageTitle={<><a href="/mycourses">Topics</a> - View </>}>
            {/* <CourseDetail /> */}
            <CourseDetailedView />
        </DashboardContent>
     );
}

export default LMS;