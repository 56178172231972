import React, { useState } from 'react';
import './VerticalAccordion.css'; // Import your custom CSS for styling
import { get } from './rest';

const VerticalAccordion = ({ items, onModuleSelect, onChapterSelect }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [chapters, setChapters] = useState([]);

  const handleItemClick = (index, moduleId) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedModule(moduleId);
    onModuleSelect(moduleId);
    fetchChapters(moduleId);
    onChapterSelect(null);
  };

  const fetchChapters = async (moduleId) => {
    try {
        const response = await get(`coursechapter?courseModule=${moduleId}`);
        setChapters(response.response);
    } catch (error) {
        console.error("Error fetching Chapters:", error);
    }
  };


  const handleChapterClick = (chapterId) => {
    onChapterSelect(chapterId);
  };

  return (
    <div className="vertical-accordion-container">
      <div className="vertical-accordion">
        {items.map((item, index) => (
          <div key={index} className="accordion-item">
            <div
              className={`accordion-header ${index === activeIndex ? 'active' : ''}`}
              onClick={() => handleItemClick(index, item.title)}
            >
              <span><small><i class="fa-solid fa-code-commit"></i></small> {item.content}</span>
            </div>
            {index === activeIndex && chapters.length > 0 && (
              <div className="accordion-content">
                {chapters.map((chapter, chapterIndex) => (
                  <div
                    key={chapterIndex}
                    className="chapter-item"
                    onClick={() => handleChapterClick(chapter.id)}
                  >
                    <span><small><i class="fa-solid fa-code-commit"></i></small> {chapter.title}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalAccordion;
