import React from "react";
import DashboardSuperAdmin from "./DashboardSuperAdmin";
import DashboardOrganizationAdmin from "./DashboardOrganizationAdmin";
import DashBoardStudent from "./DashBoardStudent";
import DashboardOtherUser from "./DashboardOtherUser";

function Dashboard() {
  var role = sessionStorage.getItem("roleslug");
  var dashboardComponent = null;

  if (role === 'superadmin') {
    dashboardComponent = <DashboardSuperAdmin />;
  } else if (role === 'organizationadmin') {
    dashboardComponent = <DashboardOrganizationAdmin />;
  } else if (role === 'student') {
    dashboardComponent = <DashBoardStudent />;
  } else  {
    dashboardComponent = <DashboardOtherUser />;
  }

  return (
    <>
      {dashboardComponent}
    </>
  );
}

export default Dashboard;
