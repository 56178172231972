import { useEffect, useState } from "react";
import { get } from "./rest";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row, Tabs, Tab, Nav, Card } from "react-bootstrap";
import OrgHeader from "./OrgHeader";
import 'bootstrap/dist/css/bootstrap.min.css';
import './CourseDetail.css';
import VerticalAccordion from "./VerticalAccordion";
import DOMPurify from 'dompurify';
import VideoPlayer from "./VideoPlayer";
import AssessmentView from "./AssessmentView";
import DashboardContent from "./DashboardContent";

function CourseDetail() {
    const [id, setId] = useState(null);
    const location = useLocation();
    const [courseDetails, setCourseDetails] = useState([]);
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get("id");
        setId(id);
        fetchModules(id);
        fetchLMS(id);
    }, [location.search]);

    useEffect(() => {
        if (id) {
            if (selectedModule && selectedChapter) {
                fetchLMS(id, selectedModule, selectedChapter);
            } else if (selectedModule) {
                fetchLMS(id, selectedModule);
            } else {
                fetchLMS(id);
            }
        }
    }, [id, selectedModule, selectedChapter]);

    const fetchModules = async (id) => {
        try {
            const response = await get('coursemodule?course=' + id);
            const options = response.response.map((mod) => ({
                title: mod.id,
                content: mod.title,
            }));
            setModules(options);
        } catch (error) {
            console.error("Error fetching Modules:", error);
        }
    };

    const fetchLMS = async (id, moduleId = null, chapterId = null) => {
        try {
            const response = await get('lms');
            const organizationid = sessionStorage.getItem("organizationid");
            const matchingCourses = response.response.filter(
                item => item.course?.id === id &&
                    item.organization === organizationid &&
                    (!moduleId || item.coursemodule === moduleId) &&
                    (!chapterId || item.coursechapter === chapterId)
            );

            setCourseDetails(matchingCourses);
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    };

    return (
    < >        
      {/* <OrgHeader /> */}
            <Container fluid className="py-5" style={{ backgroundColor: "#fff" }}>
                <Row>
                    <Col md={3} className="px-0">
                        <VerticalAccordion
                            className="p-0"
                            items={modules}
                            onModuleSelect={setSelectedModule}
                            onChapterSelect={setSelectedChapter}
                        />
                    </Col>
                    <Col md={9} className="px-5">
                        {courseDetails.length > 0 ? (
                            <div className="my-5">
                                <h3>Course: {courseDetails[0].course.course_title}</h3>
                                <p><span>Category: {courseDetails[0].category.industry}</span></p>
                                <p>Lessons:</p>
                                <Tab.Container id="course-lessons-tabs" defaultActiveKey={courseDetails[0].lesson_title}>
                                    <Row>
                                        <Col sm={12}>
                                            <Nav variant="tabs" className="custom-tabs">
                                                {courseDetails.map((courseDetail, index) => (
                                                    <Nav.Item key={index}>
                                                        <Nav.Link eventKey={courseDetail.lesson_title}>{courseDetail.lesson_title}</Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                {courseDetails.map((courseDetail, index) => (
                                                    <Tab.Pane eventKey={courseDetail.lesson_title} key={index}>
                                                        <Row className="mt-3">
                                                            <Col md={3}>
                                                                <img src={courseDetail.thumbnail_URL} alt={courseDetail.lesson_title} className="img-fluid" />
                                                            </Col>
                                                            <Col>
                                                                <h4>{courseDetail.lesson_title}</h4>
                                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(courseDetail.lesson_description) }}></div>
                                                            </Col>
                                                            <Row>
                                                                {courseDetail.video_URL && (
                                                                    <div className="mt-5">
                                                                        <VideoPlayer videoUrl={courseDetail.video_URL} />
                                                                    </div>
                                                                )}
                                                                {courseDetail.liveclass && (
                                                                    <>
                                                                        <h5 className="mt-5">Live Class Link: </h5><Link>{courseDetail.liveclass}</Link>
                                                                    </>
                                                                )}
                                                                {courseDetail.pdf_URL && (
                                                                    <a href={courseDetail.pdf_URL} className="mt-5"><i className="fa-regular fa-file-pdf fa-4x"></i> PDF Document</a>
                                                                )}
                                                            </Row>
                                                        </Row>
                                                    </Tab.Pane>
                                                ))}
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        ) : (
                            <p>No Lessons Available. Please select another...</p>
                        )}
                     
                        {/* <AssessmentView
                    selectedCourse={id}
                    selectedModule={selectedModule}
                    selectedChapter={selectedChapter}
                /> */}
                    </Col>
                </Row>
                
            </Container>
        </>
    );
}

export default CourseDetail;
