import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { get, post } from "./rest";
import { ToastContainer, toast } from "react-toastify";
import SelectField from "./SelectField";
import SelectFieldQuiz from "./SelectFieldQuiz";
import CustomSelectField from "./CustomSelectField";
import UnitSelectField from "./UnitSelectField";
import QuillEditor from "./QuillEditor";

function FillInTheBlanks() {
  const [formData, setFormData] = useState({
    question: "",
    // answer: "",
    assessmentQuiz: ""
  });
  const [optionsCourses, setOptionsCourse] = useState([]);
  const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsModuleCourse, setOptionsModuleCourse] = useState([]); // State for module options
  const [selectedOptionModule, setSelectedOptionModule] = useState(null); // State for selected module
  const [optionsUnitModule, setOptionsUnitModule] = useState([]); 
  const [selectedOptionUnit, setSelectedOptionUnit] = useState(null); // State for selected unit
  const [optionsLessonUnit, setOptionsLessonUnit] = useState([]); // State for module options
  const [selectedOptionLesson, setSelectedOptionLesson] = useState(null); // State for selected unit
  const [selectedAssessment, setSelectedAssessment] = useState(null); // State for selected unit
  const [existingAssessments, setExistingAssessments] = useState([]); 
  const [showModal, setShowModal] = useState(false);
  const [newModuleFormData, setNewModuleFormData] = useState({
    title: '',
  });
  useEffect(() => {
    fetchCourseOptions();
  }, []);
  const fetchExistingAssessments = async (selectedOptionCourses) => {
    debugger;
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`assessmentquiz`);
      const assessments = response.response
        .filter((assessment) => assessment.organization === organizationid)
        .map((assessment) => ({
          value: assessment.id,
          label: assessment.title
        }));
      
      setExistingAssessments(assessments);
    } catch (error) {
      console.error('Error fetching existing assessments:', error);
    }
    
  };
  const fetchExistingSelectedAssessments = async (selectedQuiz) => {
    debugger;
    // toast.success("Quiz created successfully!");
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`assessmentquiz`);
      const assessments = response.response
        .filter((assessment) => assessment.organization === organizationid)
        .map((assessment) => ({
          value: assessment.id,
          label: assessment.title,
          isselected: assessment.id === selectedQuiz
        }));
      
      setExistingAssessments(assessments);
    } catch (error) {
      console.error('Error fetching existing assessments:', error);
    }
    
  };
  const fetchCourseOptions = async () => {
    const organizationid = sessionStorage.getItem("organizationid");

    try {
      const response = await get('courses');
      const options = response.response.filter(cours=> cours.organization && cours.organization === organizationid ).map(course => ({
        value: course.id,
        label: course.course_title
      }));
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  const handleChangeCourses = (selectedOptionCourses) => {
    setSelectedOptionCourses(selectedOptionCourses);
    setFormData(prevState => ({
      ...prevState,
      course: selectedOptionCourses
    }));
    fetchCourseModuleOption(selectedOptionCourses); 
    
    setOptionsUnitModule([]);
    fetchExistingAssessments(selectedOptionCourses);
  };
  const fetchCourseModuleOption = async (selectedOptionCourses) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursemodule?course=${selectedOptionCourses}`);
      const options = response.response
        .map((module) => {
          if (module.organization === organizationid) {
            return {
              value: module.id,
              label: module.title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
    } catch (error) {
      console.error("Error fetching Module options:", error);
    }
  };
  const fetchCourseModuleOptionfilter = async (courseId, filter) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursemodule?course=${courseId}`);
      const options = response.response
        .map((module) => {
          if (module.organization === organizationid) {
            return {
              value: module.id,
              label: module.title,
              isselected: module.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
      const selectedModule = options.find(module => module.label === filter || module.value === filter);
  
      if (selectedModule) {
        debugger
        setSelectedOptionModule(selectedModule.value); 
        setFormData(prevState => ({
          ...prevState,
          coursemodule: selectedModule.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Module options:', error);
    }
  };
  
  const fetchCourseUnitOptionfilter = async (moduleId, filter) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`coursechapter?courseModule=${moduleId}`);
      const options = response.response
        .map((unit) => {
          if (unit.organization === organizationid) {
            return {
              value: unit.id,
              label: unit.title,
              isselected: unit.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsUnitModule(options);
      
      const selectedUnit = options.find(Unit => Unit.label === filter || Unit.value === filter);
  
      if (selectedUnit) {
        setSelectedOptionUnit(selectedUnit.value); 
        setFormData(prevState => ({
          ...prevState,
          coursechapter: selectedUnit.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Unit options:', error);
    }
  };
  // Handle module change
  const handleChangeModule = (selectedModule) => {
    // const selectedModule = event.target.value;
debugger
    setSelectedOptionModule(selectedModule);
    
    setFormData((prevState) => ({
      ...prevState,
      coursemodule: selectedModule,
    }));
    
    fetchCourseUnitOption(selectedModule)
  };
  const fetchCourseUnitOption = async (selectedOptionModule) => {
    
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      
      const response = await get(`coursechapter?courseModule=${selectedOptionModule}`);
      const options = response.response
        .map((unit) => {
          if (unit.organization === organizationid) {
            // if (unit.organization === organizationid) {
            return {
              value: unit.id,
              label: unit.title,
            };
          }
          return null;
        })
        .filter(Boolean);
        
      setOptionsUnitModule(options);
    } catch (error) {
      console.error("Error fetching Unit options:", error);
    }
  };
  // Handle unit change
  const handleChangeUnit = (selectedUnit) => {
    // const selectedUnit = event.value;

    setSelectedOptionUnit(selectedUnit);
    setFormData((prevState) => ({
      ...prevState,
      coursechapter: selectedUnit,
    }));
    fetchCourseLessonOption(selectedUnit);
  };
  const fetchCourseLessonOption = async (selectedOptionUnit) => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`lms?coursechapter=${selectedOptionUnit}`);
      const options = response.response.filter(lms => lms.coursechapter && lms.coursechapter === selectedOptionUnit)
        .map((lesson) => {
          debugger;
          if (lesson.organization === organizationid) {
            return {
              value: lesson.id,
              label: lesson.lesson_title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsLessonUnit(options);
    } catch (error) {
      console.error("Error fetching Module options:", error);
    }
  };
  const handleChangeLesson = (selectedLesson) => {
    debugger;
    // const selectedLesson = event.target.value;

    setSelectedOptionLesson(selectedLesson);
    setFormData((prevState) => ({
      ...prevState,
      lms: selectedLesson,
    }));
  };
  const handleAssessmentChange = (selectedAssessment, event) => {
    if (event) {
        event.stopPropagation(); // Stop event propagation
    }
    setSelectedAssessment(selectedAssessment.value);
    setFormData((prevState) => ({
        ...prevState,
        assessmentQuiz: selectedAssessment.value,
    }));
  };

  const handleModuleSubmit = async (e, filter) => {
    e.preventDefault();
    const organizationid = sessionStorage.getItem("organizationid");
    // const courseId = selectedOptionCourses?.value;
    if (!selectedOptionCourses) {
      toast.error('Please select a course before creating a module');
      return;
    }
    
    try {
      
      const response = await post('coursemodule', { title: filter, organization: organizationid, course: selectedOptionCourses });
      if (response.statusCode === 201) {
        toast.success('New module added successfully', {
          onClose: () => {
            fetchCourseModuleOptionfilter(selectedOptionCourses, filter);
            setNewModuleFormData({
              title: '',
            });
          setSelectedOptionUnit(null);
handleChangeUnit(selectedOptionUnit);
          }
        });
      } else {
        toast.error('Failed to add new module');
      }
    } catch (error) {
      console.error('Error adding new module:', error);
      toast.error('Failed to add new module');
    }
  };
  const handleUnitSubmit = async (e, filter) => {
    e.preventDefault();
    const organizationid = sessionStorage.getItem("organizationid");
    // const moduleId = selectedOptionModule?.value;
    if (!selectedOptionModule) {
      toast.error('Please select a Module before creating a Unit');
      return;
    }
    
    try {
      
      const response = await post('/coursechapter', { title: filter, organization: organizationid,coursemodule: selectedOptionModule  });
      if (response.statusCode === 201) {
        toast.success('New Unit added successfully',{
          onClose: () => {
            fetchCourseUnitOptionfilter(selectedOptionModule, filter);
          }
        });
 
        
      } else {
        toast.error('Failed to add new Unit');
      }
    } catch (error) {
      console.error('Error adding new Unit:', error);
      toast.error('Failed to add new Unit');
    }
  };
  const handleQuizSubmit = (selectedQuiz) => {
    
    fetchExistingSelectedAssessments(selectedQuiz);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleEditorChange = (name, content) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: content,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAssessment) {
      toast.error('Please Select a Quiz before submitting.');
      return;
    }
    try {
      const roleid = sessionStorage.getItem("roleid");
      const roleslug = sessionStorage.getItem("roleslug");
      const organizationid = sessionStorage.getItem("organizationid");
      formData.type = 'fillInTheBlanks';
      var CourseCluster = '';
      var assessmentCluster='';
      if (roleslug === 'superadmin'){
        CourseCluster = '65eef8496cbe0baacc3aab6f';
        assessmentCluster ='6607a835f89bfe1c7d5f0807';
      } else {
        CourseCluster = '65eef8586cbe0baacc3aae21';
        assessmentCluster ='6607a83df89bfe1c7d5f0809';
        formData.organization = organizationid;
      }
      const formDataWithAnswer = {
        ...formData,
        // question: `${formData.question} ___${formData.answer}___`, // Add the blank symbol within the question
        assessmentCluster: assessmentCluster,
        courseCluster: CourseCluster,
        role: roleid,
        createdBy: sessionStorage.getItem("authorid"), 
      };
      debugger;
      const responseData = await post("assessment", formDataWithAnswer);
      if (responseData.statusCode === 201) {
        toast.success("Created Successfully", {
          onClose: () => {
            // window.location.href = "/questionbank";
            setFormData((prevState) => ({
              ...prevState,
              question: '',
              difficultyLevel: ''
            }));
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <DashboardContent pageTitle="Create Question - Fill In the Blanks">
      <Row>
        <Col md={12}>
          <div className="student-info hidecheckox">
            <Form id="createQuestion" className="mt-5">
              <Row>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Course:</Form.Label>
                      <SelectField options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses} required/>
                    </Col>
                    </Row>
                    <Row>
                    {selectedOptionCourses && ( // Render module select box only if course is selected
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        {/* <Form.Label>Module:</Form.Label> */}
                        <CustomSelectField options={optionsModuleCourse} placeholder="Select Module" onChange={handleChangeModule} selected={formData.coursemodule} isSearchable handleModuleSubmit={handleModuleSubmit} required/>

                    </Col>
                     )}  
                     {selectedOptionModule && ( // Render unit select box only if module is selected */} */}
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    {/* <Form.Label>Unit:</Form.Label> */}
                    {/* <SelectField options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} /> */}
                    <UnitSelectField  options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} selected={formData.coursechapter} isSearchable handleUnitSubmit={handleUnitSubmit} required/>
                  </Col>
                 )}
                   {selectedOptionUnit && ( // Render unit select box only if module is selected */} */}
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Lesson:</Form.Label>
                    <SelectField options={optionsLessonUnit} placeholder="Select Lesson" onChange={handleChangeLesson} />
                    {/* <UnitSelectField  options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} selected={formData.coursechapter} isSearchable handleUnitSubmit={handleUnitSubmit}/> */}
                  </Col>
                 )} 
                 {/* {selectedOptionCourses &&  */}
                  <Col md={12} xl={12} lg={12} xs={12} className="form-group">
                    <Form.Label>Assessment Title:</Form.Label>
                    <SelectFieldQuiz
                        options={existingAssessments}
                        value={selectedAssessment}
                        selectedAssessment={selectedAssessment}
                        handleAssessmentChange={handleAssessmentChange}
                        isSearchable
                        selectedOptionCourses={selectedOptionCourses}
                        selectedOptionModule={selectedOptionModule}
                        selectedOptionUnit={selectedOptionUnit}
                        selectedOptionLesson={selectedOptionLesson}
                        handleQuizSubmit={handleQuizSubmit}
                        placeholder="Select a Quiz"
                        />
                    </Col>
                {/* } */}
                <Col md={12} className="form-group">
                  <Form.Group controlId="question">
                    <Form.Label>Question</Form.Label>
                    {/* <Form.Control
                      as="textarea"
                      rows={3}
                      name="question"
                      value={formData.question}
                      placeholder="Enter the question with a blank (e.g., The capital of France is ___)"
                      onChange={handleChange}
                    /> */}
                    <QuillEditor
                          name="question"
                          value={formData.question}
                          placeholder="Enter the question with a blank (e.g., The capital of France is ___)"
                          onChange={(content) =>
                            handleEditorChange("question", content)
                          }
                        />
                  </Form.Group>
                </Col>
                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  <Form.Group className="form-group">
                  <Form.Label>Difficulty Level</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.difficultyLevel}
                    onChange={(e) => setFormData({ ...formData, difficultyLevel: e.target.value })}
                    required
                  >
                    <option value="">Select Difficulty Level</option>
                    {[...Array(11).keys()].map((level) => (
                      <option key={level} value={level}>
                        {level}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                </Col>
                {/* <Col md={6} className="form-group">
                  <Form.Label>Answer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the answer for the blank"
                    name="answer"
                    value={formData.answer}
                    onChange={handleChange}
                  />
                </Col> */}
                            <Col md={12} xl={12} lg={12} xs={12} className="form-group">
                      <Form.Group controlId="explanation">
                        <Form.Label>Explanation</Form.Label>
                        <QuillEditor
                          name="explanation"
                          value={formData.explanation}
                          onChange={(content) =>
                            handleEditorChange("explanation", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                </Row>
                <Row>
                <Col md={6} className="form-group">
                  <Button
                   onClick={handleSubmit}
                    type="submit"
                    className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                  >
                    Create Question
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
      
      {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectFieldQuiz onQuizCreated={fetchExistingAssessments} />
        </Modal.Body>
      </Modal> */}
      <ToastContainer />
    </DashboardContent>
  );
}

export default FillInTheBlanks;
