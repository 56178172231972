import React, { useState, useEffect } from "react";
import { del, get, put } from "./rest";
import DashboardContent from "./DashboardContent";
import { Button, Card, Col, Row } from "react-bootstrap";
import SelectField from "./SelectField";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";

function EditCourseCohort() {
  const [id, setId] = useState(null);
  const [stid, setStId] = useState(null);
  const location = useLocation();
  const [formData, setFormData] = useState({
    course: "",
    subscribed_students: "",
    slots:""
  });
  const [cohort, setCohort] = useState("");
  const [courseCalendar, setCourseCalendar] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [paramStudent, setParamStudent] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [courseCalendarTimeSlots, setCourseCalendarTimeSlots] = useState([]);

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const stid = searchParams.get("stid");
    setId(id);
    setStId(stid);
    fetchCourseCalendarTimeSlot();
    fetchCourseCohort(id);
    fetchStudents(stid);
    
  }, [location.search]);
 
  const fetchCourseCalendar = (selectedCourse) => {
    get("coursecalendar")
      .then((response) => {
        const options = response.response.map((cour) => ({
          value: cour.course.id,
          label: cour.course.course_title,
          isselected: cour.course.id === selectedCourse
        }));
        setCourseCalendar(options);
        setSelectedCourse(selectedCourse);
      })
      .catch((error) =>
        console.error("Error fetching course calendar:", error)
      );
  };
  const fetchCourseCohort = async (id) => {
    try {
        const response = await get('coursecalendartimeslot/' + id);
        setCohort(response.response);
        debugger;
        setFormData(response.response);
        fetchCourseCalendar(response.response.course_calendar.course.id);
        fetchTimeSlotsCompare(response.response.course_calendar.course.id, response.response.id );

    } catch (error) {
      console.error("Error fetching Industries:", error);
    }
  };
  // Fetch students data
  const fetchStudents = async (stid) => {
    try {
      const response = await get("users");
      const studentsData = response.response.filter(
        (emp) => emp.role.slug === "student"
      );
      const options = studentsData.map(stu => ({
        value: stu.id,
        label: stu.username,
        isselected: stu.id === stid
      }));
      setStudents(options);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const fetchCourseCalendarTimeSlot = async () => {
    try {
        const response = await get("coursecalendartimeslot");
        debugger;  
        setCourseCalendarTimeSlots(response.response);
    } catch (error) {
        console.error("Error fetching time slots:", error);
    }
};
  const fetchTimeSlots = async (courseselected) => {
    debugger;
    try {
      const response = await get("coursecalendartimeslot");
      
      const options = response.response.map(slot => {
        if (slot.course_calendar.course.id === courseselected) {
          return {
            value: slot._id,
            label: `${slot.start_time} - ${slot.end_time} (${slot.course_calendar.start_date} - ${slot.course_calendar.end_date})`,
            isselected : slot._id === id
          };
        }
        return null; // or undefined
      }).filter(Boolean); 
  
      setTimeSlots(options);
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  };
  const fetchTimeSlotsCompare = async (courseselected, id) => {
    debugger;
    try {
      const response = await get("coursecalendartimeslot");
      
      const options = response.response.map(slot => {
        if (slot.course_calendar.course.id === courseselected) {
          return {
            value: slot._id,
            label: `${slot.start_time} - ${slot.end_time} (${slot.course_calendar.start_date} - ${slot.course_calendar.end_date})`,
            isselected : slot._id === id
          };
        }
        return null; // or undefined
      }).filter(Boolean); 
      setSelectedTimeSlot(id);
      setTimeSlots(options);
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  };
    
  // Handle course selection
  const handleCourseSelect = (event) => {
    debugger;
    setSelectedCourse(event);
    setSelectedSlot("");
    fetchTimeSlots(event);

  };

 // Handle time slot selection
const handleSlotSelect = (selectedSlotValue) => {
    // // Find the corresponding option object in the timeSlots array
    // const selectedOption = courseCalendar.find(option => option.label === selectedSlotValue);
  
    // // Extract the value (ID) from the selected option
    // const slotId = selectedOption ? selectedOption.value : null;
  
    setSelectedSlot(selectedSlotValue);
  };  
  
  // Handle student selection
  const handleStudentSelect = (event) => {
    debugger;
    setSelectedStudent(event);
  };

  const handleCohortSubmit = () => {
    // Fetch the current list of subscribed students for the selected time slot
    const currentSubscribedStudents = courseCalendarTimeSlots
        .find(slot => slot._id === selectedSlot)
        .subscribed_students;

    // Combine the current list with the new student, ensuring no duplicates
    const updatedSubscribedStudents = Array.from(new Set([...currentSubscribedStudents, selectedStudent]));
debugger;
    put("coursecalendartimeslot/" + selectedSlot,  {
        subscribed_students: updatedSubscribedStudents,
        updatedBy : sessionStorage.getItem("authorid")
    })
    .then((response) => {
        if (response.statusCode === 200) {
            toast.success('Course cohort added successfully');
            fetchCourseCalendarTimeSlot();
        }
        console.log("Cohort submitted:", response.data);
    })
    .catch((error) =>
        console.error("Error assigning student to time slot:", error)
    );
};


  return (
    <DashboardContent pageTitle="Course Cohort">
      <div className="card dashboard-card-ten mb-5">
        <div className="card-body">
          <div className="heading-layout1">
            <div className="item-title">
              <h3>Assign Students To Time Slots</h3>
            </div>
          </div>
            <div className="form-group">
                <label >Select Course:</label>
                <SelectField
                    id="course"
                    options={courseCalendar}
                    placeholder="Select Course"
                    onChange={handleCourseSelect}
                    selected={selectedCourse}
                    value={selectedCourse} >                    
                </SelectField>
            </div>
          
            <div className="form-group">
                {selectedCourse && (
                    <div>
                    <label >Select Time Slot:</label>
                    <SelectField
                        id="slot"
                        options={timeSlots}
                        placeholder="Select Time Slots"
                        selected={selectedTimeSlot}
                        onChange={handleSlotSelect}
                        value={selectedSlot}
                      />
                    </div>
                )}
            </div>
            <div className="form-group">

              <label >Select Student:</label>
              <SelectField
                id="student"
                options={students}
                placeholder="Select Student"
                onChange={handleStudentSelect}
                value={selectedStudent}
                selected={stid} 
                />
              </div>
              <button onClick={handleCohortSubmit} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Assign Student</button>
        </div>
      </div>
      
        {/* {courseCalendarTimeSlots
          .filter(slot => slot.subscribed_students.some(student => student.id === id))
          .map((courseDetails, i, array) => (
              <div key={i}>
                <Card>
                  <Card.Body>
                    Course: {courseDetails.course_calendar.course.course_title}
                    <br />
                    Start Time: {courseDetails.start_time}
                    <br />
                    End Time: {courseDetails.end_time}
                    <Row>
                      <Col>
                        <Button onClick={()=>window.location='/editCourseCohort?id='+ courseDetails.id} className='mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark'>Edit</Button>              
                      </Col>
                      <Col>
                        <Button onClick={() => handleDeleteStudent(courseDetails.id, id)} className='mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark'>Delete </Button>              
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {i < array.length - 1 && ','}
              </div>
          ))}              */}
       
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditCourseCohort;
