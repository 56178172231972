import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import QuillEditor from "./QuillEditor";
import { useCallback, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { get, post, put } from "./rest";
import { useLocation } from "react-router-dom";

function ViewChat() {
    const [formData, setformData] = useState({ message: "" });
    const [messages, setMessages] = useState([]);
    const [data, setData] = useState();
    const [id, setId] = useState(null);
    const location = useLocation();
    const authorid = sessionStorage.getItem("authorid");
    const organizationid = sessionStorage.getItem("organizationid");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        setId(id); // Set the ID value in the state variable
        fetchIssue(id); // Fetch organization data based on the ID
    }, [location.search]);

    const fetchIssue = useCallback(async (id) => {
        try {
            // Fetch assessments with applied filters
            const response = await get('messages/'+ id);
            setData(response.response);
            setMessages(response.response.metadata || []); // Assuming metadata is the array of messages
        } catch (error) {
            console.error('Error fetching Assessments:', error);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if the message is empty
        if (!formData.message.trim()) {
            toast.error("Message cannot be empty!");
            return;
        }
    
        try {
            setIsSubmitting(true);
            // Prepare the new message
            const newMessage = {
                description: formData.message,
                createdon: new Date().toISOString(),
                sendername: sessionStorage.getItem("authorname")
            };
    
            // Create the updated metadata array
            const updatedMetadata = [...messages, newMessage];
    
            // Prepare the submit data
            const submitData = {
                metadata: updatedMetadata
            };
    
            const responseData = await put('messages/' + id, submitData);
            if (responseData.statusCode === 200) {
                // Send notifications
                for (let i = 0; i < data.users.length; i++) {
                    const userId = data.users[i];
                    const notificationData = {
                        createduser: authorid,
                        user: userId,
                        organization: organizationid,
                        link: `/viewchat?id=` + id,
                        message: data.issue_name,
                        type: "chat"
                    };
    
                    await post('notifications', notificationData);
                }
    
                const notificationData = {
                    createduser: authorid,
                    user: data.sender.id,
                    organization: organizationid,
                    link: `/viewchat?id=` + id,
                    message: data.issue_name,
                    type: "chat"
                };
    
                await post('notifications', notificationData);
                toast.success("Sent Successfully", {
                    onClose: () => {
                        fetchIssue(id); // Refresh messages
                        setformData({ message: "" }); // Clear the message input
                        setIsSubmitting(false);
                    },
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("Error sending message. Please try again.");
            setIsSubmitting(false);
        }
    };
    // const handleSubmit = async (e) => {
    //     debugger;
    //     e.preventDefault();
    //     try {
    //         // Prepare the new message
    //         const newMessage = {
    //             description: formData.message,
    //             createdon: new Date().toISOString(),
    //             sendername: sessionStorage.getItem("authorname")
    //         };

    //         // Create the updated metadata array
    //         const updatedMetadata = [...messages, newMessage];

    //         // Prepare the submit data
    //         const submitData = {
    //             metadata: updatedMetadata
    //         };
    //         debugger;
    //         const responseData = await put('messages/' + id, submitData);
    //         if (responseData.statusCode === 200) {
    //             debugger;
    //             for (let i = 0; i < data.users.length; i++) {
    //                 const user = data.users[i];
    //                 const userId = user;
    //                   const notificationData = {
    //                     createduser: authorid,
    //                     user: userId,
    //                     organization: organizationid,
    //                     link: `/viewchat?id=` + id,
    //                     message: data.issue_name,
    //                     type:"chat"
    //                 };
          
    //                 await post('notifications', notificationData);
    //             }
    //             const notificationData = {
    //                 createduser: authorid,
    //                 user: data.sender.id,
    //                 organization: organizationid,
    //                 link: `/viewchat?id=` + id,
    //                 message: data.issue_name,
    //                 type:"chat"
    //             };
      
    //             await post('notifications', notificationData);
    //             toast.success("Sent Successfully", {
    //                 onClose: () => {
    //                     fetchIssue(id);
    //                 },
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error submitting form:", error);
    //     }
    // };

    const handleEditorChange = (name, content) => {
        setformData((prevState) => ({
            ...prevState,
            [name]: content,
        }));
    };
    // const canReply = () => {
    //     debugger;
    //     return data.some(chat => 
    //          chat.sender === authorid || 
    //         (chat.users && Array.isArray(chat.users) && chat.users.some(user => user.id === authorid))
    //     );
    // };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleString(undefined, options);
    };
    return ( 
        <DashboardContent pageTitle="View Chat">
            <Card>
                <CardBody>
                    {/* {data.issue_name && <h4 className="mb-5">{data.issue_name}</h4>} */}
                    {/* <CardTitle>{data.issue_name}</CardTitle> */}
                    {messages.map((chat, index) => (
                        <Col key={index} md={12} className="mb-3">
                            <Card>
                                <CardBody>
                                    <small>From: {chat.sendername}</small>
                                    <Card.Text dangerouslySetInnerHTML={{ __html: chat.description }} className="my-4"></Card.Text>
                                    <small>{formatDate(chat.createdon)}</small>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                     {/* {canReply() && ( */}
                    <Card className="mb-4">
                        <CardBody>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Row>
                                        <Col md={1}>
                                            <FormLabel>Reply:</FormLabel>
                                        </Col>
                                        <Col md={11}>
                                            <QuillEditor
                                                name="message"
                                                value={formData.message}
                                                onChange={(content) => handleEditorChange("message", content)}
                                            />
                                        </Col>
                                        <Col md={4}>
                                        <Button type="submit" className="fw-btn-fill btn-gradient-yellow mt-4" disabled={isSubmitting}>
                                            {isSubmitting ? "Sending..." : "Send"}
                                        </Button>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                    {/* )} */}
                </CardBody>
            </Card>
            <ToastContainer></ToastContainer>
        </DashboardContent>
    );
}

export default ViewChat;
