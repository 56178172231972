import { Col, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import PaymentGraph from "./PaymentGraph";
import { useEffect, useState } from "react";
import { get } from "./rest";

function DashBoardStudent() {
  const [course, setCoursesCount] = useState();
  const [enquiries, setEnquiriesCount] = useState();
  const [org, setOrgCount] = useState();
  const [assessmentscount, setAssessmentsCount] = useState();

  useEffect(() => {
    // fetchCourseCount();
    fetchEnquiriesCount();
    fetchOrgCount();
    fetchResults();
    fetchEnrolledCourse();
  }, []);

  const fetchCourseCount = async () => {
    try{
      get('/courses/count')
      .then(response => {
        setCoursesCount(response.response);
      })
    } catch {
    }
  }
  const fetchEnquiriesCount = async () => {
    try{
      get('enquiries/count')
      .then(response => {
        setEnquiriesCount(response.response);
      })
    } catch {
    }
  }
  const fetchOrgCount = async () => {
    try{
      get('/organization/count')
      .then(response => {
        setOrgCount(response.response);
      })
    } catch {
    }
  }
  const fetchResults = async () => {
    var organizationid = sessionStorage.getItem("organizationid");
    // var roleid = sessionStorage.getItem("roleid");
    var authorid = sessionStorage.getItem("authorid");
    try {
      get('assessmentreports')
        .then(response => {
          var orgAdmins = response.response.filter(result => result.organization.id === organizationid && result.user.id === authorid);
          
          setAssessmentsCount(orgAdmins.length);
        })
        .catch(error => console.error('Error fetching data:', error));
    } catch (error) {
      console.error('Error fetching Results:', error);
    }
  };
  const fetchEnrolledCourse = async () => {
    try {
        const response = await get('usercourseenrollment');
        // const role = sessionStorage.getItem("roleslug");
        const organizationid = sessionStorage.getItem("organizationid");
        const authorid = sessionStorage.getItem("authorid");

        const coursedata = response.response.filter(admin => admin.organization.id === organizationid && admin.user.id === authorid);
        setCoursesCount(coursedata.length);
    } catch (error) {
        console.error('Error fetching DefaultCourse:', error);
    }
};

  return (
    <DashboardContent pageTitle="Dashboard">
      {/* <PaymentGraph /> */}
      <Row>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-green ">
                  <i className="fa-solid fa-chalkboard-user text-green"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Enrolled Topics</div>
                  <div className="item-number" id="ClassCount">
                    {course}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-blue ">
                  <i className="fa-solid fa-chart-line text-blue"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Assessments</div>
                  <div className="item-number" id="ClassCount">
                    {assessmentscount}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        {/* <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-yellow ">
                  <i className="fa-brands fa-google text-yellow"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Subscription Plan</div>
                  <div className="item-number" id="ClassCount">
                    0
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-magenta ">
                <i className="fa-solid fa-chalkboard-user text-mauvelous"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Course Count</div>
                  <div className="item-number" id="ClassCount">
                    {course}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col> */}
      </Row>
    </DashboardContent>
  );
}

export default DashBoardStudent;
