import { Button, Col, Form, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import MultiSelectField from "./MultiSelectField";
import SelectField from "./SelectField";
import DashboardContent from "./DashboardContent";
import { useEffect, useState } from "react";
import { get } from "./rest";

function EditRoleAssigned() {
    const [formData, setFormData] = useState({
        role:"",
        activity_group:"",
        activity:[]
    });
    const [optionsRoles, setOptionsRoles] = useState([]);
    useEffect(() => {
        fetchRolesOptions(); // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    const fetchRolesOptions = async () => {
        try {
            const response = await get('roles');
            debugger;
            const options = response.response.map(role => ({
                value: role.id,
                label: role.role,
                isSelected: role.id === formData.role.id,
            }));
            setOptionsRoles(options);
        } catch (error) {
            console.error('Error fetching roles options:', error);
        }
    };

        return (
            <DashboardContent pageTitle="Edit Assigned Roles">
                <Row>
                    <Col md={12}>
                        <div className="card dashboard-card-ten">
                            <div className="card-body">
                                <div className="heading-layout1">
                                    <div className="item-title">
                                        <h3>Assigned Activity Group</h3>
                                    </div>
                                </div>
                                <div className="student-info">
                                    {/* <Form onSubmit={handleSubmit} id="editroleassigned" className="mt-5">
                                        <Row>
                                            <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                                <Form.Label>Roles:</Form.Label>
                                                <SelectField options={optionsRoles} onChange={handleChangeRoles} selected={formData.role}/>
                                            </Col>
                                        </Row>
                                         {assignedGroups.map((assignedGroup, index) => ( 
                                             <Row key={index}> 
                                                <Col md={5} xl={5} lg={5} xs={12} className="form-group">
                                                    <Form.Label>Group:</Form.Label>
                                                    <SelectField
                                                        options={optionsGroups}
                                                        onChange={(selectedGroupOption) => handleGroupChange(selectedGroupOption)}
                                                    />
                                                </Col>
                                                <Col md={5} xl={5} lg={5} xs={12} className="form-group">
                                                    <Form.Label>Activities:</Form.Label>
                                                    <MultiSelectField
                                                        // options={assignedGroup.activities}
                                                        onChange={(selectedOption) => handleChange(selectedOption)}
                                                    />
                                                </Col>
                                    
                                        <Row>
                                            <Col md={12} className="form-group">
                                                <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Assign Roles</Button>
                                            </Col>
                                        </Row>
                                        </Row>
                                        ))}
                                    </Form> */}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
            </DashboardContent>
        );
     
}

export default EditRoleAssigned;