import React from 'react';
import { Form } from 'react-bootstrap';

function SubscriptionSelectField({ options, onChange, selected, placeholder }) {
  const handleChange = (e) => {
    const selectedOption = options.find(option => option.value === e.target.value);
    onChange(selectedOption);
  };

  return (
    <Form.Control as="select" onChange={handleChange} value={selected?.value || ''}>
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Control>
  );
}

export default SubscriptionSelectField;
