import { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { useLocation } from "react-router-dom";
import { get } from "./rest";
import { Card, CardBody, CardHeader, Col, Container, Row } from "react-bootstrap";
import VerticalAccordionView from "./VerticalAccordionView";
import 'bootstrap/dist/css/bootstrap.min.css';
import './CourseDetail.css';
import AssessmentView from "./AssessmentView";

function Quiz() {
  const [id, setId] = useState(null);
  const location = useLocation();
  const [modules, setModules] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);

    if (id) {
      fetchModules(id);
    } else {
      fetchDefaultCourses();
    }
  }, [location.search]);

  const fetchDefaultCourses = async () => {
    try {
      const response = await get('courses');
      const role = sessionStorage.getItem("roleslug");
      const organizationid = sessionStorage.getItem("organizationid");
      let courseData;

      if (role === 'superadmin') {
        courseData = response.response.filter(course => course.role.slug !== "employee" && course.role.slug !== "organizationadmin");
      } else if (role === 'organizationadmin') {
        courseData = response.response.filter(course => course.role.slug !== 'superadmin' && course.organization === organizationid);
      } else {
        courseData = response.response.filter(course => course.organization === organizationid);
      }
      
      setCourses(courseData);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchModules = async (courseId) => {
    try {
      const response = await get(`coursemodule?course=${courseId}`);
      const options = response.response.map((mod) => ({
        title: mod.id,
        content: mod.title,
      }));
      setModules(options);
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  const handleModuleSelect = (module) => {
    setSelectedModule(module);
    setSelectedChapter(null); // Clear selected chapter when module is changed
  };

  const handleChapterSelect = (chapterId) => {
    setSelectedChapter(chapterId);
  };

  const handleCourseSelect = (courseId) => {
    setSelectedCourse(courseId);
    fetchModules(courseId);
    setCourses([]);
  };

  return (
    <DashboardContent
      pageTitle={
        <>
          <a href="/mycourses">Topics</a> - Quiz
        </>
      }
    >
      <Container fluid className="py-5" style={{ backgroundColor: "#fff" }}>
        <Row>
          {courses.map((course, index) => (
            <Col md={4} className="my-5" key={index}>
              <Card className="flex-fill" onClick={() => handleCourseSelect(course.id)}>
                <CardHeader className="p-0">
                  {course.price > 0 && (
                    <i className="fa-brands fa-square-web-awesome-stroke premimum-icon"></i>
                  )}
                  <img src={course.thumbnail} alt={course.course_title} className="img-fluid" height="150px" />
                </CardHeader>
                <CardBody>
                  <h3>{course.course_title}</h3>
                  <p>Category: {course.category.industry}</p>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        {modules.length > 0 && (
          <Row>
            {/* <Col md={3} className="px-0">
              <VerticalAccordionView
                className="p-0"
                items={modules}
                onModuleSelect={handleModuleSelect}
                onChapterSelect={handleChapterSelect}
              />
            </Col> */}
            <Col md={12} className="px-5">
              <AssessmentView
                selectedCourse={id}
                selectedModule={selectedModule}
                selectedChapter={selectedChapter}
              />
            </Col>
          </Row>
        )}
      </Container>
    </DashboardContent>
  );
}

export default Quiz;
