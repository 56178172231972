import { Col, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { useEffect, useState } from "react";
import { get } from "./rest";
import './BarChart.css';

function DashboardOtherUser() {
  const [course, setCoursesCount] = useState(0);
  const [student, setStudentCount] = useState(0);
  const [lesson, setLessonCount] = useState(0);
  const [quizCount, setQuizCount] = useState(0);

  useEffect(() => {
    fetchCourseCount();
    fetchStudentCount();
    fetchLessonsCount();
    fetchQuizCount();
  }, []);

  const authorid = sessionStorage.getItem("authorid");

  const fetchCourseCount = async () => {
    try {
      const response = await get('courses');
      let coursedata =  response.response.filter(admin => admin.createdBy && admin.createdBy.id === authorid);
      debugger
      setCoursesCount(coursedata.length);
    } catch (error) {
      console.error('Error fetching DefaultCourse:', error);
    }
  };


  const fetchLessonsCount = async () => {
    try {
      const response = await get('lms');
      let lmsdata = response.response.filter(lms => lms.createdBy && lms.createdBy.id === authorid);
      
      setLessonCount(lmsdata.length);
    } catch (error) {
      console.error('Error fetching LMS:', error);
    }
  };
  const fetchQuizCount = async () => {
    try {
      const response = await get('assessmentquiz');
      let quizdata = response.response.filter(quiz => quiz.createdBy && quiz.createdBy.id === authorid);
      
      setQuizCount(quizdata.length);
    } catch (error) {
      console.error('Error fetching LMS:', error);
    }
  };

  const fetchStudentCount = async () => {
    const organizationid = sessionStorage.getItem("organizationid");
    try {
      const response = await get('usercourseenrollment');
      const studentsData = response.response.filter(emp => emp.course.createdBy === authorid && emp.organization && emp.organization.id === organizationid);
    //   const teacherData = response.response.filter(emp => emp.role.slug === 'teacher' && emp.organization && emp.organization.id === organizationid);

      setStudentCount(studentsData.length);
    //   setTeacherCount(teacherData.length);
    } catch (error) {
      console.error('Error fetching Students:', error);
    }
  };

  return (
    <DashboardContent pageTitle="Dashboard">

      <Row className="mt-5">
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-green ">
                  <i className="fa fa-users text-green"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">No. of Students</div>
                  <div className="item-number" id="ClassCount">
                    {student}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-magenta ">
                  <i className="fa-solid fa-chalkboard-user text-mauvelous"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Topics Count</div>
                  <div className="item-number" id="ClassCount">
                    {course}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-yellow ">
                  <i className="fa fa-list text-yellow"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Lessons Count</div>
                  <div className="item-number" id="ClassCount">
                    {lesson}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="dashboard-summery-one mg-b-20">
            <Row className="align-items-center">
              <Col md={4}>
                <div className="item-icon bg-light-blue ">
                <i className="fa-solid fa-chart-line text-blue"></i>
                </div>
              </Col>
              <Col md={8}>
                <div className="item-content">
                  <div className="item-title">Quiz Created</div>
                  <div className="item-number" id="ClassCount">
                    {quizCount}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        
        
      </Row>

    </DashboardContent>
  );
}

export default DashboardOtherUser;
