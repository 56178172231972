import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { get, post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from "react";


function CreateRoles() {
    const [formData, setFormData] = useState({
       
      });
      // const [response, setResponse] = useState(null);
      // const [roleExists, setRoleExists] = useState(false);
      const handleChange = async (e) => {
        const { name, value } = e.target;

        
          setFormData(prevState => ({
            ...prevState,
            [name]: value
          }));
        
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const slug = formData.role.replace(/\s+/g, '-').toLowerCase();
          var organizationid = sessionStorage.getItem("organizationid");

        // Add the slug to the form data
        const formDataWithSlug = {
            ...formData,
            slug: slug,
            organization: organizationid,
            createdBy: sessionStorage.getItem("authorid"), 
        };
        const checkEmailResponse = await get(`roles`);
        const setRoleExists = checkEmailResponse.response.filter(rol => rol.slug === slug && rol.organization && rol.organization.id === organizationid);
        if (setRoleExists.length !== 0) {
          toast.error('Role already exists!');
        } else {
          const responseData = await post('roles', formDataWithSlug);
          if(responseData.statusCode === 201){
            toast.success("Created Successfully", {
              onClose: () => {
                window.location.href='/roles';
              },
            });
          }
        }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };
    
    return ( 
        <DashboardContent pageTitle="Create Role">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Create Role</h3>
                                </div>
                            </div>
                            <div className="student-info">
                            <Form onSubmit={handleSubmit} id="createRole" className="mt-5">
                            <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    <Form.Label>Role:</Form.Label>
                                    <Form.Control type="text" id="role" name="role" className="form-control" onChange={handleChange} required />
                                </Col>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    
                                </Col>
                                </Row>
                <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Create Role</Button>
                                </Col>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        <ToastContainer />
    </DashboardContent>
    );
}

export default CreateRoles;