import { Carousel } from "react-bootstrap";

function BannerSlider({banners}) {
    debugger;
    return ( 
        <Carousel>
            {banners.map((banner, index) => (
                <Carousel.Item key={index}>
                    <img src={banner.url} alt={banner.name} className="d-block w-100 h-100"/>
                </Carousel.Item>
            ))}
        </Carousel>
     );
}

export default BannerSlider;