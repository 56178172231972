import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { get, put } from "./rest";
import { useLocation } from "react-router-dom";
import QuillEditor from "./QuillEditor";
import Select from "react-select";
import SelectField from "./SelectField";

function EditAssessmentQuiz() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    averagePassPercentage: "",
    difficultyLevel: "",
    timer: "",
    questionscount: "",
    assessmentQuiz: null,
  });
  const [existingAssessments, setExistingAssessments] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [id, setId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchAssessments(id);
  }, [location.search]);

  useEffect(() => {
    fetchExistingAssessments();
  }, []);

  const fetchExistingAssessments = async () => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get(`assessmentquiz`);
      const assessments = response.response
        .filter((assessment) => assessment.organization === organizationid)
        .map((assessment) => ({
          value: assessment.id,
          label: assessment.title,
        }));

      setExistingAssessments(assessments);
    } catch (error) {
      console.error("Error fetching existing assessments:", error);
    }
  };

  const fetchAssessments = async (id) => {
    try {
      const response = await get("assessmentquiz/" + id);
      setFormData((prevState) => ({
        ...prevState,
        ...response.response,
        assessmentQuiz: response.response.assessmentQuiz,
      }));
      setSelectedAssessment(response.response.assessmentQuiz);
    } catch (error) {
      console.error("Error fetching Assessments:", error);
    }
  };

  const handleTitleChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      title: value,
    }));
  };

  const handleDescriptionChange = (content) => {
    setFormData((prevState) => ({
      ...prevState,
      description: content,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = {
        ...formData,
        updatedBy : sessionStorage.getItem("authorid")
      };
      const responseData = await put("assessmentquiz/" + id, updatedFormData);
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/assessments";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAssessmentChange = (selectedOption) => {
    debugger
    setSelectedAssessment(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      assessmentQuiz: selectedOption,
    }));
  };

  const handleDifficultyLevelChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      difficultyLevel: selectedOption.value.toString(),
    }));
  };

  const options = [...Array(11).keys()].map((level) => ({
    value: level,
    label: level.toString(),
  }));

  return (
    <DashboardContent pageTitle="Edit Assessment">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Edit Assessment </h3>
                </div>
              </div>
              <div className="student-info hidecheckox">
                <Form onSubmit={handleSubmit} id="editAssessment" className="mt-5">
                  {formData.type === "random" ? (
                    <>
                      <Row>
                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                          <Form.Label>Quiz Name</Form.Label>
                          <Form.Control
                            type="text"
                            id="title"
                            name="title"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.title}
                            required
                          />
                        </Col>

                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                          <Form.Label>No. of Questions</Form.Label>
                          <Form.Control
                            type="text"
                            id="questionscount"
                            name="questionscount"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.questionscount}
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                          <Form.Label>Select Quiz</Form.Label>
                          <SelectField
                            options={existingAssessments}
                            selected={selectedAssessment}
                            onChange={handleAssessmentChange}
                            placeholder="Select Quiz"
                            isClearable
                          />
                        
                        </Col>

                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                          <Form.Label>Difficulty Level</Form.Label>
                          {/* <Select
                            value={options.find(option => option.value === formData.difficultyLevel)}
                            onChange={handleDifficultyLevelChange}
                            options={options}
                            required
                          /> */}
                          <SelectField
                            options={options}
                            selected={formData.difficultyLevel}
                            onChange={handleDifficultyLevelChange}
                            placeholder="Select Difficulty Level"
                            isClearable
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col md={8} xl={8} lg={8} xs={12} className="form-group">
                          <Form.Label> Title:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Quiz Title"
                            name="title"
                            value={formData.title}
                            onChange={handleTitleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8} xl={8} lg={8} xs={12} className="form-group">
                          <Form.Label>Assessment Description:</Form.Label>
                          <QuillEditor
                            name="description"
                            value={formData.description}
                            onChange={handleDescriptionChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xl={4} lg={4} xs={4} className="form-group">
                            <Form.Label>Average Pass Percentage</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter average pass percentage"
                              value={formData.averagePassPercentage}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  averagePassPercentage: e.target.value,
                                })
                              }
                              required
                            />
                        </Col>
                        <Col md={4} xl={4} lg={4} xs={4} className="form-group">
                            <Form.Label>Difficulty Level</Form.Label>
                            <Form.Control
                              as="select"
                              value={formData.difficultyLevel}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  difficultyLevel: e.target.value,
                                })
                              }
                              required
                            >
                              <option value="">Select Difficulty Level</option>
                              {[...Array(11).keys()].map((level) => (
                                <option key={level} value={level}>
                                  {level}
                                </option>
                              ))}
                            </Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xl={4} lg={4} xs={4} className="form-group">
                          <Form.Group controlId="formTimer">
                            <Form.Label>Timer (in minutes)</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter quiz timer"
                              value={formData.timer}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  timer: e.target.value,
                                })
                              }
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Button
                        type="submit"
                        className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      >
                        Update Assessment
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditAssessmentQuiz;
