import React, { useState, useEffect } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card, Col, Form, Modal, Row, Tab, Table } from 'react-bootstrap';
import { del, get } from './rest';
import { toast, ToastContainer } from 'react-toastify';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

function Assessments() {
  const [assessments, setAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [assessmentsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizId, setQuizId] = useState(null);

  useEffect(() => {  
    fetchAssessments();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await get(`assessment`);
        const filteredAssessments = response.response.filter(
          ass => ass.assessmentQuiz.id === quizId
        );
        setQuizQuestions(filteredAssessments);
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error("Failed to fetch questions");
      }
    };

    if (show) {
      fetchQuestions();
    }
  }, [show, quizId]);

  const fetchAssessments = async () => {
    try {
      get('assessmentquiz')
        .then(response => {
          var role = sessionStorage.getItem("roleslug");
          var organizationid = sessionStorage.getItem("organizationid");
          var qundata ='';

          if(role === 'superadmin'){
            qundata = response.response.filter(qun => qun.role.slug === 'superadmin'); 
          } else if (role === 'organizationadmin'){
            qundata = response.response.filter(qun => qun.organization && qun.organization === organizationid);
          } else {
            qundata = response.response.filter(qun => qun.organization && qun.organization === organizationid);
          }
          setAssessments(qundata);
          setFilteredAssessments(qundata);
        })
        .catch(error => console.error('Error fetching data:', error));            
    } catch (error) {
      console.error('Error fetching Assessments:', error);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterAssessments(e.target.value);
  };

  const filterAssessments = (query) => {
    const filteredQuizs = assessments.filter(quiz => 
      (quiz.title && quiz.title.toLowerCase().includes(query.toLowerCase())) ||
      (quiz.type && quiz.type.toLowerCase().includes(query.toLowerCase())) ||
      (quiz.course && quiz.course.course_title.toLowerCase().includes(query.toLowerCase())) 
    );
    setFilteredAssessments(filteredQuizs);
    setCurrentPage(1);
  };
  
  const indexOfLastquiz = currentPage * assessmentsPerPage;
  const indexOfFirstquiz = indexOfLastquiz - assessmentsPerPage;
  const currentquizs = filteredAssessments.slice(indexOfFirstquiz, indexOfLastquiz);
  
  async function handleDelete(Id) {
    try {
      await del(`assessmentquiz/${Id}`);
      fetchAssessments();
    } catch (error) {
      console.error('Error deleting Assessments:', error);
    }
  }
  
  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredAssessments.length / assessmentsPerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredAssessments.length / assessmentsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleView = (id) => {
    setQuizId(id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setQuizQuestions([]);
  };
  const renderQuestion = (question) => {
    return (
      <Card key={question._id} className="p-3 mb-4 d-flex justify-content-between">
        <Row>
          <Col md={11}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question) }}></div>
            {question.type !== 'fillintheblanks' && (
              <div className="d-flex">
                {question.answer1 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `a) ${DOMPurify.sanitize(question.answer1)}` }}></div>}
                {question.answer2 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `b) ${DOMPurify.sanitize(question.answer2)}` }}></div>}
                {question.answer3 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `c) ${DOMPurify.sanitize(question.answer3)}` }}></div>}
                {question.answer4 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `d) ${DOMPurify.sanitize(question.answer4)}` }}></div>}
              </div>
            )}
          </Col>
          
        </Row>
      </Card>
    );
  };
  return (
    <DashboardContent pageTitle="Quiz">
      <div>
        <Row className="mt-5">
          <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} onChange={handleChange} placeholder="Search ..." className="form-control" />
          </Col>
        </Row>
        <div className="table-responsive mt-5" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
              <th>Course/Random</th>
                <th>Title</th>
                <th>Link</th>
                
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedAssessments">
              {currentquizs.map((quiz, index) => (
                <tr key={index}>
                  
                  <td>
                    {quiz.course && quiz.course.course_title 
                      ? quiz.course.course_title 
                      : (quiz.type === "random" ? "Random Quiz" : "")}
                  </td>
                  <td dangerouslySetInnerHTML={{ __html: quiz.title }}></td>
                  <td><Link to={`/quizview?quizid=${quiz.id}`}>open quiz link</Link></td>
                  <td>
                    <i onClick={() => window.location = '/editassessments?id=' + quiz.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i>
                    <i onClick={() => handleDelete(quiz.id)} className="fa-regular fa-trash-can"  style={{ paddingRight: '10px' }}></i>
                    {quiz.type !== "random" ? <i onClick={() => handleView(quiz.id)} className="fa-regular fa-eye"></i> : <></>} 
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
          <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredAssessments.length / assessmentsPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredAssessments.length / assessmentsPerPage)}
          >
            Next
          </Button>
        </nav>

        {/* Modal for viewing questions */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Quiz Questions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {quizQuestions.length > 0 ? (
                quizQuestions.map((question) => renderQuestion(question))
            ) : (
              <p>No questions available for this quiz.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer></ToastContainer>
    </DashboardContent>
  );
}

export default Assessments;
