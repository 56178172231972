import DOMPurify from 'dompurify';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function Evaluate({ show, handleClose, OrgAdmin, updateOrgAdmin }) {
  const [answers, setAnswers] = useState([]);
  const [correctCount, setCorrectCount] = useState(0);

  useEffect(() => {
    debugger;
    if (OrgAdmin) {
      const fillInTheBlanksAnswers = (OrgAdmin.answers || []).filter(
        answer => answer.questionType === 'fillInTheBlanks'
      );
      setAnswers(fillInTheBlanksAnswers);
    }
  }, [OrgAdmin]);

  const handleAnswerChange = (index, value) => {
    // Update the answer's isCorrect property based on the selected radio button
    const updatedAnswers = answers.map((answer, i) => {
      if (i === index) {
        return { ...answer, isCorrect: value };
      }
      return answer;
    });
    setAnswers(updatedAnswers);
        // Calculate correct count
        const count = updatedAnswers.filter(answer => answer.isCorrect).length;
        setCorrectCount(count);
  };
  
  // const handleAnswerChange = (index, isCorrect) => {
  //   const updatedAnswers = answers.map((answer, i) => {
  //     if (i === index) {
  //       return { ...answer, isCorrect };
  //     }
  //     return answer;
  //   });
  //   setAnswers(updatedAnswers);

  //   // Calculate correct count
  //   const count = updatedAnswers.filter(answer => answer.isCorrect).length;
  //   setCorrectCount(count);
  // };

  const handleSubmit = () => {
    const correctPercentage = (correctCount / answers.length) * 100;

    const updatedData = {
      ...OrgAdmin,
      answers: OrgAdmin.answers.map(answer => {
        const updatedAnswer = answers.find(a => a.questionId === answer.questionId);
        return updatedAnswer ? { ...answer, isCorrect: updatedAnswer.isCorrect } : answer;
      }),
      correctPercentage
    };

    updateOrgAdmin(OrgAdmin.id, updatedData);
    handleClose();
  };

  // Debugging: log to check values
  console.log('OrgAdmin:', OrgAdmin);
  console.log('answers:', answers);

  return (
    <Modal show={show} onHide={handleClose} className='evaluate'>
      <Modal.Header closeButton>
        <Modal.Title>Correct Fill in the Blanks Answers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        {answers.map((answer, index) => (
 <Form.Group controlId={`answer-${index}`} key={index}>
 <Form.Label className='d-flex mt-4'>
   {index + 1}. &nbsp;
   <span
     dangerouslySetInnerHTML={{
       __html: DOMPurify.sanitize(answer.questionTitle),
     }}
   ></span>
 </Form.Label>
 <div>
   <p>Given Answer: {answer.selectedOption.join(", ")}</p>
   
   <label className="form-check-label">
     <input
       type="radio"
       name={`answer-${index}`}
       checked={answer.isCorrect === true}
       onChange={() => handleAnswerChange(index, true)}
       className="form-check-input me-4"
     />
      Correct
   </label>

   <label className="form-check-label ms-4">
     <input
       type="radio"
       name={`answer-${index}`}
       checked={answer.isCorrect === false}
       onChange={() => handleAnswerChange(index, false)}
       className="form-check-input me-4"
     />
      Incorrect
   </label>
 </div>
</Form.Group>

))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className='plain-btn py-3'>
          Close
        </Button>
        <Button onClick={handleSubmit} className='secondary-btn'>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Evaluate;
