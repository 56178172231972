import React, { useState, useEffect, useCallback } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { del, get } from './rest';
import { useLocation } from "react-router-dom";
import { format, isToday, isThisWeek, parseISO } from 'date-fns';


function Messages() {
    const [assessments, setAssessments] = useState([]);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [assessmentsPerPage] = useState(6);
    const [searchQuery, setSearchQuery] = useState('');
    const organizationId = sessionStorage.getItem("organizationid");
    const authorId = sessionStorage.getItem("authorid");
    const [id, setId] = useState(null);
    const location = useLocation();
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get("id");
      setId(id); // Set the ID value in the state variable
      if(id){
        fetchChats(id); // Fetch Industries data based on the ID
      } else {
        fetchIssues();
      }
      
    }, [location.search]);
    const fetchIssues = useCallback(async () => {
        try {
            const role = sessionStorage.getItem("roleslug");

            // Fetch assessments with applied filters
            const response = await get('messages');

            // Filter the assessments based on role, organization, and author ID
            let filteredAssessments = response.response;
            if (role === 'superadmin') {
                filteredAssessments = filteredAssessments;
            } 
            // else if (role === 'organizationadmin'){
            //     filteredAssessments = filteredAssessments.filter(assessment => 
            //         // assessment.role.slug !== 'superadmin' &&
            //         assessment.sender && assessment.sender.organization === organizationId 
            //     );
            // } 
            else {
                filteredAssessments = filteredAssessments.filter(assessment => 
                    // assessment.role.slug !== 'superadmin' &&
                    assessment.sender && assessment.sender.organization === organizationId &&
                    (assessment.users.some(user => user.id === authorId) || assessment.sender.id === authorId)
                );
            }
            
            // Update state with filtered assessments
            setAssessments(filteredAssessments);
            setFilteredAssessments(filteredAssessments);
        } catch (error) {
            console.error('Error fetching Assessments:', error);
        }
    }, []);

    const fetchChats = async(id) => {
        try {
            // Fetch assessments with applied filters
            const response = await get('messages');

            // Filter the assessments based on role, organization, and author ID
            let filteredAssessments = response.response;
           
                filteredAssessments = filteredAssessments.filter(assessment => 
                    assessment.sender && assessment.sender.organization === organizationId &&
                    (assessment.users.some(user => user.id === id) || assessment.sender.id === id)
                );        
        
            
            // Update state with filtered assessments
            setAssessments(filteredAssessments);
            setFilteredAssessments(filteredAssessments);
        } catch (error) {
            console.error('Error fetching Assessments:', error);
        }
    }
    const formatCreatedAt = (dateString) => {
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                throw new Error("Invalid date");
            }
      
            if (isToday(date)) {
                return format(date, 'HH:mm'); // Show time without seconds if the date is today
            }
      
            if (isThisWeek(date)) {
                return format(date, 'EEEE, MMM d'); // Show week day and date if the date is in the same week
            }
      
            return format(date, 'EEE, MMM d'); // Show date and week day for other dates
        } catch (error) {
            console.error("Error parsing date:", error);
            return "Invalid date"; // Fallback for invalid dates
        }
      };
    // Filter assessments based on search query
    const handleSearch = () => {
        filterAssessments(searchQuery);
    };
    const handleChange = (e) => {
        setSearchQuery(e.target.value);
        filterAssessments(e.target.value);
    };

    const filterAssessments = (query) => {
        const filteredChats = assessments.filter(chat =>
            (chat.issue_name && chat.issue_name.toLowerCase().includes(query.toLowerCase())) ||
            (chat.sender && chat.sender.username.toLowerCase().includes(query.toLowerCase()))
        );
        setFilteredAssessments(filteredChats);
        setCurrentPage(1);
    };

    const indexOfLastChat = currentPage * assessmentsPerPage;
    const indexOfFirstChat = indexOfLastChat - assessmentsPerPage;
    const currentChats = filteredAssessments.slice(indexOfFirstChat, indexOfLastChat);

    async function handleDelete(id) {
        try {
            await del(`messages/${id}`);
            fetchIssues();
        } catch (error) {
            console.error('Error deleting Assessments:', error);
        }
    }

    const getPageNumbers = () => {
        const totalPages = Math.ceil(filteredAssessments.length / assessmentsPerPage);
        const pageNumbers = [];
        const maxVisiblePages = 5;

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const startPage = Math.max(1, currentPage - 2);
            const endPage = Math.min(totalPages, currentPage + 2);

            if (startPage > 1) pageNumbers.push(1, '...');
            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i);
            }
            if (endPage < totalPages) pageNumbers.push('...', totalPages);
        }
        return pageNumbers;
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < Math.ceil(filteredAssessments.length / assessmentsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <DashboardContent pageTitle="Messages">
            <div>
                <Row className='text-right my-5'>
                    <Col md={4} className="form-group">
                        <Form.Control 
                            type="text" 
                            name="name" 
                            value={searchQuery} 
                            onChange={handleChange} 
                            placeholder="Search ..." 
                            className="form-control" 
                        />
                    </Col>
                    <Col md={{ span: 3, offset: 5 }}>
                        <Button type="button" onClick={() => window.location = '/createmessage'} className="fw-btn-fill btn-gradient-yellow">Send a New Message</Button>
                    </Col>
                </Row>
                <Row>
                    {currentChats.map((chat, index) => (
                        <Col key={index} md={8} className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>{chat.issue_name}</Card.Title>
                                    <p>From - {chat.sender.username}</p>
                                    <p className="item-time">{formatCreatedAt(chat.updatedAt)}</p>
                                    {/* <Card.Subtitle className="mb-2 text-muted">Time - {chat.updatedAt ? chat.updatedAt :chat.createdAt}</Card.Subtitle> */}
                                    {/* <Card.Text dangerouslySetInnerHTML={{ __html: chat.description }}></Card.Text> */}
                                    <Button onClick={() => window.location = '/viewchat?id=' + chat.id} className="mr-2 secondary-btn">View</Button>
                                    <Button onClick={() => handleDelete(chat.id)} className='ms-4 py-3' variant="danger">Delete</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <nav className='d-flex justify-content-end my-5 align-items-center'>
                    <Button
                        className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
                        onClick={handlePrevious}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <ul className="pagination">
                        {getPageNumbers().map((pageNumber, index) => (
                            <li
                                key={index}
                                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
                            >
                                {pageNumber === '...' ? (
                                    <span className="page-link">...</span>
                                ) : (
                                    <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                                )}
                            </li>
                        ))}
                    </ul>
                    <Button
                        className={`btn plain-btn ${currentPage === Math.ceil(filteredAssessments.length / assessmentsPerPage) ? 'disabled' : ''}`}
                        onClick={handleNext}
                        disabled={currentPage === Math.ceil(filteredAssessments.length / assessmentsPerPage)}
                    >
                        Next
                    </Button>
                </nav>
            </div>
        </DashboardContent>
    );
}

export default Messages;
