import React, { useEffect, useState } from "react";
import { get } from "./rest";
import DashboardContent from "./DashboardContent";
import { Card, CardBody, CardHeader, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SelectField from "./SelectField";

function StudentsProgress() {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [optionsStudents, setOptionsStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [lessonEventsByCourse, setLessonEventsByCourse] = useState({});
  const [courseEventsByCourse, setCourseEventsByCourse] = useState({});
  const [courseCompletedEventsByCourse, setCourseCompletedEventsByCourse] = useState({});

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const organizationid = sessionStorage.getItem("organizationid");
      const response = await get("users");
      const filteredStudents = response.response.filter(
        (student) =>
          student.role.slug === "student" &&
          student.organization &&
          student.organization.id === organizationid
      );
      const options = filteredStudents.map((student) => ({
        value: student.id,
        label: `${student.username} (${student.email})`,
      }));
      setOptionsStudents(options);
      setStudents(filteredStudents);
    } catch (error) {
      console.error("Error fetching Students options:", error);
    }
  };

  const fetchEvents = async (studentId) => {
    const organizationid = sessionStorage.getItem("organizationid");

    try {
      // Fetch lesson events
      const lessonResponse = await get("globalevents", {
        params: {
          type: "lessonevent",
          organization: organizationid,
          user: studentId,
        },
      });

      const lessonEvents = lessonResponse.response.filter(
        (event) => event.type === 'lessonevent'
      );

      // Organize lesson events by course ID and keep only the most recent one
      const lessonEventsByCourse = lessonEvents.reduce((acc, event) => {
        const courseId = event.course;
        if (!acc[courseId] || new Date(event.createdAt) > new Date(acc[courseId].createdAt)) {
          acc[courseId] = event;
        }
        return acc;
      }, {});

      setLessonEventsByCourse(lessonEventsByCourse);

      // Fetch course events
      const courseResponse = await get("globalevents", {
        params: {
          type: "courseevent",
          organization: organizationid,
          user: studentId,
        },
      });

      const courseEvents = courseResponse.response.filter(
        (event) => event.type === 'courseevent'
      );

      // Organize course events by course ID
      const courseEventsByCourse = courseEvents.reduce((acc, event) => {
        const courseId = event.course;
        if (!acc[courseId]) {
          acc[courseId] = [];
        }
        acc[courseId].push(event);
        return acc;
      }, {});

      setCourseEventsByCourse(courseEventsByCourse);

      // Fetch course completed events
      const completedResponse = await get("globalevents", {
        params: {
          type: "coursecompleted",
          organization: organizationid,
          user: studentId,
        },
      });

      const completedEvents = completedResponse.response.filter(
        (event) => event.type === 'coursecompleted'
      );

      // Organize course completed events by course ID
      const courseCompletedEventsByCourse = completedEvents.reduce((acc, event) => {
        const courseId = event.course;
        acc[courseId] = event;
        return acc;
      }, {});

      setCourseCompletedEventsByCourse(courseCompletedEventsByCourse);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleStudentSelect = async (selectedValue) => {
    const studentId = selectedValue;
    setSelectedStudent(studentId);

    try {
      const studentCourses = await get("usercourseenrollment");
      const courses = studentCourses.response.filter((course) => course.user.id === studentId);
      setCourses(courses);

      // Fetch events after courses are fetched
      fetchEvents(studentId);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  return (
    <DashboardContent pageTitle="Students Progress">
      <div>
        <Row>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Form.Group controlId="selectStudent" className="form-group">
              <Form.Label>Select Student:</Form.Label>
              <SelectField
                options={optionsStudents}
                onChange={handleStudentSelect}
                placeholder="Select a student"
              />
            </Form.Group>
          </Col>
        </Row>

        {selectedStudent && (
          <>
            <div className="mt-5">
              <h3>
                Topics -{" "}
                {
                  students.find((student) => student.id === selectedStudent)
                    ?.firstname
                }
              </h3>
              <Row>
                {courses.length > 0 ? (
                  courses.map((course, index) => (
                    <Col key={index} md={4} className="my-5">
                      <Link to={`/lms?id=${course.course.id}`}>
                        <Card className="flex-fill">
                          <CardHeader className="p-0">
                            {course.course.price > 0 && (
                              <i className="fa-brands fa-square-web-awesome-stroke premimum-icon"></i>
                            )}
                            <img
                              src={course.course.thumbnail}
                              alt={course.course.course_title}
                              className="img-fluid"
                              height="150px"
                            />
                          </CardHeader>
                          <CardBody>
                            <h3>{course.course.course_title}</h3>
                            {/* Display course completion status */}
                            {courseCompletedEventsByCourse[course.course.id] ? (
                              <p>Progress: Course Completed</p>
                            ) : lessonEventsByCourse[course.course.id] ? (
                              <p>Progress: {lessonEventsByCourse[course.course.id].name}</p>
                            ) : (
                              <p>No progress recorded.</p>
                            )}
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  ))
                ) : (
                  <Col md={12} className="text-center my-5">
                    <p>No Topics taken.</p>
                  </Col>
                )}
              </Row>
            </div>
          </>
        )}
      </div>
    </DashboardContent>
  );
}

export default StudentsProgress;
