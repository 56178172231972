import { Button, Col, Form, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { del, get, post, put } from "./rest";
import QuillEditor from "./QuillEditor";

function WebsiteSetUp() {
  const [formData, setFormData] = useState({});
  const [phoneError, setPhoneError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [bannerURLs, setBannerURLs] = useState([]); // State to store array of banner URLs

  useEffect(() => {
    async function fetchData() {
      try {
        const organizationId = sessionStorage.getItem("organizationid");
        const response = await get('OrganizationWebsite');
        const data = response.response;
        const organizationData = data.find(org => org.organization && org.organization.id === organizationId);
        if (organizationData) {
          setFormData(organizationData);
        } else {
          throw new Error('Organization data not found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'phoneNumber') {
      // Remove any non-numeric characters
      newValue = value.replace(/\D/g, '');
    
      // Check if the original value contains any non-numeric characters
      if (value !== newValue) {
        setPhoneError('Phone number should contain only numbers.');
      } else if (newValue.length !== 10) {
        // Validate if the phone number has exactly 10 digits
        setPhoneError('Phone number must be 10 digits long.');
      } else {
        setPhoneError('');
      }
    
      // Restrict to 10 digits (this ensures newValue is always 10 digits)
      newValue = newValue.slice(0, 10);
    }


    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleEditorChange = (name, content) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: content,
    }));
  };

  const handleBannerChange = async (event) => {
    if ((formData.banners || []).length >= 10) {
      toast.warn("You cannot upload more than 10 banners.");
      return;
    }
    
    setUploading(true);
    const organizationid = sessionStorage.getItem("organizationid");
    const file = event.target.files[0];
    const uploadData = new FormData(); // Renamed to avoid conflict
    uploadData.append('files', file);
    uploadData.append('path', 'prod/default/website/' + organizationid + '/banners');
  
    try {
      const response = await fetch('http://api.campusplus360.in/upload', {
        method: 'POST',
        body: uploadData,
      });
      if (response.ok) {
        const data = await response.json();
        if (data.response.length > 0) {
          const urls = data.response.map(item => item); // Assuming item is the URL directly
          setFormData(prevFormData => ({
            ...prevFormData,
            banners: [...(prevFormData.banners || []), ...urls] // Ensure banners is an array before spreading
          }));
          toast.success('Banner uploaded successfully');
        }
      } else {
        toast.error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const roleid = sessionStorage.getItem("roleid");
      const roleslug = sessionStorage.getItem("roleslug");
      const organizationid = sessionStorage.getItem("organizationid");
      if (roleslug !== 'superadmin') {
        formData.organization = organizationid;
      }
     
      if (formData.id) {
        var id = formData.id;
        const updatedFormData = {
          ...formData,
          updatedBy : sessionStorage.getItem("authorid")
        };
        // If organization data is found, hit one API
        const responseData = await put("OrganizationWebsite/" + id, updatedFormData);
        if (responseData.statusCode === 200) {
          toast.success("Updated Successfully", {
            onClose: () => {
              window.location.href = "/websitesetup";
            },
          });
        }
      } else {
        const formDataWithAnswer = {
          ...formData,
          role: roleid,
          banners: bannerURLs,
          createdBy: sessionStorage.getItem("authorid")
        };
        // If organization data is not found, hit another API
        const responseData = await post("OrganizationWebsite", formDataWithAnswer);
        if (responseData.statusCode === 201) {
          toast.success("Submitted Successfully", {
            onClose: () => {
              window.location.href = "/websitesetup";
            },
          });
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDeleteBanner = (index) => {
    // Create a copy of the banners array
    const updatedBanners = [...(formData.banners || [])];
    // Remove the banner at the specified index
    updatedBanners.splice(index, 1);
    // Update the state with the new array of banners
    setFormData(prevState => ({
      ...prevState,
      banners: updatedBanners
    }));
    toast.success('Banner(s) removed successfully');
  };

  return (
    <DashboardContent pageTitle="Website Setup">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Website Set Up</h3>
                </div>
              </div>
              <div className="student-info hidecheckox">
                <Form onSubmit={handleSubmit} id="websitesetup" className="mt-5">
                  <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label>Title 1</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          placeholder="Enter Title 1"
                          name="title"
                          value={formData.title || ''}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label>Title 2</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          placeholder="Enter Title 2"
                          name="title2"
                          value={formData.title2 || ''}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group controlId="paragraph1">
                        <Form.Label>Paragraph 1</Form.Label>
                        <QuillEditor
                          name="paragraph1"
                          value={formData.paragraph1 || ''}
                          onChange={(content) =>
                            handleEditorChange("paragraph1", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group controlId="paragraph2">
                        <Form.Label>Paragraph 2</Form.Label>
                        <QuillEditor
                          name="paragraph2"
                          value={formData.paragraph2 || ''}
                          onChange={(content) =>
                            handleEditorChange("paragraph2", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>Email:</Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        value={formData.email || ''}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>Phone:</Form.Label>
                      <Form.Control
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        className={`form-control ${phoneError ? 'is-invalid' : ''}`}
                        value={formData.phoneNumber || ''}
                        onChange={handleChange}
                        required
                      />
                      {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label htmlFor="facebook">Facebook:</Form.Label>
                      <Form.Control
                        type="url"
                        id="facebook"
                        name="facebook"
                        className="form-control"
                        value={formData.facebook || ''}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label htmlFor="twitter">Twitter:</Form.Label>
                      <Form.Control
                        type="url"
                        id="twitter"
                        name="twitter"
                        className="form-control"
                        value={formData.twitter || ''}
                        onChange={handleChange}
                      />
                    </Col>
                    {/* <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label htmlFor="linkedin">Linkedin:</Form.Label>
                      <Form.Control
                        type="url"
                        id="linkedin"
                        name="linkedin"
                        className="form-control"
                        value={formData.linkedin || ''}
                        onChange={handleChange}
                      />
                    </Col> */}
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label htmlFor="youtube">Youtube:</Form.Label>
                      <Form.Control
                        type="url"
                        id="youtube"
                        name="youtube"
                        className="form-control"
                        value={formData.youtube || ''}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label htmlFor="instagram">Instagram:</Form.Label>
                      <Form.Control
                        type="url"
                        id="instagram"
                        name="instagram"
                        className="form-control"
                        value={formData.instagram || ''}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label >Banner:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                              <Form.Control
                                type="file"
                                id="imgfile"
                                className="image-upload mb-3"
                                accept="image/*"
                                onChange={handleBannerChange}
                              />
                          </div>
                          <div className="js--image-preview">
                            {formData.banners && formData.banners.length > 0 ? (
                              // Render banners if they exist in the response
                              formData.banners.map((banner, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                  <img src={banner.url} alt={`Banner ${index + 1}`} width={100} style={{ marginRight: '10px' }} />
                                  <button onClick={() => handleDeleteBanner(index)} className="secondary-btn"><i className="fa-regular fa-trash-can"></i></button>
                                </div>
                              ))
                            ) : (
                              // Render a message if no banners exist
                              <p>No banners uploaded</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Row>
                      <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                          {uploading ? 'Uploading...' : 'Website Set Up'}
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default WebsiteSetUp;
