import React from 'react';
import { post } from './rest';
import { Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

const RazorpayButton = ({ course, userDetails }) => {
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        const options = {
            key: 'rzp_test_VLnzWA84IIIUs4', // Replace with your Razorpay key ID
            amount: course.price * 100, // Razorpay accepts amount in paise (smallest currency unit)
            currency: 'INR',
            name: userDetails.organization.adminOrganization,
            description: 'Course Enroll',
            handler: async function (response) {
                toast.success(`Payment successful! Razorpay Payment ID: ${response.razorpay_payment_id}`);
                
                // Prepare the data to be sent to the transactions API
                const transactionData = {
                    transactionId: response.razorpay_payment_id,
                    amount: course.price,
                    course: course.id,
                    user: userDetails.id, // Assuming you have a userId in userDetails
                };

                try {
                    const transactionRes = await post('/transactions', transactionData);
                    debugger;
                    if (transactionRes.statusCode === 201) {
                        debugger;
                        // Transaction recorded successfully, now enroll the user in the course
                        const enrollmentData = {
                            name: userDetails.firstname,
                            user: userDetails.id,
                            course: course.id,
                            organization: userDetails.organization.id,
                            transaction: transactionRes.response.transaction.id, // Assuming this is the correct reference
                            subscription: null, // Add subscription if applicable
                            courseCalendarTimeSlots: null // Add course calendar time slots if applicable
                        };
debugger;                
                        const enrollmentRes = await post('/usercourseenrollment', enrollmentData);

                        if (enrollmentRes.statusCode === 201) {
                            window.location.href = `/lms?id=${course.id}`;
                        } else {
                            throw new Error('Failed to enroll user in the course');
                        }
                    } else {
                        throw new Error('Failed to record transaction');
                    }
                } catch (error) {
                    console.error('Error recording transaction or enrolling user:', error);
                    alert('There was an error processing your request. Please contact support.');
                }
            },
            prefill: {
                name: userDetails.firstname,
                email: userDetails.email,
                contact: userDetails.phoneNumber,
            },
            notes: {
                address: userDetails.organization.adminaddress
            },
            theme: {
                color: '#3399cc'
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    return (
        <>
        <Button onClick={handlePayment} className='secondary-btn'>Pay with Razorpay</Button>
        <ToastContainer />
        </>
    );
};

export default RazorpayButton;
