import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { get, put } from "./rest";
import { useLocation } from "react-router-dom";
import QuillEditor from "./QuillEditor";
import SelectField from "./SelectField";

function EditShortAnswer() {
  const [formData, setFormData] = useState({
    question: "",
    course:null
  });

  const [id, setId] = useState(null);
  const location = useLocation();
  const [optionsCourses, setOptionsCourse] = useState([]);
  const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchAssessments(id);
  }, [location.search]);

  const fetchAssessments = async (id) => {
    try {
      const response = await get("assessment/" + id); // Define response only once
    //   const correctAnswer = response.response.correctanswer;
    //   setSelectedOption(correctAnswer); // Set the correct answer from API to selectedOption
      setFormData(response.response);
    } catch (error) {
      console.error("Error fetching Questions:", error);
    }
  };

  // useEffect(() => {
  //   fetchCourseOptions();
  // }, []);
  const fetchCourseOptionsselected = async () => {
    try {
      const response = await get('courses');
      const options = response.response.map(course => ({
        value: course.id,
        label: course.course_title,
        isSelected: course.id === formData.course.id
      }));
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  const fetchCourseOptions = async () => {
    try {
      const response = await get('courses');
      const options = response.response.map(course => ({
        value: course.id,
        label: course.course_title,
      }));
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  if(formData.course !== null){
    if(formData.course !== undefined){
      fetchCourseOptionsselected();
    }
    else{
      fetchCourseOptions();
    }
  } else {
    fetchCourseOptions();
  }
  const handleChangeCourses = (selectedOptionCourses) => {
    setSelectedOptionCourses(selectedOptionCourses);
    setFormData(prevState => ({
      ...prevState,
      course: selectedOptionCourses,
      courseid: selectedOptionCourses
    }));
    debugger;
  };
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseData = await put("assessment/" + id, formData);
      debugger;
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/questionbank";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <DashboardContent pageTitle="Edit Question">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Edit Question </h3>
                </div>
              </div>
              <div className="student-info hidecheckox">
                <Form
                  onSubmit={handleSubmit}
                  id="editQuestion"
                  className="mt-5"
                >
                  <Row>
                    <Col md={12} className="form-group">
                    <Form.Group controlId="question">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="question"
                      value={formData.question}
                      placeholder="Enter the short answer question"
                      onChange={handleChange}
                    />
                  </Form.Group>
                    </Col>
                    {
                      (formData.course !== undefined && formData.course !== null) ? (
                        <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                          <Form.Label>Course:</Form.Label>
                          <SelectField options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses} selected={formData.course.id} />
                        </Col>
                      ) : (
                        <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                          <Form.Label>Course:</Form.Label>
                          <SelectField options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses}/>
                        </Col>
                      )
                    }
                    </Row>
                    <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Button
                        type="submit"
                        className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      >
                        Update Question
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditShortAnswer;
