import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectField from "./SelectField";
import { ToastContainer, toast } from "react-toastify";
import YearPicker from "./YearPicker";
import { get, put } from "./rest";
import { useLocation } from "react-router-dom";

function EditDefaultCourse() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsIndustries, setOptionsIndustries] = useState([]);
  const [selectedOptionIndustries, setSelectedOptionIndustries] =
    useState(null);

  const [formData, setFormData] = useState({
    course_title: "",
    category: "",
    price: "",
    course_description: "",
    year: "",
    thumbnail: "",
    courseCluster:"",
    role:""
  });

  const [id, setId] = useState(null);
  const location = useLocation();
  const [DefaultCourse, setDefaultCourse] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [creating, setCreating] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchDefaultCourse(id);
  }, [location.search]);
  useEffect(() => {
    fetchIndustriesOptions();
  }, []);
  const fetchIndustriesOptions = async () => {
    try {
      const response = await get("categories");
      debugger;
      const options = response.response.map((category) => ({
        value: category.id, // Assuming _id is unique and can be used as the value
        label: category.industry, // Using the role property as the label
        isSelected: category.id === formData.category.id
      }));
      setOptionsIndustries(options); // Set optionsIndustries directly from the response
    } catch (error) {
      console.error("Error fetching Industries options:", error);
    }
  };
  const fetchDefaultCourse = async (id) => {
    try {
      get("courses/" + id)
        .then((response) => {
          debugger;
          setDefaultCourse(response.response);
          setFormData(response.response);
        })
        .catch((error) => console.error("Error fetching data:", error));
    } catch (error) {
      console.error("Error fetching DefaultCourse:", error);
    }
  };
  // const handleChange = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  // };
 
  const handleChangeIndustries = (selectedOptionIndustries) => {
    setSelectedOptionIndustries(selectedOptionIndustries);
    setFormData((prevState) => ({
      ...prevState,
      category: selectedOptionIndustries,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleYearChange = (year) => {
    setSelectedYear(year);
    setFormData((prevState) => ({
      ...prevState,
      year: year, // Set selectedYear in formData
    }));
  };
  const handleThumbnailChange = async (event) => {
    setUploading(true);
    
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'prod/default/courses/thumbnail'); 
  
    try {
        debugger;
        const response = await fetch('http://api.campusplus360.in/upload', {
            method: 'POST',
            body: formData,
        }); 
        if (response.status === 201) { // Checking for response.ok instead of statusCode === 200
            const data = await response.json(); // Assuming the response is in JSON format
            debugger;
            if (data.response.length > 0) {
              const firstObject = data.response[0]; // Accessing the first object in the array
              const url = firstObject.url; // Assuming the URL key is 'url' in the object
              setThumbnailURL(url); // Set the thumbnail URL
              setFormData(prevState => ({
                ...prevState,
                thumbnail: url
              }));
              toast.success('Image Uploaded successfully');
            } 
        } else {
            toast.error('Failed to upload image');
        }
    } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Failed to upload image');
    } finally {
      setUploading(false); // Set uploading to false when upload completes
    }
};
const roleid = sessionStorage.getItem("roleid");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);

    try {
      const updatedFormData = {
        ...formData,
        thumbnailURL: thumbnailURL || formData.thumbnail,
        role:roleid || formData.role,
        updatedBy : sessionStorage.getItem("authorid")
      };

      const responseData = await put("courses/" + id, updatedFormData);
      debugger;
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/course";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setCreating(false);
    }
  };

  return (
    <DashboardContent pageTitle="Default Course">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Edit Default Course</h3>
                </div>
              </div>
              <div className="student-info">
                <Form onSubmit={handleSubmit} id="editCourse" className="mt-5">
                  <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >
                        Course Title:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="course_title"
                        name="course_title"
                        onChange={handleChange}
                        value={formData.course_title}
                        className="form-control"
                        required
                      />
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Categories:</Form.Label>
                      <SelectField
                        options={optionsIndustries}
                        onChange={handleChangeIndustries}
                        selected={formData.category.id}
                      />
                    </Col>
                    </Row>
                    <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Image:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                              <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" onChange={handleThumbnailChange}  />
                            <img src={formData.thumbnail} alt="thumnail" width={100}></img>

                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Price:</Form.Label>
                      <Form.Control
                        type="number"
                        id="price"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label >
                        Course Summary:
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        id="course_description"
                        name="course_description"
                        onChange={handleChange}
                        value={formData.course_description}
                        className="form-control"
                        required
                      />
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label >Year:</Form.Label>
                      <br />
                      <YearPicker
                        selected={formData.year}
                        onChange={handleYearChange}
                      />
                    </Col>
                    </Row>
                    <Row>
                    {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Upload Video:</Form.Label>
                    <Form.Control 
                      type="file" 
                      accept="video/*" 
                      onChange={handleFileChange} 
                    />
                    </Col> */}
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        {uploading ? 'Uploading...' : 'Update Course'}
                        {/* {creating ? 'Updating' : ''} */}
                      </Button>
                      <p>{creating ? 'Updating' : ''}</p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditDefaultCourse;
