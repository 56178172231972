import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import DashboardContent from './DashboardContent';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import SelectField from './SelectField';
import { del, get, post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

const CourseCalendar = () => {
  const [events, setEvents] = useState([]);
  const [content, setContent] = useState(null);
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    fetchCourseCalendar();
  }, []);
  const fetchCourseCalendar = async () => {
    try {
      const response = await get('coursecalendar');
      const formattedEvents = response.response.filter(item => item.organization.id === organizationid || !item.organization)
      .map(item => ({
        id: item._id,
        title: `${item.course.course_title}`,
        start: new Date(item.start_date),
        end: new Date(item.end_date),
        slots: item.slots.map(slot => ({
          startTime: slot.startTime, // Extract startTime from slot
          endTime: slot.endTime,     // Extract endTime from slot
        }))
      }));
  
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching Course Calendar:', error);
    }
  };
  
  const onClickDay = (date) => {
    // Filter events based on the clicked date
    const eventsOnClickedDate = events.filter(event =>
      moment(date).isBetween(moment(event.start).startOf('day'), moment(event.end).endOf('day'), null, '[]')
    );
  
    console.log('Clicked date:', date);
    console.log('Events on this day:', eventsOnClickedDate);
    debugger;
  
    const content = eventsOnClickedDate.map(event => {
      const formattedStart = moment(event.start).format('MMMM Do YYYY');
      const formattedEnd = moment(event.end).format('MMMM Do YYYY');
      const displayDate = formattedStart === formattedEnd ? formattedStart : `${formattedStart} - ${formattedEnd}`;
      
      return (
        <Card key={event.id} className="mt-3">
          <Card.Body>
            <Card.Title>
              <h5>Course: {event.title}</h5>
            </Card.Title>
            {event.slots.map(slot => (
              <Card.Text key={`${event.id}-${slot.start}`}>
                <p>Date(s): {displayDate}</p>
                <p>Time Slot: {slot.startTime} - {slot.endTime}</p>
              </Card.Text>
            ))}
            <Row>
              <Col>
                <Button onClick={() => window.location='/editcoursecalendar?id=' + event.id} className='mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark'>Edit</Button>
              </Col>
              <Col>
                <Button onClick={() => handleDeleteCalendar(event.id)} className='mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark'>Delete Course From Calendar</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    });
  
    setShowContent(true);
    setContent(content);
  };
  
  
  const hideContent = () => {
    setShowContent(false);
  };
  
  async function handleDeleteCalendar(id) {
    try {
      await del(`coursecalendar/${id}`);
      // Refresh after deletion
      fetchCourseCalendar();
    } catch (error) {
      console.error('Error deleting Units:', error);
    }
  }

  const [courseData, setCourseData] = useState({
    start_date: null,
    end_date: null,
    course: '',
    price: '',
    slots: [],
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsCourses, setOptionsCourse] = useState([]);
  const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);
  var organizationid = sessionStorage.getItem("organizationid");

  useEffect(() => {
    fetchCourseOptions();
  }, []);
  const fetchCourseOptions = async () => {
    try {
      const response = await get('courses');
      const options = response.response.filter(course => course.organization === organizationid || !course.organization)
      .map(course => ({
        value: course.id,
        label: course.course_title
      }));
      debugger;
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  const handleChangeCourses = (selectedOptionCourses) => {
    setSelectedOptionCourses(selectedOptionCourses);
    setCourseData(prevState => ({
      ...prevState,
      course: selectedOptionCourses
    }));
    debugger;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleAddSlot = () => {
    
    const newSlot = {
      startTime: '09:00', 
      endTime: '10:00',   
    };
    setCourseData({ ...courseData, slots: [...courseData.slots, newSlot] });
  };

  const handleSlotChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSlots = [...courseData.slots];
    updatedSlots[index] = { ...updatedSlots[index], [name]: value };
    setCourseData({ ...courseData, slots: updatedSlots });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var organizationid = sessionStorage.getItem("organizationid");
      const authorid = sessionStorage.getItem("authorid");
      courseData.organization = organizationid;
      courseData.created_user = authorid;
      // Submit course calendar data
      const calendarResponse = await post('coursecalendar', courseData);
      const course_calendar = calendarResponse.response.courseCalendar._id; 
  
      // Submit time slots associated with the created calendar
      await Promise.all(courseData.slots.map(slot => {
        const slotData = {          
          start_time: slot.startTime,
          end_time: slot.endTime,
          course_calendar: course_calendar,
          created_user: sessionStorage.getItem("authorid"), 
          createdBy: sessionStorage.getItem("authorid"), 
        };
        return post('coursecalendartimeslot/bulk-insert', slotData);
      }));
  
      // Show success toast
      toast.success('Form submitted successfully', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose:() =>{          
          window.location.reload();
      }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      // Optionally, you can show an error toast here if the submission fails
    }
  };
  

  const handleDateChange = (field, date) => {
    setCourseData({ ...courseData, [field]: date });
  };

  

  return (
    <DashboardContent pageTitle="Course Calendar">
        <div className="card dashboard-card-ten">
            <div className="card-body">
        <Form onSubmit={handleSubmit} className="my-5">
          <Row>
          <Col md={4} xl={4} lg={4} xs={12} className="form-group">
            <Form.Label>Start Date:</Form.Label>
            <Form.Control type="date" name="start_date" className="form-control" value={courseData.start_date} onChange={(e) => handleDateChange('start_date', e.target.value)} required />
          </Col>
          <Col md={4} xl={4} lg={4} xs={12} className="form-group">
            <Form.Label>End Date:</Form.Label>
            <Form.Control type="date" name="end_date" className="form-control" value={courseData.end_date} onChange={(e) => handleDateChange('end_date', e.target.value)} min={courseData.start_date} required/>
          </Col>
          </Row>
                  <Row>
          <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                        <Form.Label>Course:</Form.Label>
                        <SelectField options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses} required/>
                      </Col>
          <Col md={4} xl={4} lg={4} xs={12} className="form-group">
            <Form.Label>Price:</Form.Label>
            <Form.Control type="text" name="price" className="form-control" value={courseData.price} onChange={handleInputChange} required/>
          </Col>
          {courseData.slots.map((slot, index) => (
            <div key={index}>
              <Row>
              <h3 className='mt-4'>Time Slots:</h3>

              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                <Form.Label>Start Time:</Form.Label>
                <Form.Control type="time" name="startTime" className="form-control" value={slot.startTime} onChange={(e) => handleSlotChange(index, e)} />            
              </Col>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                <Form.Label>End Time:</Form.Label>
                <Form.Control type="time" name="endTime" className="form-control" value={slot.endTime} onChange={(e) => handleSlotChange(index, e)} />            
              </Col>
              </Row>
            </div>
          ))}
          </Row>
          <Row>
              <Col md={6}>
                  <Row>
                      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                        <Button type="button" className="mt-5 plain-btn" onClick={handleAddSlot}>Add Time Slot *</Button>
                      </Col>
                      </Row>
                  <Row>
                      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                        <Button type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit</Button>
                      </Col>
                  </Row>
              </Col>            
          </Row>
        </Form>
        
        <Calendar
          tileContent={({ date, view }) => {
            if (view === 'month') {
              const eventsOnDate = events.filter(event => {
                const isInRange = moment(date).isBetween(event.start, event.end, null, '[]');
                const isStartDate = moment(date).isSame(event.start, 'day');
                const isEndDate = moment(date).isSame(event.end, 'day');
                return isInRange || isStartDate || isEndDate;
              });

              const uniqueCourses = [...new Set(eventsOnDate.map(event => event.title))];

              return uniqueCourses.length > 0 && (
                <div className="course-container">
                  {uniqueCourses.map((course, index) => (
                    <div key={index} className="course-box">
                      <span className="course-name">{course}</span>
                    </div>
                  ))}
                </div>
              );
            } 
          }}
          onClickDay={onClickDay}
          onClickOutside={hideContent} // Hide content when user clicks outside the calendar
          onActiveStartDateChange={hideContent} // Hide content when the active start date changes
          onViewChange={hideContent} 
        />
        {showContent && <div className="my-5">{content}</div>}
      </div>
      </div>
      <ToastContainer />
    </DashboardContent>
  );
};

export default CourseCalendar;
