import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import { get, post, put } from './rest';
import DashboardContent from './DashboardContent';
import QuillEditor from './QuillEditor';

function EditResultModal() {
  const [detailedAssessments, setDetailedAssessments] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [correctPercentage, setCorrectPercentage] = useState(0);
  const [hasFillInTheBlanks, setHasFillInTheBlanks] = useState(false);
  const [assessmentTitle, setAssessmentTitle] = useState('');
  const [assessmentReportId, setAssessmentReportId] = useState(null);

  const [formData, setFormData] = useState({
    answers: [],
    assessment: '',
    correctPercentage: ''
  });
  const [id, setId] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [timer, setTimer] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const timerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const retake = searchParams.get('retake');
    setId(id);
    
    if(retake === "retake"){
      fetchAssessments(id);
    } else {
      getAssessmentReport(id);
    }
  }, [location.search]);
  const authorId = sessionStorage.getItem("authorid");

  const fetchAssessments = async (assessmentQuizId) => {
    try {
      const response = await get(`assessment?assessmentQuiz=${assessmentQuizId}`);
      if (response.response) {
        setDetailedAssessments(response.response);
        if (response.response[0]?.assessmentQuiz?.timer) {
          setTimer(response.response[0].assessmentQuiz.timer);
        }
      } else {
        setDetailedAssessments([]);
      }
    } catch (error) {
      console.error('Error fetching Assessments:', error);
    }
  };

  const getAssessmentReport = async (id) => {
    try {
      debugger;
      const response = await get(`assessmentreports/${id}`);
      const report = response.response;
      fetchAssessments(report.assessment._id);
      setFormData(report);
      setAssessmentReportId(id);
      setAssessmentTitle(report.assessment.title);
      initializeSelectedOptions(report.answers);
      setCorrectPercentage(report.correctPercentage || 0);
    } catch (error) {
      console.error('Error fetching assessment report:', error);
    }
  };

  const initializeSelectedOptions = (answers) => {
    const options = {};
    const answered = {};

    answers.forEach((answer) => {
      options[answer.questionId] = answer.selectedOption;
      answered[answer.questionId] = answer.selectedOption.length > 0;
    });

    setSelectedOptions(options);
    setAnsweredQuestions(answered);
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleOptionChange = (questionId, key, value, questionType) => {
    debugger
    setSelectedOptions((prev) => {
      const currentOptions = prev[questionId] || [];

      let newOptions;
      if (questionType === 'multipleChoice') {
        // For multipleChoice, only keep the latest selected option
        newOptions = [key];
      } else if (questionType === 'chooseTheCorrectAnswer') {
        // For chooseTheCorrectAnswer, allow multiple selected options
        newOptions = currentOptions.includes(key)
          ? currentOptions.filter(option => option !== key)
          : [...currentOptions, key];
      } else if (questionType === 'fillInTheBlanks') {
        // For fillInTheBlanks, just store the input value
        newOptions = [value];
      }

      // Mark question as answered
      setAnsweredQuestions((prevAnswered) => ({
        ...prevAnswered,
        [questionId]: newOptions.length > 0,
      }));

      return {
        ...prev,
        [questionId]: newOptions,
      };
    });
  };

  const submitAnswers = async (status) => {
    const allAnswersData = [];
    let totalQuestions = 0;
    let correctAnswersCount = 0;
    let hasFillInTheBlanks = false;

    detailedAssessments.forEach((assessment) => {
        const questionId = assessment._id;
        const selectedOption = selectedOptions[questionId] || [];
        let isCorrect = false;

        if (assessment.type === 'chooseTheCorrectAnswer') {
            const correctAnswer = assessment.correctanswer;
            if (Array.isArray(correctAnswer)) {
                isCorrect =
                    selectedOption
                        .map((opt) => capitalizeFirstLetter(opt))
                        .sort()
                        .toString() ===
                    correctAnswer
                        .map((ans) => capitalizeFirstLetter(ans))
                        .sort()
                        .toString();
            } else {
                isCorrect =
                    capitalizeFirstLetter(selectedOption[0]) ===
                    capitalizeFirstLetter(correctAnswer);
            }
        } else if (assessment.type === 'multipleChoice') {
            const correctAnswer = assessment.correct_answer;

            if (Array.isArray(correctAnswer)) {
                isCorrect =
                    selectedOption
                        .map((opt) => capitalizeFirstLetter(opt))
                        .sort()
                        .toString() ===
                    correctAnswer
                        .map((ans) => capitalizeFirstLetter(ans))
                        .sort()
                        .toString();
            } else {
                isCorrect =
                    capitalizeFirstLetter(selectedOption[0]) ===
                    capitalizeFirstLetter(correctAnswer);
            }
        } else if (assessment.type === 'fillInTheBlanks') {
            hasFillInTheBlanks = true;
        }

        if (assessment.type !== 'fillInTheBlanks') {
            totalQuestions++;
            if (isCorrect) {
                correctAnswersCount++;
            }
        }

        allAnswersData.push({
            questionId: assessment._id,
            questionTitle: assessment.question,
            selectedOption,
            questionType: assessment.type,
            isCorrect: assessment.type === 'fillInTheBlanks' ? null : isCorrect,
        });
    });

    const correctPercentage = hasFillInTheBlanks ? null : (correctAnswersCount / totalQuestions) * 100;

    const payload = {
        answers: allAnswersData,
        assessmentId: formData.assessment.id,
        user: sessionStorage.getItem('authorid'),
        organization: sessionStorage.getItem('organizationid'),
        correctPercentage,
        submissionStatus: status,
    };

    const checkExistingReport = async (userId, assessmentId) => {
          try {
            // Fetch all assessment reports
            const response = await get(`assessmentreports`);
            console.log("authorid", userId);
            console.log("assessment", assessmentId);
            // Filter the reports based on userId and assessmentId
            const filteredReports = response.response.filter(report => report.user && report.user.id === userId && report.assessment && report.assessment.id === assessmentId);
            console.log("checking response",filteredReports);
            if (filteredReports.length > 0 && filteredReports[0]._id) {
              setAssessmentReportId(filteredReports[0]._id);
            } else {
                console.warn('No valid report found or _id is missing');
            }
            console.log(assessmentReportId);
            // Return the first matching report if exists, otherwise return null
            return filteredReports.length > 0 ? filteredReports[0] : null;
            
          } catch (error) {
            console.error("Error checking existing report:", error);
            return null;
          }
        };
        try {
          let response = ''
          if(!assessmentReportId){
            const exists = await checkExistingReport(authorId, id);
            const assessid = exists._id;
             response = exists ? await put(`assessmentreports/${assessid}`, payload) : await put('assessmentreports', payload);
          }
          else {
            response = await put(`assessmentreports/${assessmentReportId}`, payload);
          }
    
          // const response = await put(`assessmentreports/${id}`, payload);
          console.log('Updated response:', response);
          if (response.statusCode === 201) {
            toast.success('Assessment Submitted successfully!', {
              onClose: () => {
                window.location.href = '/results';
              },
            });
          }  else if (response.statusCode === 200) {
            if(status === 'saved'){
              toast.success('Answers saved successfully for this assessment!', {
                onClose: () => {
                  window.location.href = '/results';
                }
              });
            } else{
              toast.success('Answers submitted successfully for this assessment!', {
                onClose: () => {
                  window.location.href = '/results'
                }
              });
            }
          }  else {
            toast.error('Failed to update assessment.');
          }
        } catch (error) {
          console.error('Error updating assessment:', error);
          toast.error('Error updating assessment.');
        }
};

  // const submitAnswers = async (status) => {
  //   const allAnswersData = [];
  //   let totalQuestions = 0;
  //   let correctAnswersCount = 0;
  //   let hasFillInTheBlanks = false;

  //   detailedAssessments.forEach((assessment) => {
  //     const questionId = assessment._id;
  //     const selectedOption = selectedOptions[questionId] || [];
  //     const correctAnswer = correctAnswers[questionId];
  //     let isCorrect = false;

  //     if (assessment.type === 'chooseTheCorrectAnswer') {
  //       if (Array.isArray(correctAnswer)) {
  //         isCorrect =
  //           selectedOption
  //             .map((opt) => capitalizeFirstLetter(opt))
  //             .sort()
  //             .toString() ===
  //           correctAnswer
  //             .map((ans) => capitalizeFirstLetter(ans))
  //             .sort()
  //             .toString();
  //       } else {
  //         isCorrect =
  //           capitalizeFirstLetter(selectedOption[0]) ===
  //           capitalizeFirstLetter(correctAnswer);
  //       }
  //     } else if (assessment.type === 'multipleChoice') {
  //       isCorrect =
  //         capitalizeFirstLetter(selectedOption[0]) ===
  //         capitalizeFirstLetter(correctAnswer);
  //     } else if (assessment.type === 'fillInTheBlanks') {
  //       hasFillInTheBlanks = true;
  //     }

  //     if (assessment.type !== 'fillInTheBlanks') {
  //       totalQuestions++;
  //       if (isCorrect) {
  //         correctAnswersCount++;
  //       }
  //     }

  //     allAnswersData.push({
  //       questionId: assessment._id,
  //       questionTitle: assessment.question,
  //       selectedOption,
  //       questionType: assessment.type,
  //       isCorrect: assessment.type === 'fillInTheBlanks' ? null : isCorrect,
  //     });
  //   });
  //   debugger
  //   const payload = {
  //     answers: allAnswersData,
  //     assessmentId: formData.assessment.id,
  //     user: sessionStorage.getItem('authorid'),
  //     organization: sessionStorage.getItem('organizationid'),
  //     correctPercentage: hasFillInTheBlanks ? null : (correctAnswersCount / totalQuestions) * 100,
  //     submissionStatus: status,
  //   };
  //   const checkExistingReport = async (userId, assessmentId) => {
  //     try {
  //       // Fetch all assessment reports
  //       const response = await get(`assessmentreports`);
  //       console.log("authorid", userId);
  //       console.log("assessment", assessmentId);
  //       // Filter the reports based on userId and assessmentId
  //       const filteredReports = response.response.filter(report => report.user && report.user.id === userId && report.assessment && report.assessment.id === assessmentId);
  //       console.log("checking response",filteredReports);
  //       if (filteredReports.length > 0 && filteredReports[0]._id) {
  //         setAssessmentReportId(filteredReports[0]._id);
  //       } else {
  //           console.warn('No valid report found or _id is missing');
  //       }
  //       console.log(assessmentReportId);
  //       // Return the first matching report if exists, otherwise return null
  //       return filteredReports.length > 0 ? filteredReports[0] : null;
        
  //     } catch (error) {
  //       console.error("Error checking existing report:", error);
  //       return null;
  //     }
  //   };
  //   try {
  //     let response = ''
  //     if(!assessmentReportId){
  //       const exists = await checkExistingReport(authorId, id);
  //       const assessid = exists._id;
  //        response = exists ? await put(`assessmentreports/${assessid}`, payload) : await put('assessmentreports', payload);
  //     }
  //     else {
  //       response = await put(`assessmentreports/${assessmentReportId}`, payload);
  //     }

  //     // const response = await put(`assessmentreports/${id}`, payload);
  //     console.log('Updated response:', response);
  //     if (response.statusCode === 201) {
  //       toast.success('Assessment Submitted successfully!', {
  //         onClose: () => {
  //           window.location.href = '/results';
  //         },
  //       });
  //     }  else if (response.statusCode === 200) {
  //       if(status === 'saved'){
  //         toast.success('Answers saved successfully for this assessment!', {
  //           onClose: () => {
  //             window.location.href = '/results';
  //           }
  //         });
  //       } else{
  //         toast.success('Answers submitted successfully for this assessment!', {
  //           onClose: () => {
  //             window.location.href = '/results'
  //           }
  //         });
  //       }
  //     }  else {
  //       toast.error('Failed to update assessment.');
  //     }
  //   } catch (error) {
  //     console.error('Error updating assessment:', error);
  //     toast.error('Error updating assessment.');
  //   }
  // };

  const startQuiz = () => {
    if (timer) {
      setTimeLeft(timer * 60);

      timerRef.current = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 0) {
            clearInterval(timerRef.current);
            submitAnswers();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (timeLeft === 0 && timer) {
      submitAnswers();
    }
  }, [timeLeft, timer]);

  useEffect(() => {
    if (detailedAssessments.length > 0 && timer) {
      startQuiz();
    }
  }, [detailedAssessments, timer]);

  const renderDetail = (assessment) => {
    return (
      <div key={assessment._id} id={`question-${assessment._id}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(assessment.question),
          }}
        ></div>

        {assessment.type === 'multipleChoice' && (
          <div>
            {['answer1', 'answer2', 'answer3', 'answer4'].map((answer) => (
              <div key={answer} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  type="radio"
                  value={assessment[answer]}
                  checked={selectedOptions[assessment._id]?.includes(answer)}
                  onChange={() =>
                    handleOptionChange(assessment._id, answer, assessment[answer], 'multipleChoice')
                  }
                  style={{ marginRight: '10px' }}
                />
                <label
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(assessment[answer]),
                  }}
                  className='m-0'
                ></label>
              </div>
            ))}
          </div>
        )}
        {assessment.type === 'chooseTheCorrectAnswer' && (
          <div >
            {['answer1', 'answer2', 'answer3', 'answer4'].map((answer) => (
              <div key={answer} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={assessment[answer]}
                  checked={selectedOptions[assessment._id]?.includes(answer)}
                  onChange={() =>
                    handleOptionChange(assessment._id, answer, assessment[answer], 'chooseTheCorrectAnswer')
                  }
                  style={{ marginRight: '10px' }}
                />
                <label
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(assessment[answer]),
                  }}
                ></label>
              </div>
            ))}
          </div>
        )}
        {assessment.type === 'fillInTheBlanks' && (
          
          <div >
            <p className="mt-4">
              Answer:{' '}
              <span>
              <QuillEditor
                          value={selectedOptions[assessment._id]?.[0] || ''}
                          onChange={(content) =>
                            handleOptionChange(assessment._id, content, content, 'fillInTheBlanks')
                          }
                        />
              </span>
            </p>
          </div>
        )}
      </div>
    );
  };

  const scrollToQuestion = (questionId) => {
    const questionElement = document.getElementById(questionId);
    if (questionElement) {
      questionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const answeredCount = Object.values(answeredQuestions).filter((answered) => answered).length;

  return (
    <DashboardContent pageTitle="Update your Response">
      <Card className="p-5">
        <h3 className="mb-5">Assessment: {assessmentTitle}</h3>
        <div className="d-flex justify-content-between mb-3">

          {timeLeft !== null && timer && (
            <div className="timer">
              <h4>Time Left: {formatTime(timeLeft)}</h4>
            </div>
          )}
        </div>
        <Row>
          <Col md={8}>
            {detailedAssessments.map((assessment) => (
              <div key={assessment._id}>{renderDetail(assessment)}</div>
            ))}
          </Col>
          <Col md={4}>
            <h4>Navigate to Question</h4>
            <div>
            Answered Questions: {answeredCount} / {detailedAssessments.length}
          </div>
            <div className="p-3 sticky">
              {detailedAssessments.map((detail, detailIndex) => (
                <div
                  className="px-3 py-2 m-1"
                  key={`question-index-${detailIndex}`}
                  onClick={() => scrollToQuestion(`question-${detail._id}`)}
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    backgroundColor: answeredQuestions[detail._id] ? '#fff2d8' : '#fff',
                    border: '1px solid #ccc',
                  }}
                >
                  {detailIndex + 1}
                </div>
              ))}
            </div>
          </Col>
        </Row>

        <div className="mt-5 d-flex justify-content-between">
          <Button onClick={() => submitAnswers('saved')} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Save Answers</Button>
          <Button onClick={() => submitAnswers('submitted')} className="ml-3 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit Answers</Button>
        </div>
        <ToastContainer />
      </Card>
    </DashboardContent>
  );
}

export default EditResultModal;
