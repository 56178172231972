import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { get, put } from "./rest";
import { ToastContainer, toast } from "react-toastify";

function EditChapter() {
  const [formData, setFormData] = useState({
    title: "",
  });
  const [creating, setCreating] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState('');
  const [id, setId] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id); // Set the ID value in the state variable
    fetchUnits(id); // Fetch Units data based on the ID
  }, [location.search]);
  const [Units, setUnits] = useState([]);

  const fetchUnits = async (id) => {
    try {
        const response = await get('coursechapter/' + id);
        setUnits(response.response);
      // Update form data with fetched organization data
      setFormData(response.response);
      
    } catch (error) {
      console.error("Error fetching Units:", error);
    }
  };
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);

    try {
      const updatedFormData = {
        ...formData,
        updatedBy : sessionStorage.getItem("authorid")
      };
      const responseData = await put("coursechapter/" + id, updatedFormData);
      setResponse(responseData);
      debugger;
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/Units";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setCreating(false);

    }
  };

  return (
    <DashboardContent pageTitle="Edit Unit">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Edit Unit</h3>
                </div>
              </div>
              <div className="student-info">
                <Form
                  onSubmit={handleSubmit}
                  id="EditChapter"
                  className="mt-5"
                >
                  <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label htmlFor="Unit">
                        Unit Title:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="unit"
                        name="title"
                        className="form-control"
                        value={formData.title}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    </Row>
                    <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Button type="submit" disabled={uploading} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        {uploading ? 'Uploading...' : 'Update Unit'}
                      </Button>
                      {/* <p>{creating ? 'Updating' : ''}</p> */}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditChapter;
