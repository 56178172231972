import React, { useState, useEffect } from "react";
import { del, get, post, put } from "./rest";
import DashboardContent from "./DashboardContent";
import { Button, Card, Col, Row } from "react-bootstrap";
import SelectField from "./SelectField";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";

function CourseCohort() {
  const [id, setId] = useState(null);
  const location = useLocation();

  const [courseCalendar, setCourseCalendar] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [paramStudent, setParamStudent] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [courseCalendarTimeSlots, setCourseCalendarTimeSlots] = useState([]);
  const organizationid = sessionStorage.getItem("organizationid");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchCourseCalendarTimeSlot();
    if (id) {
      fetchStudentsId(id);
      setSelectedStudent(id);
    } else {
      fetchStudents();
    }

  }, [location.search]);
  // Fetch course calendar data
  useEffect(() => {
    get("coursecalendar")
      .then((response) => {
        const options = response.response.filter(cour => cour.organization.id === organizationid).map(cour => {
          const startDate = cour.start_date;
          const endDate = cour.end_date;
          const dateLabel = startDate === endDate ? startDate : `${startDate} - ${endDate}`;
          return{
            value: cour.course.id,
            label: `${cour.course.course_title} (${dateLabel})`
          };
          });
          setCourseCalendar(options);
      })
      .catch((error) =>
        console.error("Error fetching course calendar:", error)
      );
  }, []);

  // useEffect(() => {

  //   if (id && students.length > 0) {
  //     debugger;
  //     const selectedStudentObj = students.find((student) => student.value === id);
  //     if (selectedStudentObj) {
  //       debugger;
  //       setSelectedStudent(selectedStudentObj);
  //       setParamStudent(selectedStudentObj);
  //     }
  //   }
  // }, [id, students]);
  // Fetch students data
  const fetchStudents = async () => {
    try {
      const response = await get("users");
      const studentsData = response.response.filter(
        (emp) => emp.role.slug === "student"
      );
      const options = studentsData.map(stu => ({
        value: stu.id,
        label: stu.username
      }));
      setStudents(options);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };
  const fetchStudentsId = async (idfromparam) => {
    try {
      const response = await get("users");
      const studentsData = response.response.filter(
        (emp) => emp.role.slug === "student"
      );
      const options = studentsData.map(stu => ({
        value: stu.id,
        label: stu.username,
        isSelected: stu.id === idfromparam
      }));
      setStudents(options);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };
  const fetchCourseCalendarTimeSlot = async () => {
    try {
        const response = await get("coursecalendartimeslot");
        setCourseCalendarTimeSlots(response.response);
    } catch (error) {
        console.error("Error fetching time slots:", error);
    }
};
  const fetchTimeSlots = async (courseselected) => {
    debugger;
    try {
      const response = await get("coursecalendartimeslot");
      
      const options = response.response.map(slot => {
        if (slot.course_calendar.course.id === courseselected) {
          return {
            value: slot._id,
            label: `${slot.start_time} - ${slot.end_time} (${slot.course_calendar.start_date} - ${slot.course_calendar.end_date})`
          };
        }
        return null; // or undefined
      }).filter(Boolean); 
  
      setTimeSlots(options);
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  };
    
  // Handle course selection
  const handleCourseSelect = (event) => {
    debugger;
    setSelectedCourse(event);
    setSelectedSlot("");
    fetchTimeSlots(event);

  };

 // Handle time slot selection
const handleSlotSelect = (selectedSlotValue) => {
    // // Find the corresponding option object in the timeSlots array
    // const selectedOption = courseCalendar.find(option => option.label === selectedSlotValue);
  
    // // Extract the value (ID) from the selected option
    // const slotId = selectedOption ? selectedOption.value : null;
  
    setSelectedSlot(selectedSlotValue);
  };
  
  const handleDeleteStudent = async (slotId, studentIdToRemove) => {
    try {
      // Find the slot
      const slot = courseCalendarTimeSlots.find(slot => slot._id === slotId);
      console.log("Found slot:", slot);
  
      if (!slot) {
        throw new Error("Slot not found");
      }
  
      // Ensure both the studentIdToRemove and IDs in subscribed_students are strings
      const updatedSubscribedStudents = slot.subscribed_students.filter(id => id.id !== studentIdToRemove);
  
      console.log("Updated subscribed students:", updatedSubscribedStudents);
  debugger;
      // Send the updated list to the server
      const response = await put(`coursecalendartimeslot/${slotId}`, {
        subscribed_students: updatedSubscribedStudents
      });
      console.log("Server response:", response);
  
      // Check response status
      if (response.statusCode === 200) {
        toast.success('Removed successfully', {
          onClose: () => {                
            window.location.reload();
          } 
        });
  
        // Fetch updated time slot data
        fetchCourseCalendarTimeSlot();
      } else {
        throw new Error("Failed to remove student. Server responded with status: " + response.statusCode);
      }
    } catch (error) {
      console.error("Error removing student:", error);
    }
  };
  
  
  // Handle student selection
  const handleStudentSelect = (event) => {
    debugger;
    setSelectedStudent(event);
  };

  const handleCohortSubmit = async () => {
    try {
      // Fetch the current list of subscribed students for the selected time slot
      const currentSubscribedStudents = courseCalendarTimeSlots
        .find(slot => slot._id === selectedSlot)
        .subscribed_students;
  
      // Combine the current list with the new student, ensuring no duplicates
      const updatedSubscribedStudents = Array.from(new Set([...currentSubscribedStudents, selectedStudent]));
  
      // Update the subscribed students for the selected time slot
      const putResponse = await put(`coursecalendartimeslot/${selectedSlot}`, {
        subscribed_students: updatedSubscribedStudents
      });
  
      if (putResponse.statusCode === 200) {
        const enrolledStudent = await get('/users/'+ selectedStudent);
        const studentData = enrolledStudent.response;
        debugger;
        // After successful update, prepare and send data to another API
        const enrollmentData = {
          name: studentData.firstname, // Assuming userDetails is defined elsewhere
          user: studentData.id,
          course: selectedCourse,
          organization: studentData.organization.id,
          courseCalendarTimeSlots: selectedSlot
        };
        debugger;
        const postResponse = await post('/UserCourseEnrollment', enrollmentData);
  
        if (postResponse.statusCode === 201) {
          toast.success('Course cohort added successfully', {
            onClose: () => {
              window.location.reload();
            }
          });
        } else {
          toast.error('Failed to add course cohort');
        }
  
        // Refresh the course calendar time slots after successful operations
        fetchCourseCalendarTimeSlot();
      } else {
        toast.error('Failed to update subscribed students');
      }
    } catch (error) {
      console.error('Error handling cohort submission:', error);
      toast.error('Error handling cohort submission');
    }
  };
  


  return (
    <DashboardContent pageTitle="Course Cohort">
      <div className="card dashboard-card-ten mb-5">
        <div className="card-body">
          <div className="heading-layout1">
            <div className="item-title">
              <h3>Assign Students To Time Slots</h3>
            </div>
          </div>
            <div className="form-group">
                <label >Select Course:</label>
                <SelectField
                    id="course"
                    options={courseCalendar}
                    placeholder="Select Course"
                    onChange={handleCourseSelect}
                    value={selectedCourse} >                    
                </SelectField>
            </div>
          
            <div className="form-group">
                {selectedCourse && (
                    <div>
                    <label >Select Time Slot:</label>
                    <SelectField
                        id="slot"
                        options={timeSlots}
                        placeholder="Select Time Slots"
                        onChange={handleSlotSelect}
                        value={selectedSlot}
                      />
                    </div>
                )}
            </div>
            <div className="form-group">

              <label >Select Student:</label>
              <SelectField
                id="student"
                options={students}
                placeholder="Select Student"
                onChange={handleStudentSelect}
                value={selectedStudent}
                selected={id} />
              </div>
              <button onClick={handleCohortSubmit} className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Assign Student</button>
        </div>
      </div>
      
        {courseCalendarTimeSlots
          .filter(slot => slot.subscribed_students.some(student => student.id === id))
          .map((courseDetails, i, array) => (
              <div key={i}>
                <Card>
                  <Card.Body>
                    Course: {courseDetails.course_calendar.course.course_title}
                    <br />
                    Start Time: {courseDetails.start_time}
                    <br />
                    End Time: {courseDetails.end_time}
                    <Row>
                      {/* <Col>
                        <Button onClick={()=>window.location='/editcoursecohort?id='+ courseDetails.id + '&stid=' + id} className='mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark'>Edit</Button>              
                      </Col> */}
                      <Col>
                        <Button onClick={() => handleDeleteStudent(courseDetails.id, id)} className='mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark'>Delete </Button>              
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {i < array.length - 1 && ','}
              </div>
          ))}             
       
      <ToastContainer />
    </DashboardContent>
  );
}

export default CourseCohort;
