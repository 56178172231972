import React, { useState } from 'react';
import './VerticalAccordion.css'; // Import your custom CSS for styling
import { get } from './rest';

const VerticalAccordionView = ({ items, onModuleSelect, onChapterSelect, onLessonSelect, onCompletionStatus }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [lessons, setLessons] = useState([]);

  const handleItemClick = (index, moduleId) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedModule(moduleId);
    onModuleSelect(moduleId);
    fetchChapters(moduleId);
    onChapterSelect(null);
    onLessonSelect(null);
  };

  const fetchChapters = async (moduleId) => {
    try {
      const response = await get(`coursechapter?courseModule=${moduleId}`);
      setChapters(response.response);
    } catch (error) {
      console.error("Error fetching Chapters:", error);
    }
  };

  const fetchLessons = async (chapterId) => {
    try {
      const response = await get(`lms?courseChapter=${chapterId}`);
      setLessons(response.response
        .filter(lms => lms.courseChapter 
          && lms.courseChapter.id === chapterId));
          debugger
          console.log(lessons);
          console.log(response.response);
    } catch (error) {
      console.error("Error fetching Lessons:", error);
    }
  };

  const handleChapterClick = (chapterId) => {
    onChapterSelect(chapterId);
    setSelectedChapter(chapterId);
    debugger;
    fetchLessons(chapterId);
  };

  const handleLessonClick = (lesson) => {
    onLessonSelect(lesson);
    const isLastLesson = lessons.length > 0 && lesson.id === lessons[lessons.length - 1].id;
    const isLastChapter = selectedChapter === chapters[chapters.length - 1]?.id;
    onCompletionStatus(isLastChapter && isLastLesson); // Notify parent about completion status
  };

  return (
    <div className="vertical-accordion-container">
      <div className="vertical-accordion">
        {items.map((item, index) => (
          <div key={index} className="accordion-item">
            <div
              className={`accordion-header ${index === activeIndex ? 'active' : ''}`}
              onClick={() => handleItemClick(index, item.title)}
            >
              <span>
                <small><i className="fa-solid fa-code-commit"></i></small> {item.content}
              </span>
            </div>
            {index === activeIndex && (
              <div className="accordion-content">
                {chapters.length > 0 && (
                  <div className="chapter-list">
                    {chapters.map((chapter, chapterIndex) => (
                      <div key={chapterIndex}>
                        <div
                          className="chapter-item"
                          onClick={() => handleChapterClick(chapter.id)}
                        >
                          <span>
                            <small><i className="fa-solid fa-code-commit"></i></small> {chapter.title}
                          </span>
                        </div>
                        {selectedChapter === chapter.id && lessons.length > 0 && (
                          <div className="lesson-list p-3">
                            {lessons.map((lesson, lessonIndex) => (
                              <div
                                key={lessonIndex}
                                className="lesson-item"
                                onClick={() => handleLessonClick(lesson)}
                              >
                                <span>
                                  <small><i className="fa-solid fa-code-commit"></i></small> {lesson.lesson_title}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalAccordionView;