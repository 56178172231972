import React, { useState, useEffect } from "react";
import { Card, Modal, Button, Row, Col } from "react-bootstrap";
import { get } from "./rest"; // Assuming get function is defined in rest
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import CreateQuizForm from "./CreateQuizForm";

const QuizQuestionsModal = ({ 
  show, 
  onHide, 
  quizId,
  selectedOptionCourses,
  selectedOptionModule,
  selectedOptionUnit,
  selectedOptionLesson
}) => {
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [assessmentData, setAssessmentData] = useState([]);
  const [selectedQuizId, setSelectedQuizId] = useState(null); // State to hold the selected quiz ID

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await get(`assessment`);
        const filteredAssessments = response.response.filter(
          ass => ass.assessmentQuiz.id === quizId
        );
        setQuizQuestions(filteredAssessments);
        setAssessmentData(filteredAssessments); // Store the complete assessment data
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error("Failed to fetch questions");
      }
    };

    if (show) {
      fetchQuestions();
    }
  }, [show, quizId]);

  const handleRemoveQuestion = (questionId) => {
    setQuizQuestions(quizQuestions.filter(question => question._id !== questionId));
  };

  const handleSave = () => {
    setShowCreateForm(true);
  };

  const handleCancel = () => {
    setShowCreateForm(false);
  };

  const handleQuizCreated = (quizId) => {
    setSelectedQuizId(quizId); // Set the selected quiz ID after it's created
    setShowCreateForm(false);
    onHide(); // Close the modal after quiz creation
  };

  const renderQuestion = (question) => {
    return (
      <Card key={question._id} className="p-3 mb-4 d-flex justify-content-between">
        <Row>
          <Col md={11}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question) }}></div>
            {question.type !== 'fillintheblanks' && (
              <div className="d-flex">
                {question.answer1 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `a) ${DOMPurify.sanitize(question.answer1)}` }}></div>}
                {question.answer2 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `b) ${DOMPurify.sanitize(question.answer2)}` }}></div>}
                {question.answer3 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `c) ${DOMPurify.sanitize(question.answer3)}` }}></div>}
                {question.answer4 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `d) ${DOMPurify.sanitize(question.answer4)}` }}></div>}
              </div>
            )}
          </Col>
          <Col md={1} className="d-flex justify-content-end">
            <Button variant="danger" onClick={() => handleRemoveQuestion(question._id)}>
              <i className="fa fa-trash"></i>
            </Button>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Quiz Questions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showCreateForm ? (
          <CreateQuizForm
            questions={quizQuestions}
            onQuizCreated={handleQuizCreated}
            onCancel={handleCancel}
            selectedOptionCourses={selectedOptionCourses}
            selectedOptionModule={selectedOptionModule}
            selectedOptionUnit={selectedOptionUnit}
            selectedOptionLesson={selectedOptionLesson}
            selectedQuizId={selectedQuizId} // Pass selected quiz ID to pre-select in form
            assessmentData={assessmentData} // Pass assessment data to the form
          />
        ) : (
          quizQuestions.map((question) => renderQuestion(question))
        )}
      </Modal.Body>
      {!showCreateForm && (
        <Modal.Footer>
          <Button variant="primary" onClick={handleSave} className="secondary-btn">
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default QuizQuestionsModal;
