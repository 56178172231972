import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import LoginSuperAdmin from "./Components/LoginSuperAdmin";
import ForgotPassword from "./Components/ForgotPassword";
import OTP from "./Components/OTP";
import ChangePassword from "./Components/ChangePassword";
import Dashboard from "./Components/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import Enquiries from "./Components/Enquiries";
// import ListofOrganizations from "./Components/ListofOrganizations";
// import EditOrganization from "./Components/EditOrganization";
import Profile from "./Components/Profile";
import UpdateProfile from "./Components/UpdateProfile";
import DefaultCourse from "./Components/DefaultCourse";
import CreateDefaultCourse from "./Components/CreateDefaultCourse";
import EditDefaultCourse from "./Components/EditDefaultCourse";
import Industries from "./Components/Industries";
import CreateIndustry from "./Components/CreateIndustry";
import EditIndustry from "./Components/EditIndustry";
// import ChaptersList from "./Components/ChaptersList";
// import CreateChapter from "./Components/CreateChapter";
import AssignRoles from "./Components/AssignRoles";
import CreateRoles from "./Components/CreateRoles";
import ListofRoles from "./Components/ListofRoles";
import EditRole from "./Components/EditRole";
import Subscriptions from "./Components/SubscriptionTypes";
import CreateSubscriptionType from "./Components/CreateSubscriptionType";
import EditSubscriptionType from "./Components/EditSubscriptionType";
import SubscriptionPayment from "./Components/SubscriptionPayment";
// import Employees from "./Components/Employees";
// import CreateEmployee from "./Components/CreateEmployee";
// import EditEmployee from "./Components/EditEmployee";
// import CreateOrganization from "./Components/CreateOrganization";
import RolesAssigned from "./Components/RolesAssigned";
import Assessments from "./Components/Assessments";
import CreateAssessment from "./Components/CreateAssessment";
import EditAssessment from "./Components/EditAssessment";
import CreateLMS from "./Components/CreateLMS";
import OrganizationAdmins from "./Components/OrganizationAdmins";
import CreateOrganizationAdmin from "./Components/CreateOrganizationAdmin";
import EditOrganizationAdmin from "./Components/EditOrganizationAdmin";
import Voucher from "./Components/Vochuer";
import ListOfLMS from "./Components/ListOfLMS";
import EditLMS from "./Components/EditLMS";
import SetNewPassword from "./Components/SetNewPassword";
import EditRoleAssigned from "./Components/EditRoleAssigned";
import WebsiteSetUp from "./Components/WebsiteSetUp";
import Results from "./Components/Results";
import Students from "./Components/Students";
import CreateStudent from "./Components/CreateStudent";
import EditStudent from "./Components/EditStudent";
import CreateQuestion from "./Components/CreateQuestion";
import FillInTheBlanks from "./Components/FillInTheBlanks";
import Modules from "./Components/Modules";
import Chapters from "./Components/Chapters";
import Test from "./Components/Test";
import EditModule from "./Components/EditModule";
import EditChapter from "./Components/EditChapter";
import ShortAnswer from "./Components/ShortAnswer";
import EssayAnswer from "./Components/EssayAnswer";
import EditFillintheBlank from "./Components/EditFillintheBlank";
import EditShortAnswer from "./Components/EditShortAnswer";
import EditEssayAnswer from "./Components/EditEssayAnswer";
import CourseForm from "./Components/CourseForm";
import Calendar from "./Components/Calendar";
import CourseCalendar from "./Components/CourseCalendar";
import CreateChooseCorrect from "./Components/CreateChooseCorrect";
import EditChooseCorrect from "./Components/EditChooseCorrect";
import Questions from "./Components/Questions";
import EditAssessmentQuiz from "./Components/EditAssessmentQuiz";
import CourseCohort from "./Components/CourseCohort";
import EditCourseCalendar from "./Components/EditCourseCalendar";
import EditCourseCohort from "./Components/EditCourseCohort";
import Home from "./Components/Home";
import Courses from "./Components/Courses";
import CourseDetail from "./Components/CourseDetail";
import MyCourses from "./Components/MyCourses";
import Quiz from "./Components/Quiz";
import LMS from "./Components/LMS";
import PasswordChange from "./Components/PasswordChange";
import SignUp from "./Components/SignUp";
import EditResultModal from "./Components/EditResultModal";
import QuizCalendar from "./Components/QuizCalendar";
import TakeAssessment from "./Components/TakeAssessment";
import StudentsProgress from "./Components/StudentsProgress";
import Messages from "./Components/Messages";
import ViewChat from "./Components/ViewChat";
import { useEffect, useState } from "react";
import CreateIssue from "./Components/CreateIssue";
import RandomQuiz from "./Components/RandomQuiz";
import UploadDocument from "./Components/UploadDocument";
import ViewDocuments from "./Components/ViewDocuments";
import ProtectedRoute from "./Components/ProtectedRoute";
import { get } from "./Components/rest";
import UpgradeSubscription from "./Components/UpgradeSubscription";
import QuizView from "./Components/QuizView";

function App() {
  const [isOrganizationActive, setIsOrganizationActive] = useState(true);

  useEffect(() => {
    // const organizationId = sessionStorage.getItem("organizationid");
    sessionStorage.setItem("organizationid", "66794c0593cc381129edfb13") //enter org id everytime
    const fetchOrganizationOptions = async () => {
      try {
          const organizationId = sessionStorage.getItem("organizationid");
          const response = await get('organization');
          
          // Filter to get the specific organization
          const data = response.response.filter(org => org.id === organizationId);
  
          // Assuming data is an array and we take the first item for processing
          if (data.length > 0) {
              const organization = data[0];
  
              // Check if the organization is active
              const isActive = organization.isActive;
  
              // Calculate start and end dates
              const startDate = organization.subscription ? new Date(organization.subscription.createdAt) : null;
              const endDate = startDate ? new Date(startDate) : null;
  
              if (endDate) {
                  endDate.setFullYear(endDate.getFullYear() + 1); // Add 1 year (365 days)
              }
  
              // Get the current date
              const currentDate = new Date();
  
              // Check if the subscription is valid
              const isSubscriptionValid = startDate && currentDate >= startDate && currentDate <= endDate;
  
              // Set the organization active status based on both conditions
              setIsOrganizationActive(isActive && isSubscriptionValid);
  
          }
      } catch (error) {
          console.error('Error fetching organization options:', error);
      }
  };
  

    fetchOrganizationOptions();
}, []);

// Get the current page URL
var currentPage = window.location.pathname;
if (!sessionStorage.getItem("authorid")) {
  var exceptions = ["/forgotpassword", "/otp", "/setpassword", "/", "/home", "/signup", "/login", "/upgrade"];

  if (!exceptions.includes(currentPage)) {
      window.location.href = "/";
  }
} else {
  var dashboardExceptions = ["/forgotpassword", "/otp", "/setpassword", "/", "/signup", "/login", "/upgrade"];
  if (dashboardExceptions.includes(currentPage)&& currentPage !== "/upgrade") {
    window.location.href = "/home";
    // window.location.href = "/dashboard";
  }
}
// Function to add asterisks to required fields
const addAsterisksToRequiredLabels = () => {
  const requiredFields = document.querySelectorAll('.form-control[required]');
  requiredFields.forEach(field => {
    // Find the label associated with the current field
    const label = findLabelForField(field);
    if (label && !label.innerHTML.includes('*')) {
      label.innerHTML += '<span class="text-danger"> *</span>';
    }
  });
};

// Function to find the label associated with a form field
const findLabelForField = (field) => {
  // Check if the field itself is labeled
  let label = field.closest('label');
  if (!label) {
    // If not, find the nearest label in the ancestor tree
    label = field.closest('div').querySelector('label');
  }
  return label;
};

useEffect(() => {
  // Initial run to add asterisks
  addAsterisksToRequiredLabels();

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(() => {
    addAsterisksToRequiredLabels();
  });

  // Observe changes in the entire body
  observer.observe(document.body, { childList: true, subtree: true });

  // Cleanup observer on unmount
  return () => {
    observer.disconnect();
  };
}, []);


  return (
    <div className="App">
      <BrowserRouter>
        {/* This routes for non sidebar pages */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginSuperAdmin />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/setpassword" element={<SetNewPassword />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/home" element={<Home />} />
          
          {/* <Route path="/createorganization" element={<CreateOrganization />} />
          <Route path="/listoforganization" element={<ListofOrganizations />} />
          <Route path="/editorganization" element={<EditOrganization />} /> */}
          {/* <Route path="/roleassign" element={<EditOrganization />} /> */}
          <Route element={<ProtectedRoute isOrganizationActive={isOrganizationActive} />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/enquiries" element={<Enquiries />} />
          
          <Route path="/profile" element={<Profile />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/course" element={<DefaultCourse />} />
          <Route path="/createcourse" element={<CreateDefaultCourse />} />
          <Route path="/editcourse" element={<EditDefaultCourse />} />
          <Route path="/categories" element={<Industries />} />
          <Route path="/createindustry" element={<CreateIndustry />} />
          <Route path="/editindustry" element={<EditIndustry />} />
          {/* <Route path="/chapters" element={<ChaptersList />} /> */}
          {/* <Route path="/createchapter" element={<CreateChapter />} /> */}
          <Route path="/roles" element={<ListofRoles />} />
          <Route path="/assignroles" element={<AssignRoles />} />
          <Route path="/createroles" element={<CreateRoles />} />
          <Route path="/editroles" element={<EditRole />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/createsubscriptiontype" element={<CreateSubscriptionType />} />
          <Route path="/editsubscriptiontype" element={<EditSubscriptionType />} />
          <Route path="/subscriptionpayment" element={<SubscriptionPayment />} />
          {/* <Route path="/employees" element={<Employees />} />
          <Route path="/createemployee" element={<CreateEmployee />} />
          <Route path="/editemployee" element={<EditEmployee />} /> */}
          <Route path="/rolesassigned" element={<RolesAssigned />} />
          <Route path="/editrolesassigned" element={<EditRoleAssigned />} />
          <Route path="/questionbank" element={<Questions />} />
          <Route path="/assessments" element={<Assessments />} />
          <Route path="/editassessments" element={<EditAssessmentQuiz />} />
          <Route path="/createquestion" element={<CreateQuestion />} />
          <Route path="/create-assessment" element={<CreateAssessment />} />
          <Route path="/fill-in-the-blanks" element={<FillInTheBlanks />} />
          <Route path="/editfillintheblank" element={<EditFillintheBlank />} />
          <Route path="/short-answer" element={<ShortAnswer />} />
          <Route path="/editshortanswer" element={<EditShortAnswer />} />
          <Route path="/essay-answer" element={<EssayAnswer />} />
          <Route path="/editessay" element={<EditEssayAnswer />} />
          <Route path="/editquestion" element={<EditAssessment />} />
          <Route path="/createlms" element={<CreateLMS />} />
          <Route path="/users" element={<OrganizationAdmins />} />
          <Route path="/createuser" element={<CreateOrganizationAdmin />} />
          <Route path="/edituser" element={<EditOrganizationAdmin />} />
          <Route path="/listoflms" element={<ListOfLMS />} />
          <Route path="/editlms" element={<EditLMS />} />
          <Route path="/websitesetup" element={<WebsiteSetUp />} />
          <Route path="/results" element={<Results />} />
          <Route path="/students" element={<Students />} />
          <Route path="/createstudent" element={<CreateStudent />} />
          <Route path="/editstudent" element={<EditStudent />} />
          <Route path="/modules" element={<Modules />} />
          <Route path="/editmodule" element={<EditModule />} />
          <Route path="/units" element={<Chapters />} />
          <Route path="/editunit" element={<EditChapter />} />
          <Route path="/test" element={<Test />} />
          <Route path="/coursecalendar" element={<CourseCalendar />} />
          <Route path="/editcoursecalendar" element={<EditCourseCalendar />} />
          <Route path="/courseform" element={<CourseForm />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/createchooseTheCorrectAnswer" element={<CreateChooseCorrect />} />
          <Route path="/editchooseTheCorrectAnswer" element={<EditChooseCorrect />} />
          <Route path="/coursecohort" element={<CourseCohort />} />
          <Route path="/editcoursecohort" element={<EditCourseCohort />} />
          <Route path="/home" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/coursedetail" element={<CourseDetail />} />
          <Route path="/mycourses" element={<MyCourses />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/lms" element={<LMS />} />
          <Route path="/passwordchange" element={<PasswordChange />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/EditResult" element={<EditResultModal />} />
          <Route path="/quizcalendar" element={<QuizCalendar />} />
          <Route path="/assessment" element={<TakeAssessment />} />
          <Route path="/studentprogress" element={<StudentsProgress />} />
          <Route path="/chats" element={<Messages />} />
          <Route path="/createmessage" element={<CreateIssue />} />
          <Route path="/viewchat" element={<ViewChat />} />
          <Route path="/createrandomquiz" element={<RandomQuiz />} />
          <Route path="/uploaddocuments" element={<UploadDocument />} />
          <Route path="/documents" element={<ViewDocuments />} />
          <Route path="/upgrade" element={<UpgradeSubscription />} />
          <Route path="/quizview" element={<QuizView />} />
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
